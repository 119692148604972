import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, School, Insights, Grade } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const ExamsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Innovative Exam Maker and Assessment Tools">
                                    Innovative Exam Maker and Assessment Tools: Revolutionize Your Exam Processes with AI
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                In the ever-evolving landscape of education, <strong>AI</strong> is a vital component that educators must embrace to enhance their teaching methodologies. Our cutting-edge AI-powered exam maker and assessment tools are designed to transform the way you approach exam creation, grading, and analysis. Our platform streamlines the entire exam process, from initial design to final reporting, ensuring a more efficient, accurate, and insightful experience for educators and students alike. By incorporating advanced <strong>artificial intelligence</strong>, our tools provide a seamless solution that integrates effortlessly into your educational workflow.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our AI-powered platform stands out with its comprehensive set of features tailored to meet the diverse needs of modern educational environments. Whether you are looking to <strong>create</strong> custom exams, automate grading processes, or analyze detailed performance metrics, our tools are designed to enhance every aspect of the assessment lifecycle. Embrace a future where technology drives educational excellence, ensuring that every assessment is fair, accurate, and reflective of student capabilities. Our platform is not just a tool; it is a transformative resource that empowers educators to deliver superior educational experiences.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our platform, educators benefit from features such as secure online exam environments that safeguard academic integrity, real-time feedback mechanisms that keep students informed and engaged, and advanced analytics that provide deep insights into student performance. These features collectively contribute to a more dynamic and effective learning environment, where assessments are seamlessly integrated into the teaching process, and educational outcomes are enhanced through data-driven insights. Our solution supports educators in their quest for excellence, offering the tools necessary to <strong>create</strong>, manage, and analyze assessments with unprecedented ease and efficiency.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our AI-Powered Exam Platform?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">1. Efficient Exam Creation</Typography>
                                    </Box>
                                    Our intuitive exam creation tool allows you to effortlessly <strong>create</strong> comprehensive and customizable exams that align with your specific educational goals and standards. The user-friendly interface integrates various question types, ensuring that your assessments are well-structured and tailored to meet diverse learning objectives. This efficiency enhances the quality of your exams, making it easier to deliver high-stakes assessments that accurately measure student understanding and performance.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">2. Automated Test Grading</Typography>
                                    </Box>
                                    Say goodbye to manual grading with our AI-driven grading system. This powerful feature automates the grading process, providing instant feedback to students and detailed performance reports to educators. The automated system not only saves time but also helps maintain consistency in grading, allowing educators to focus more on instructional activities and student engagement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">3. Advanced Analytics</Typography>
                                    </Box>
                                    Our platform's advanced analytics capabilities provide educators with deep insights into student performance. Through detailed exam reports and performance metrics, educators can identify trends, evaluate strengths and weaknesses, and make data-driven decisions to enhance their instructional strategies. The rich analytics enable a more comprehensive understanding of student progress and performance, allowing for targeted interventions that can drive improved educational outcomes.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Grade color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">4. Secure Exam Environment</Typography>
                                    </Box>
                                    Academic integrity is paramount, and our platform's secure online exam environment ensures every assessment is conducted under fair and controlled conditions. This feature is crucial in maintaining the credibility of the assessment process, ensuring all students are evaluated on a level playing field, and reinforcing the importance of academic honesty in the educational experience.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Conduct Exams Effectively with Our Platform
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Conducting exams effectively is essential for accurate assessment and successful educational outcomes. Our AI-powered platform provides a complete solution for managing the entire exam process, from design to analysis. Utilize our tools to <strong>create</strong> and distribute exams efficiently, automate grading processes, and gain valuable insights from detailed analytics. By leveraging these features, you can enhance the effectiveness of your assessments and support your educational goals. Discover how our platform can transform your exam management practices and contribute to a more effective and engaging learning environment.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ExamsUseCase;
