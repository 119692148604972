import { Grid, TextField, Typography, Card, CardContent, CardMedia } from "@mui/material";
import { useImperativeHandle, useRef, useState, forwardRef } from "react"

export const imgSrcPrefix = 'data:image/jpeg;base64,'

const UploadImage = forwardRef(({ onImageUpload, imageBase64 = '' }, ref) => {
    const [imagePreview, setImagePreview] = useState(imageBase64 ? imgSrcPrefix + imageBase64 : '');
    const [imageSizeTxt, setImageSizeTxt] = useState(getImgSizeTextFromBase64(imageBase64))
    const [uploadError, setUploadError] = useState('')
    const uploadInputRef = useRef(null)

    // TODO: Add a button that resets this input to upload a new image
    const reset = () => {
        setImagePreview(null)
        setImageSizeTxt('')
        setUploadError('')
        if (uploadInputRef.current)
            uploadInputRef.current.value = ''
    }

    // This is forwarding the ref, and expose the reset function to the parent
    useImperativeHandle(ref, () => ({
        reset: reset
    }));


    const handleImagePreview = (file) => {
        if (!file || !isImage(file)) {
            setImagePreview(null)
            setUploadError('Error uploading image, available extentions are .jpg, .jpeg, .png')
            return;
        }
        setUploadError('')
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];

            resizeToThumbnail(base64String, (resizedBase64) => {
                // make it light-weight image
                setImageSizeTxt(getImgSizeTextFromBase64(resizedBase64))
                setImagePreview(imgSrcPrefix + resizedBase64);
                onImageUpload(resizedBase64)
            })
        };
        reader.readAsDataURL(file);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    type="file"
                    onChange={(e) => {
                        handleImagePreview(e.target.files[0]);
                    }}
                    fullWidth
                    inputRef={uploadInputRef}
                />
                <Typography variant="body2" color="text.secondary">
                    Try Not to use more than 48KB image
                </Typography>
                {imagePreview && (
                    <Card sx={{ maxWidth: 200, mt: 2 }}>
                        <CardMedia component="img" alt="Org Logo" height="100" image={imagePreview} />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                Org Logo Preview<br />
                                {imageSizeTxt}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
                {uploadError &&
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {uploadError}
                    </Typography>
                }
            </Grid>
        </Grid>
    );
})

function resizeImage(base64String, maxWidth, maxHeight, callback) {
    const img = new Image();
    img.src = `data:image/jpeg;base64,${base64String}`;

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth; // Set the maximum width you want
        const MAX_HEIGHT = maxHeight; // Set the maximum height you want
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }

        canvas.width = MAX_WIDTH; // Always set to the maximum width
        canvas.height = MAX_HEIGHT; // Always set to the maximum height
        const ctx = canvas.getContext('2d');

        // Set background color to white
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the image onto the canvas
        ctx.drawImage(img, (MAX_WIDTH - width) / 2, (MAX_HEIGHT - height) / 2, width, height);

        const resizedBase64 = canvas.toDataURL('image/jpeg').split(',')[1];
        callback(resizedBase64)
    };
};


function resizeToThumbnail(base64String, callback) {
    resizeImage(base64String, 200, 200, callback);
};

function isImage(file) {
    // Check if the file has a valid image extension
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif'];
    const fileNameParts = file.name.split('.');
    const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    return allowedExtensions.includes(extension);
};

function getImageSizeFromBase64(base64String) {
    // Remove the data URL prefix (e.g., 'data:image/jpeg;base64,')
    const base64WithoutPrefix = base64String.replace(/^data:image\/[a-z]+;base64,/, '');

    // Convert the Base64 string to binary data
    const binaryData = atob(base64WithoutPrefix);

    // Get the length of the binary data, which represents the size of the image in bytes
    const imageSizeInBytes = binaryData.length;

    // Convert bytes to kilobytes (1 KB = 1024 bytes)
    const imageSizeInKB = imageSizeInBytes / 1024;

    return {
        imageSizeInBytes: imageSizeInBytes,
        imageSizeInKB: imageSizeInKB,
    };
}

function getImgSizeTextFromBase64(base64String) {
    return `Img Size: ${getImageSizeFromBase64(base64String).imageSizeInKB.toFixed(2)}KB`
}

export default UploadImage;