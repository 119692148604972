import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Star } from '@mui/icons-material';
import TeachersUseCaseImg from '../../../assets/features/teachers.jpg'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const TeachersUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Empower Teachers with Advanced AI-Driven Assessment Tools">
                                    Empower Teachers with Advanced AI-Driven Assessment Tools: Revolutionizing Education Through AI-Enhanced Testing and Feedback
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Revolutionize your teaching approach with our cutting-edge AI-powered assessment tools, meticulously designed to cater specifically to the needs of educators. Our platform simplifies every aspect of creating, administering, and evaluating exams and assessments. By integrating advanced AI technology, we enable teachers to streamline cumbersome administrative tasks, thereby freeing up valuable time to focus on engaging with students and enhancing their overall learning experiences. Whether you’re looking to create personalized tests or free up your schedule, our tools have you covered.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our platform is versatile and adaptable, supporting a broad range of educational settings—from primary schools to higher education institutions. This flexibility ensures that your teaching methods remain effective and relevant in today's fast-paced academic environment. With our AI-driven tools, educators can maintain a high level of instructional quality and efficiency, ultimately fostering a more productive and dynamic learning environment. This free platform empowers teachers to create assessments that resonate with their students.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                The advanced features of our platform include automated exam creation, grading, and performance tracking, all of which are powered by sophisticated AI algorithms. These features are designed to optimize the assessment process, provide timely feedback, and deliver actionable insights into student performance. By leveraging these tools, educators can enhance their teaching strategies and ensure that students receive the support they need to succeed academically. Our platform makes it easy to create high-quality test papers and assessments.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Our AI-Powered Platform is Indispensable for Teachers
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Efficient Test Creation and Customization</Typography>
                                    </Box>
                                    Our platform’s AI-driven capabilities simplify the process of designing and distributing custom tests and assessments. Teachers can effortlessly create personalized exams that align with their specific educational goals and cater to the diverse learning needs of their students. This streamlined approach not only saves valuable time but also enhances the accuracy and effectiveness of assessments, ensuring that they are tailored to meet your educational objectives. Create exams that captivate and challenge your students effectively.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Automated and Accurate Exam Grading</Typography>
                                    </Box>
                                    Our platform automates the grading process using advanced AI algorithms, providing timely and precise feedback on student performance. This automation not only ensures consistent and objective grading but also frees up educators to concentrate on interactive teaching and personalized student support. By streamlining the evaluation process, our platform enables teachers to track progress and performance more effectively, ultimately enhancing the overall test experience.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Star color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Advanced Insights and Performance Analytics</Typography>
                                    </Box>
                                    Our platform offers comprehensive insights into student performance through detailed analytics and reporting features. Educators can access in-depth reports that reveal performance metrics, identify trends, and address individual student needs. These analytics are crucial for making informed decisions about teaching strategies and for supporting academic growth. By utilizing these insights, educators can refine their approach to enhance learning outcomes and improve overall educational effectiveness. Analyze results and improve your tests continuously.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Maximize the Benefits of Our AI Assessment Platform
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                To fully harness the power of our AI-powered assessment platform, integrate it seamlessly into your daily teaching practices. Design customized exams that cater to individual learning needs, automate grading processes to ensure efficiency, and utilize our advanced analytics to gain actionable insights into student performance. By incorporating these tools into your educational framework, you can significantly enhance teaching effectiveness, foster greater student engagement, and achieve superior learning outcomes. Create engaging and dynamic tests that inspire student success!
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={TeachersUseCaseImg} alt="AI-Powered Tools for Teachers" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default TeachersUseCase;
