import React, { useState, useEffect } from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

const LinearProgressWithLabel = (props) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

const LoaderProgressBar = ({ questions }) => {
    const MULTIPLIER = 0.6;
    const totalSeconds = MULTIPLIER * questions.reduce((total, question) => {
        if (question.type === 'multiple_choice' || question.type === 'fill_in_the_blank') {
            return total + 12;
        } else if (question.type === 'open_questions' || question.type === 'graph_questions' || question.type === 'nested_questions') {
            return total + 20
        } else {
            return total
        }
    }, 0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                const newProgress = prevProgress + 1;
                if (newProgress >= totalSeconds) {
                    clearInterval(interval);
                }
                return newProgress;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [totalSeconds]);

    return (
        <div style={{ width: '100%', maxWidth: '600px', paddingTop: '40px', paddingBottom: '10px' }}>
            <LinearProgressWithLabel variant="determinate" value={(progress / totalSeconds) * 100} sx={{ height: 6, borderRadius: 10 }} />
        </div>
    );
}
export default LoaderProgressBar
