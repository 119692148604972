import React, { useState } from 'react';
import EmailForm from './EmailForm';
import CodeForm from './CodeForm';
import NewPasswordForm from './NewPasswordForm';
import { Box, Grid } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { forgotPassword, resetPassword, verifyCode } from '../../requests/auth';
import useSnackBar from '../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const { openSnackBar } = useSnackBar()
    const navigate = useNavigate()
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailSubmit = (email) => {
        setIsLoading(true)
        // Send API request to request reset code for the provided email
        forgotPassword(email).then(response => {
            if (response.status == HttpStatusCode.Ok) {
                // After success, move to the next step
                setEmail(email);
                setResetToken(response.data.reset_token)
                setStep(2);
            }
        }).finally(() => {
            openSnackBar('RESET_PASSWORD')
            setIsLoading(false)
        })
    };

    const handleCodeSubmit = (code) => {
        setIsLoading(true)
        verifyCode(code, resetToken).then(response => {
            if (response.status === HttpStatusCode.Ok)
                setStep(3)
        }).catch(e => {
            openSnackBar('INVALID_RESET_CODE')
        }).finally(() => { setIsLoading(false) })
    }

    const handleNewPasswordSubmit = (password) => {
        setIsLoading(true)
        resetPassword(password, email, resetToken).then(response => {
            if (response.status === HttpStatusCode.Ok) {
                openSnackBar('RESET_PASSWORD_SUCCEEDED')
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            }
        }).catch(e => {
            openSnackBar('ERROR')
        }).finally(() => { setIsLoading(false) })
    }
    const handleGetNewCode = (e) => {
        e.preventDefault();
        handleEmailSubmit(email)
    }
    return (
        <>
            <Grid container sx={{ backgroundColor: '#ddd', height: '100vh' }} >
                <Grid item lg={3} xs={12} ></Grid>
                <Grid item lg={6} xs={12} sx={{ p: 3 }}>
                    <Box sx={{ borderRadius: '10px', backgroundColor: '#fff', p: 5, margin: 'auto', maxWidth: '400px' }}>
                        <Box sx={{ margin: 'auto', maxWidth: '300px' }}>
                            {step === 1 && <EmailForm onSubmit={handleEmailSubmit} loading={isLoading} />}
                            {step === 2 && <CodeForm onSubmit={handleCodeSubmit} loading={isLoading} onGetNewCode={handleGetNewCode} />}
                            {step === 3 && <NewPasswordForm onSubmit={handleNewPasswordSubmit} loading={isLoading} />}
                            <a href='/login' style={{ fontSize: '14px', color: '-webkit-link' }}>Go back to login</a>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={3} xs={12}></Grid>
            </Grid >
        </>
    );
};

export default ForgotPassword;
