import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import { useLanguage } from '../../contexts/languageContext';

export const DownloadCSVButton = ({ list, filename, buttonStyle = {} }) => {
    const { isRTL, languageData } = useLanguage();
    const handleDownloadCSV = () => {
        // Convert JSON to CSV content
        const csvContent = convertArrayToCsv(list);
        // Create a Blob and initiate the download
        const blob = new Blob(['\uFEFF', csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename ? filename + '.csv' : `whitelist.csv`;
        link.click();
    };

    return (
        <Button
            variant="contained"
            color="success"
            sx={{ direction: isRTL ? 'ltr' : 'rtl', ...buttonStyle }}
            onClick={handleDownloadCSV}
            disabled={!list || !list?.length}
        >
            <DownloadIcon /> &nbsp;{languageData?.download_label || 'Download'}
        </Button>
    )
}

function convertArrayToCsv(jsonData) {
    const headers = Object.keys(jsonData[0]);
    const csvContentArray = [];

    // Add headers to the CSV content
    csvContentArray.push(headers.join(','));

    // Add rows to the CSV content
    jsonData.forEach((row) => {
        const values = headers.map((header) => row[header]);
        csvContentArray.push(values.join(','));
    });

    return csvContentArray.join('\n');
};