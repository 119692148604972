import { Helmet } from "react-helmet-async";
import { useUser } from "../../hooks/useUser";
import PricingPlans from "./PricingPlans";

function Pricing() {
    const { user } = useUser()

    return (
        <>
            <Helmet>
                <title>StudyWise | Pricing</title>
                <meta name="description" content="Choose the best pricing plan for your needs. Our AI-powered exam generator offers flexible options for educators, institutions, and learners. Sign up today and start generating exams that boost learning outcomes." />
            </Helmet>
            <PricingPlans email={user?.email} />
        </>
    )
}

export default Pricing;