import React, { useMemo, useState } from 'react';
import { Typography, Button, TextField, Box, Grid, InputAdornment, Card, CardContent } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { EMPTY_STRING, isMobile } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { IdmMoeILButton } from '../../components/IdmMoeILButton';
import SSO from '../../utils/ssoTypes';
import { colors } from '../../utils/customTheme';

const Identification = ({ title, settings, onStart, ssoType = null }) => {
  const [formData, setFormData] = useState({
    fullname: EMPTY_STRING,
  });
  const [showNameInput, setShowNameInput] = useState(false);
  const { isRTL, languageData } = useLanguage();
  const quizLanguageData = languageData?.quiz;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStartClick = () => {
    if (formData.fullname.trim() === EMPTY_STRING) {
      alert('Full Name is required.');
    } else {
      onStart(formData);
    }
  };

  const toggleNameInput = () => {
    setShowNameInput((prev) => !prev);
  };

  const isStartButtonDisabled = formData.fullname.trim() === EMPTY_STRING;
  const ssoMoeIl = ssoType === SSO.types.MoeIL;
  const styles = useMemo(() => {
    return {
      studentEntranceBtn: {
        ...(isMobile ? {} : { maxWidth: '200px' }),
        margin: isMobile ? '9vh auto 2vh auto' : '8vh 0 0 0',
      },
      card: {
        maxWidth: '40rem',
        margin: 'auto',
        padding: '20px',
        paddingTop: ssoMoeIl ? '2vh' : '10vh',
      },
    };
  }, [isMobile, ssoMoeIl]);
  const hideNameLogin = settings?.disable_name_login == true || (ssoMoeIl && settings?.disable_name_login === undefined)

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} >
      {ssoMoeIl && <IdmMoeILButton buttonLabel="היסטוריית מבחנים" buttonProps={{ sx: styles.studentEntranceBtn }} />}
      <Box sx={styles.card}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
                  {title ?? ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IdmMoeILButton buttonLabel="כניסת תלמידים למבחן באמצעות הזדהות אחידה" redirectUrl={window.location.href} />
              </Grid>
              {!hideNameLogin && (
                <>
                  <Grid item xs={12}>
                    <Button
                      onClick={toggleNameInput}
                      variant="outlined"
                      fullWidth
                    >
                      {quizLanguageData?.identification?.name_entry ?? 'Enter with Name'}
                    </Button>
                  </Grid>
                  {showNameInput && (
                    <Grid item xs={12}>
                      <TextField
                        label={quizLanguageData?.full_name ?? 'Full Name'}
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {showNameInput && (
                    <Grid item xs={12}>
                      <Button
                        onClick={handleStartClick}
                        variant="contained"
                        color="primary"
                        disabled={isStartButtonDisabled}
                        fullWidth
                      >
                        {quizLanguageData?.identification?.start_label ?? 'Start Exam'}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Box sx={{ marginTop: '20px' }}>
          {/* condition render if no settings hide it */}
          {['start_time', 'end_time', 'show_results'].some((key) => settings?.[key]) &&
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                  {['start_time', 'end_time', 'show_results'].map((key) => {
                    const value = settings?.[key];
                    if (value) {
                      const formattedValue =
                        key === 'start_time' || key === 'end_time'
                          ? new Date(value).toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })
                          : null;

                      return (
                        <Grid container key={key} spacing={1} alignItems="center" sx={{ p: 1 }}>
                          <Grid item>
                            <BeenhereIcon color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">
                              <strong>
                                {quizLanguageData?.identification?.[key] ?? key}
                              </strong>
                              {key !== 'show_results' && ` ${formattedValue}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                    return null; // Skip rendering if the value is false or undefined
                  })}
                </Grid>

              </CardContent>
            </Card>}
        </Box>
      </Box>
    </div>
  );
};

export default Identification;
