import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const AccessibilityStatement = () => {
    return (
        <>
            <Helmet>
                <title>StudyWise | Accessibility</title>
                <meta name="description" content="Our commitment to accessibility ensures that our AI-powered exam generator is usable by everyone. Learn more about our accessibility features and policies." />
            </Helmet>
            <Grid container spacing={2} sx={{ padding: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                        Accessibility Statement - StudyWise.io
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>StudyWise</strong> is a company that provides services and products in the field of education and assessment.</p>
                        <p>In this statement, we aim to streamline the use and improve our service in terms of accessibility and equal rights for people with disabilities.</p>
                        <p>Our accessibility adaptation was carried out in accordance with <strong>Regulation 35</strong> of the Equal Rights for Persons with Disabilities at Level AA.</p>
                        <p>Accessibility adaptation was tested in Chrome, Firefox, Safari, Mozilla, and Edge browsers.</p>
                        <p>Accessibility features available on the site:</p>
                        <ul>
                            <li><strong>Support for all standard accepted browsers</strong> (such as Chrome, Explorer, FireFox, Opera, Mozila).</li>
                            <li>The site content is written in clear language and readable fonts are used.</li>
                            <li>The site structure is built with headings, paragraphs, and lists.</li>
                            <li>The site is easy and convenient to navigate and includes clear and accessible menus.</li>
                            <li>The links on the site are clear and explain where you will be directed after clicking on them.</li>
                            <li>Textual description of images and icons for assistive technologies.</li>
                            <li>Adaptation of the site to different resolution work environments (responsiveness).</li>
                            <li>ARIA rules have been implemented to help interpret the content of the site more accurately and better.</li>
                            <li>Accessibility of menus, forms and fields, heading hierarchy, tab elements, pop-ups and more.</li>
                        </ul>
                        <p>Changing the display on the site:</p>
                        <ul>
                            <li>You can zoom in or out of the site by pressing one of the “CTRL” buttons together with the mouse wheel or together with the “+” sign for zoom in or together with the “-” sign for zoom out. Each click will zoom in or out the screen by ten percent (10%).</li>
                            <li>You can change the font size using the accessibility menu on the site.</li>
                            <li>Users who do not have a mouse or cannot use a mouse can activate the features available on the site by pressing the “TAB” key. Each press will move the cursor to the next option on the site.</li>
                            <li>Pressing the “Enter” key will activate the link on which the cursor is located.</li>
                            <li>The site does not include flashing, flickering and moving content. In places where such content exists, it can be stopped by standing on it and clicking the mouse or moving to it by the “TAB” key and pressing the “Enter” key.</li>
                        </ul>
                        <p><strong>Accessibility Limitations:</strong></p>
                        <p>The site management makes every effort to ensure that all pages displayed are accessible. However, there may be pages that have not yet been made accessible, or for which a suitable technological solution has not yet been found. In addition, in external advertisements, which were inserted by business owners advertising on the site, the accessibility may not be complete or sufficient.</p>
                        <p><strong>Encountered a problem? We are here to help!</strong></p>
                        <p><a href="mailto:support@studywise.io">support@studywise.io</a></p>
                    </Typography>
                </Grid>

            </Grid>
        </>
    );
};

export default AccessibilityStatement;
