// src/components/SummaryStep.js
import React, { useEffect } from 'react';
import { Box, Button, Typography, Grid, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useLanguage } from '../../contexts/languageContext';
import { renderFeatures } from '../../utils/configs';
import ROLES from '../../utils/roles';

const SummaryStep = ({ selectedRole, onFinish, userRoles }) => {
    const { languageData } = useLanguage();
    const { freeFeatures, premiumFeatures } = renderFeatures(selectedRole);

    useEffect(() => {
        if (userRoles.some(role => ROLES.specialRoles.includes(role))) {
            onFinish();
        }
    }, [userRoles, onFinish]);

    return (
        <div dir='ltr'>
            <Grid container justifyContent="center" alignItems="flex-start" spacing={4} sx={{ px: 2 }}>
                {/* Free Features */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: '12px', p: 2, backgroundColor: '#f9f9f9' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                {languageData?.onboarding?.summary_step?.free_features || "Free Features"}
                            </Typography>
                            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                {freeFeatures.map((feature, index) => (
                                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <CheckCircleIcon sx={{ color: 'success.main', mr: 1 }} />
                                        <Typography variant="subtitle1">{feature}</Typography>
                                    </li>
                                ))}
                            </ul>
                            <Box textAlign="center" mt={4}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        minWidth: '200px',
                                        '&:hover': { backgroundColor: 'darkgreen' },
                                    }}
                                    onClick={() => onFinish(false)}
                                >
                                    {languageData?.onboarding?.summary_step?.free_button || "Free Access"}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Premium Features */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: '12px', p: 2, backgroundColor: '#f7f0ff' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                {languageData?.onboarding?.summary_step?.premium_features || "Premium Features"}
                            </Typography>
                            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                {premiumFeatures.map((feature, index) => (
                                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <LockIcon sx={{ color: 'secondary.main', mr: 1 }} />
                                        <Typography variant="subtitle1">{feature}</Typography>
                                    </li>
                                ))}
                            </ul>
                            <Box textAlign="center" mt={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        minWidth: '200px',
                                        '&:hover': { backgroundColor: 'purple' },
                                    }}
                                    onClick={() => onFinish(true)}
                                >
                                    {languageData?.onboarding?.summary_step?.premium_button || "Upgrade to Premium"}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

SummaryStep.propTypes = {
    selectedRole: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
};

export default SummaryStep;
