import api from "./api"
import { HttpStatusCode } from "axios"

export const getAllResponses = async (studentId) => {
    try {
        const res = await api.get(`/students/${studentId}/responses`)
        if (res.status === HttpStatusCode.Ok) {
            return res.data?.responses
        } else {
            console.error('Responses fetch failed')
            return Promise.reject(`Request failed with status ${res.status}`)
        }
    } catch (error) {
        return Promise.reject(error)
    }
    }

export const getResponseInfo = async (studentId, responseId) => {
    try {
        const res = await api.get(`/students/${studentId}/responses/${responseId}`)
        if (res.status === HttpStatusCode.Ok) {
            return res.data
        } else {
            console.error('Info fetch failed')
            return Promise.reject(`Request failed with status ${res.status}`)
        }
    } catch (error) {
        return Promise.reject(error)
    }
    }