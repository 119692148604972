import { useEffect, useState, Fragment } from "react";
import { useSubjects } from "../../contexts/SubjectsContext";
import { IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DeleteConfirmationPopver } from "../../components/DeleteConfirmationPopover";
import { paddingPageStyle } from "../../utils/customTheme";
import Header from "../../components/Header";
import { useUser } from "../../hooks/useUser";
import { homepage } from "../../components/menu/MenuLinks";

function SubjectsDashboard() {
    const { user, isAdmin } = useUser();
    const { subjects, addSubject, editSubject, deleteSubject, fetchSubjects } = useSubjects();
    const [newSubject, setNewSubject] = useState({ labels: { en: '', he: '' }, prompt: '' });
    const [editingSubjectId, setEditingSubjectId] = useState(null);
    const [editingData, setEditingData] = useState({ labels: { en: '', he: '' }, prompt: '' });
    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [subjectIdToDelete, setSubjectIdToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        isAdmin().then(res => {
            if (!res) {
                window.location.href = homepage;
            }
        });
    }, [])


    useEffect(() => {
        if (!subjects || !subjects.length) {
            fetchSubjects()
        }
    }, [subjects, fetchSubjects]);

    if (!user || !user.is_admin)
        return null;

    const handleAddSubject = () => {
        setIsLoading(true);
        if (newSubject.labels.en && newSubject.labels.he && newSubject.prompt) {
            addSubject(newSubject).then(() => {
                setNewSubject({ labels: { en: '', he: '' }, prompt: '' });
            }).finally(() => setIsLoading(false));
        }
    };

    const handleEditSubject = (id) => {
        editSubject(id, editingData).then(() => {
            setEditingSubjectId(null);
            setEditingData({ labels: { en: '', he: '' }, prompt: '' });
        })
    };

    const handleDeleteSubject = () => {
        console.log('deleting subject', subjectIdToDelete);
        deleteSubject(subjectIdToDelete).then(() => {
            setDeletePopoverAnchorEl(null);
            setSubjectIdToDelete(null);
        });
    };

    return (
        <>
            <Header />
            <div style={{ ...paddingPageStyle, marginBottom: '10rem', padding: '16px 32px' }}>
                <Typography variant="h4" textAlign='center'>Subjects Dashboard</Typography>

                <Grid container sx={{ maxWidth: '50rem', py: 4, gap: '10px', justifyContent: 'space-between', margin: 'auto' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Add New Subject</Typography>
                    </Grid>
                    <Grid xs={5.5}>
                        <TextField
                            label="Label (English)"
                            value={newSubject.labels.en}
                            onChange={(e) => setNewSubject({ ...newSubject, labels: { ...newSubject.labels, en: e.target.value } })}
                            variant="outlined"
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid xs={5.5}>
                        <TextField
                            label="Label (Hebrew)"
                            value={newSubject.labels.he}
                            onChange={(e) => setNewSubject({ ...newSubject, labels: { ...newSubject.labels, he: e.target.value } })}
                            variant="outlined"
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            label="Prompt"
                            value={newSubject.prompt}
                            onChange={(e) => setNewSubject({ ...newSubject, prompt: e.target.value })}
                            variant="outlined"
                            multiline
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid xs={12} display='flex' justifyContent='center'>
                        <Button variant="contained" color="primary" sx={{ width: '200px' }} onClick={handleAddSubject}>
                            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Add Subject'}
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Label (English)</TableCell>
                                <TableCell>Label (Hebrew)</TableCell>
                                <TableCell>Prompt</TableCell>
                                <TableCell sx={{ minWidth: '150px' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subjects.map((subject) => (
                                <Fragment key={subject._id}>
                                    <TableRow>
                                        <TableCell>{subject.labels?.en}</TableCell>
                                        <TableCell>{subject.labels?.he}</TableCell>
                                        <TableCell>{subject.prompt}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => {
                                                setEditingSubjectId(subject._id);
                                                setEditingData({ labels: subject.labels, prompt: subject.prompt });
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={(e) => {
                                                setDeletePopoverAnchorEl(e.currentTarget);
                                                setSubjectIdToDelete(subject._id);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {editingSubjectId === subject._id && (
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <TextField
                                                        label="Edit Label (English)"
                                                        value={editingData.labels.en}
                                                        onChange={(e) => setEditingData({ ...editingData, labels: { ...editingData.labels, en: e.target.value } })}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        label="Edit Label (Hebrew)"
                                                        value={editingData.labels.he}
                                                        onChange={(e) => setEditingData({ ...editingData, labels: { ...editingData.labels, he: e.target.value } })}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        label="Edit Prompt"
                                                        value={editingData.prompt}
                                                        onChange={(e) => setEditingData({ ...editingData, prompt: e.target.value })}
                                                        variant="outlined"
                                                        multiline
                                                        sx={{ width: '-webkit-fill-available' }}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell >
                                                <IconButton onClick={() => handleEditSubject(subject._id)}>
                                                    <SaveIcon />
                                                </IconButton>
                                                <IconButton onClick={() => setEditingSubjectId(null)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <DeleteConfirmationPopver
                anchorEl={deletePopoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => setDeletePopoverAnchorEl(null)}
                onCancel={() => setDeletePopoverAnchorEl(null)}
                onDelete={handleDeleteSubject}
                slotProps={{ paper: { sx: { p: 1 } } }}
            />
        </>
    );
}

export default SubjectsDashboard;
