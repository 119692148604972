import React from 'react';
import { Box, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { colors } from '../../utils/customTheme';

const QuizCreationComponent = () => {

    const handleButtonClick = () => {
        const exampleExamId = process.env.REACT_APP_EXAMPLE_EXAM_ID;
        window.open(`https://exam-generator.com/quiz?id=${exampleExamId}`, '_blank');
    };

    return (
        <Box sx={{ p: 4, color: 'white', display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom align="center">
                        Try a demo exam
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom color={colors.text} align="center">
                        Experience StudyWise's Exam Auto-Grade
                    </Typography>
                    <Card sx={{ mt: 2, mb: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="h6">Skills test</Typography>
                                <Typography>2 / 3</Typography>
                            </Box>
                            {['A. Multiple Choice', 'B. Multiple Choice', 'C. Open Ended'].map((option, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Typography sx={{ mr: 2 }}>{option}</Typography>
                                    <Box sx={{ flexGrow: 1, height: 1, bgcolor: 'lightgrey' }} />
                                    <Box sx={{
                                        ml: 2,
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        bgcolor: index === 0 || index === 2 ? 'green' : 'red',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white'
                                    }}>
                                        {index === 0 || index === 2 ? '✓' : '✗'}
                                    </Box>
                                </Box>
                            ))}
                            <Button
                                variant="contained"
                                sx={{ mt: 2, display: 'block', mx: 'auto' }}
                                onClick={handleButtonClick}
                            >
                                Try demo exam
                            </Button>
                        </CardContent>
                    </Card>
                    <Typography variant="body2" align="center" color={colors.text}>
                        Whether you're a teacher, student, or educator, you'll love how simple it is to create and auto-grade exams with StudyWise - try a sample exam.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default QuizCreationComponent;
