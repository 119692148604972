// ReviewHeader.js

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import SettingsButton from '../../components/Settings/SettingsButton';
import SubmitAndShareButton from './SubmitAndShare';
import { useLanguage } from '../../contexts/languageContext';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';

const ReviewHeader = ({ handleShareLink, handleGoToPreview, handleSaveSettings, examJson }) => {
    const { languageData } = useLanguage();

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 0 }}>
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={handleGoToPreview}
                >
                    {languageData?.preview_btn_label ?? ''}
                </Button>
                <SettingsButton button onSubmit={handleSaveSettings} examSettings={examJson?.settings} />
                <SubmitAndShareButton
                    handleSaveSettings={handleSaveSettings}
                    examJson={examJson}
                />
                <ChooseLanguageBtn />
            </Toolbar>
        </AppBar>
    );
};

export default ReviewHeader;
