import React, { useEffect, useState, useMemo } from 'react';
// Components
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Header from '../../components/Header';
// Hooks
import useSnackBar from '../../hooks/useSnackBar';
import { Helmet } from 'react-helmet-async';
import { useMyStudents } from '../../contexts/MyStudentsContext';
import { colors, paddingPageStyle } from '../../utils/customTheme';
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useLanguage } from '../../contexts/languageContext';

const maxRowsPerPage = 10;
const StudentOverview = () => {
    const { studentId } = useParams();
    const navigate = useNavigate();
    const { getStudentById } = useMyStudents();
    const { languageData, isRTL } = useLanguage();
    const [student, setStudent] = useState(null);
    const { openSnackBar } = useSnackBar();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!student) {
            setLoading(true);
            getStudentById(studentId).then((studentRes) => {
                if (studentRes) {
                    setStudent(studentRes);
                }
            }).catch(err => {
                openSnackBar(SnackbarTypes.ERROR.field);
                console.error("Error getting student:", err);
            }).finally(() => setLoading(false));
        }
    }, [student, studentId, getStudentById, openSnackBar]);

    // Memoize columns to avoid recreating on every render
    const columns = useMemo(() => [
        { field: 'exam_title', headerName: languageData?.my_students?.student_overview?.exam_title || 'Exam Title', flex: 1, minWidth: 200 },
        { field: 'grade', headerName: languageData?.my_students?.student_overview?.exam_grade || 'Grade', flex: 0.5, minWidth: 100 },
        {
            field: 'date', headerName: languageData?.my_students?.student_overview?.exam_date || 'Date', flex: 0.5, type: 'dateTime', minWidth: 150,
            valueGetter: (params) => params.value ? new Date(params.value) : null,
            renderCell: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
            }
        },
        {
            field: 'action', headerName: languageData?.my_students?.student_overview?.actions || 'Actions', flex: 0.5, minWidth: 200,
            type: 'actions',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{ backgroundColor: colors.secondary }}
                    onClick={() => {
                        if (!params.row.exam_id || !params.row.id) {
                            console.error('Invalid exam id or response id');
                            openSnackBar(SnackbarTypes.ERROR.field);
                            return;
                        }
                        navigate(`/review?id=${params.row.exam_id}&rid=${params.row.id}`);
                    }}
                >
                    {languageData?.review_btn_label || "Review"}
                </Button>
            ),

        },
    ], [languageData]);

    // Memoize rows to avoid recreating on every render
    const rows = useMemo(() =>
        student?.responses?.map((response, index) => ({
            id: response._id || index,
            exam_title: response.exam_title,
            exam_id: response.exam_id,
            grade: response.grade,
            date: response.date ? new Date(response.date) : null,
            action: null,
        })) || [], [student]);

    return (
        <>
            <Helmet>
                <title>StudyWise | Student Overview</title>
                <meta name="description" content="View and manage the details, progress, and performance of your students. Keep track of their academic journey and provide personalized support to help them succeed." />
                <meta name="keywords" content="student overview, student management, academic progress, student performance, education, StudyWise" />
                <meta name="author" content="StudyWise" />
            </Helmet>
            <Header />
            <div style={{ ...paddingPageStyle, maxWidth: '70rem', padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {student?.studentInfo?.name}
                </Typography>

                <Divider sx={{ my: 3, width: '100%' }} />

                {rows.length > 0 ? (
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            hideFooterPagination={rows.length < maxRowsPerPage}
                            hideFooter={rows.length < maxRowsPerPage}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: maxRowsPerPage, page: 0 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            checkboxSelection={false}         // Hides selection checkboxes
                            disableSelectionOnClick={true}    // Prevents row selection on click
                            sx={{
                                direction: isRTL ? 'rtl' : 'ltr',
                                '& .MuiDataGrid-columnHeader, & .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton': {
                                    backgroundColor: colors.text,
                                    color: '#fff',
                                },
                                '& .MuiDataGrid-cell': {
                                    direction: isRTL ? 'rtl' : 'ltr',
                                    textAlign: isRTL ? 'right' : 'left',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    direction: 'ltr'
                                }
                            }}
                        />
                    </div>
                ) : (loading ? (
                    <CircularProgress disableShrink size={'4rem'} sx={{ color: colors.main, marginTop: '3rem' }} />
                ) : (
                    <p>{languageData?.history?.no_exams || "No exams available"}</p>
                ))}
            </div>
        </>
    );
};

export default StudentOverview;
