import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Toolbar, Link as MuiLink, Popover, Typography, Button, Box } from '@mui/material';
import { Assessment, School, PeopleAlt, Business, Assignment, Chat, AssignmentTurnedIn } from '@mui/icons-material'; // Import icons
import { allowFocusStyle } from '../../utils/customTheme';

// todo: fix icons alignments with texts
const HoverLinkHeader = ({ label }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);

    const getPopoverContent = (label) => {
        switch (label) {
            case 'Product':
            case 'מוצר':
                return (
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }}>
                            <Link
                                to="/product/skills-knowledge-assessment"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assessment color="primary" sx={{ fontSize: 22, mr: 2 }} />
                                <Box>
                                    <Typography variant="h6">Skills and knowledge assessment</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Test skills and knowledge
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/ai-question-generator"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assignment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">AI question generator</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Generate AI-driven questions
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/auto-grading"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <AssignmentTurnedIn color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Auto grading</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Automate grading process
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/feedback-grades"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Chat color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Feedback and grades</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Provide feedback on performance
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/insights-analytics"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assessment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Insights and Analytics</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Get detailed insights
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/mistaken-questions"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assignment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Mistaken questions</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Identify common mistakes
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/moodle-integration"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Business color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Moodle integration</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Seamlessly integrate with Moodle
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/product/lms-integration"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Business color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">LMS integration</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Integrate with your LMS
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                );
            case "Who's it for":
            case "למי זה מתאים":
                return (
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/clients/students"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <PeopleAlt color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Students</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Engage and empower with interactive learning experiences.
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/clients/teachers"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <AssignmentTurnedIn color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Teachers</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Generate and evaluate exams and assessments with ease.
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/clients/professors"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <School color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Professors</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Advance assessment with academic development tools and resources.
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/clients/organizations"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Business color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Organizations</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Meet organizational needs with scalable and customizable employee training.
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                );
            case 'Use Cases':
            case 'שימושים':
                return (
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/exams"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assessment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Exams</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Test skills and knowledge
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/quizzes"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assignment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Quizzes</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Engage students with fun quizzes
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/homework"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <AssignmentTurnedIn color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Homework</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Interactive after school assignments
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/class-interactions"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Chat color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Class Interactions</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Encourage discussions and interactions
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/formative-assessment"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assessment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">Formative Assessment</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Encouraging feedback and motivation
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', ...allowFocusStyle }} >
                            <Link
                                to="/usecase/ai-reports"
                                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                                onClick={handleClose}
                            >
                                <Assessment color="primary" sx={{ fontSize: 22 }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="h6">AI Reports</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Get detailed insights and analytics
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <MuiLink
                color="inherit"
                underline="none"
                sx={{ cursor: 'pointer', fontWeight: '500' }}
                href="#"
                onClick={handleClick}
            >
                {label}
            </MuiLink>
            <Popover
                id="mouse-over-popover"
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {getPopoverContent(label)}
            </Popover>
        </div>
    );
};

export default HoverLinkHeader;
