import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { colors } from '../../utils/customTheme';
import validationGif from '../../assets/validation.gif';

const CountdownTimer = ({ startTime, endTime, languageData, isRTL }) => {
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [status, setStatus] = useState('pending'); // 'pending', 'ongoing', 'ended'

    const calculateCountdown = () => {
        const now = roundToSeconds(new Date());
        const start = roundToSeconds(parseDate(startTime));
        const end = roundToSeconds(parseDate(endTime));

        if (!start || !end) {
            return { status: 'error', timeRemaining: null };
        }

        if (now < start) {
            return { status: 'pending', timeRemaining: start.getTime() - now.getTime() };
        } else if (now >= start && now <= end) {
            return { status: 'ongoing', timeRemaining: end.getTime() - now.getTime() };
        } else {
            return { status: 'ended', timeRemaining: 0 };
        }
    };

    useEffect(() => {
        // Initialize countdown
        const { status, timeRemaining } = calculateCountdown();
        setStatus(status);
        setTimeRemaining(timeRemaining);

        // Update every second
        const timer = setInterval(() => {
            const { status, timeRemaining } = calculateCountdown();
            setStatus(status);
            setTimeRemaining(timeRemaining);
        }, 1000);

        // Cleanup on component unmount
        return () => clearInterval(timer);
    }, [startTime, endTime]);

    // Format time remaining
    const formatTime = (milliseconds) => {
        if (!milliseconds || milliseconds <= 0) return '00:00:00';

        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // Determine message based on status
    const getMessage = () => {
        switch (status) {
            case 'pending':
                return languageData?.exam_not_started || 'Exam has not started yet';
            case 'ended':
                return languageData?.exam_ended || 'Exam has ended';
            case 'error':
                return languageData?.exam_time_error || 'Unable to determine exam time';
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.onboarding,
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                direction: isRTL ? 'rtl' : 'ltr'
            }}
        >
            {(status === 'pending' || status === 'ended' || status === 'error') && (
                <>
                    <Typography variant="h6" gutterBottom>
                        {getMessage()}
                    </Typography>
                    <img src={validationGif} alt='validation' width={80} height={80} />
                </>
            )}

            {status === 'pending' && timeRemaining && timeRemaining > 0 && (
                <Typography variant="h5" sx={{ mt: 2 }}>
                    {formatTime(timeRemaining)}
                </Typography>
            )}

            {status === 'ongoing' && timeRemaining && timeRemaining > 0 && (
                <Typography variant="h5" sx={{ mt: 2, color: 'green' }}>
                    {languageData?.exam_ongoing || 'Exam has started'}: {formatTime(timeRemaining)}
                </Typography>
            )}
        </Box>
    );
};

export default CountdownTimer;

function parseDate(dateInput) {
    if (!dateInput) return null;

    const parsedDate = new Date(dateInput);
    if (isNaN(parsedDate.getTime())) {
        console.error('Invalid date input:', dateInput);
        return null;
    }
    return parsedDate;
}

function roundToSeconds(date) {
    if (!date || isNaN(date.getTime())) return null;

    date.setMilliseconds(0);
    return date;
}
