import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllUserStudents } from '../requests/user';
import { useUser } from '../hooks/useUser';

// Create the context
const MyStudentsContext = createContext();

const useMyStudents = () => {
    const context = useContext(MyStudentsContext);
    if (!context) {
        throw new Error('useMyStudents must be used within a MyStudentsProvider');
    }
    return context;
};


// Create a provider component
const MyStudentsProvider = ({ children }) => {
    const [students, setStudents] = useState([]);
    const { user } = useUser();

    useEffect(() => {
        if (!user)
            setStudents([]);
    }, [user]);

    async function fetchMyStudents() {
        if (!user) return Promise.reject('User not found');
        if (students.length > 0) return students;
        return getAllUserStudents(user._id).then(result => {
            const fetchedStudents = result?.students
            setStudents(fetchedStudents);
            return fetchedStudents;
        })
    }

    const getStudentById = async (studentId) => {
        if (!students.length) {
            return fetchMyStudents().then(fetchedStudents => {
                return fetchedStudents.find(student => student?.studentInfo?._id === studentId);
            });
        }
        return Promise.resolve(students.find(student => student?.studentInfo?._id === studentId));
    };

    return (
        <MyStudentsContext.Provider value={{ students, setStudents, fetchMyStudents, getStudentById }}>
            {children}
        </MyStudentsContext.Provider>
    );
};

export { useMyStudents, MyStudentsProvider };