import React, { useState, useMemo } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid, Chip, Box, Divider, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/languageContext';
// Utils
import { capitalizeFirstLetter } from '../utils';
import { colors } from '../utils/customTheme';
import { languages } from '../utils/examOptions';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const MAX_TITLE_CHARS = 30;

const StudentExamCard = ({ title = "", date, examId = "", responseId = "", totalQuestions = null, language = null, totalGrade = 100, grade }) => {
    const { languageData, isRTL } = useLanguage();
    const [openTooltip, setOpenTooltip] = useState(false);
    const isHebExam = useMemo(() => language?.toLowerCase() === languages.Hebrew.toLowerCase(), [language]);

    const handleViewResults = () => {
        sessionStorage.setItem('responseId', responseId);
        sessionStorage.setItem('examId', examId);
        window.open('/results', '_blank');
    };

    const shortenedTitle = useMemo(() => {
        return title.length > MAX_TITLE_CHARS ? `${title.slice(0, MAX_TITLE_CHARS)}...` : title;
    }, [title]);

    const handleOpenTooltip = () => {
        if (shortenedTitle !== title) setOpenTooltip(true);
    };

    return (
        <Card
            sx={{ boxShadow: 1, borderRadius: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
            dir={isRTL ? 'rtl' : 'ltr'}
        >
            {/* Title Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: '#E9F4FF', color: colors.main }}>
                <Tooltip title={title} arrow open={openTooltip} PopperProps={{ sx: { textAlign: 'center' } }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', flexGrow: 1, direction: isHebExam ? 'rtl' : 'ltr' }} onMouseEnter={handleOpenTooltip} onMouseLeave={() => setOpenTooltip(false)}>
                        {shortenedTitle}
                    </Typography>
                </Tooltip>
            </Box>

            <Divider />

            {/* Card Content */}
            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <Typography variant="caption" color="textSecondary">
                        {date}
                    </Typography>
                    {grade !== undefined && grade !== null && <Box sx={{ direction: 'ltr', fontWeight: 'bold', color: colors.main }}>
                        <TaskAltIcon color="primary" fontSize='20px' /> &nbsp;
                        {grade + "/" + totalGrade}
                    </Box>}
                </Box>
            </CardContent>

            <Divider sx={{ my: 1 }} />

            {/* Chips Section */}
            <CardContent sx={{ flex: 1, p: 0 }}>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'nowrap', px: 2, mt: 1, justifyContent: 'center' }}>
                    {totalQuestions && <Chip label={`${totalQuestions} ${languageData?.history?.questions_label ?? `Questions`}`} variant="outlined" />}
                    {language && <Chip label={capitalizeFirstLetter(language)} variant="outlined" />}
                </Box>
            </CardContent>

            <Divider sx={{ my: 1, mt: 2 }} />

            {/* Action Section */}
            <CardActions sx={{ justifyContent: 'center', p: 2, gap: 2 }}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleViewResults}
                    sx={{ borderRadius: '20px', px: 4 }}
                >
                    {languageData?.quiz?.completion_msgs?.view_results || 'View Results'}
                </Button>
            </CardActions>
        </Card>
    );
};

export default StudentExamCard;
