import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab } from '@mui/material';
import ShareTab from './ShareTab';
import { useUser } from '../../hooks/useUser';
import { colors } from '../../utils/customTheme';

const shareTabsValues = {
  students: {
    key: 'students',
    url: (eid) => `${window.location.origin}/quiz?id=${eid}`
  },
  colleagues: {
    key: 'colleagues',
    url: (eid, uid) => `${window.location.origin}/share-redirect?eid=${eid}&uid=${uid}`
  }
}
const tabHoverStyle = { '&:hover': { color: colors.main, backgroundColor: 'transparent' } }

const ShareDialog = ({ isRTL, languageData, open, onClose, examId }) => {
  const [tabValue, setTabValue] = useState(shareTabsValues.students.key)
  const { user } = useUser()

  const onTabChange = (e, newVal) => {
    setTabValue(newVal)
  }

  return (
    <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'}>
      {/* <DialogTitle>{languageData.title}</DialogTitle> */}
      <DialogContent>
        <Tabs value={tabValue} onChange={onTabChange} aria-label="share options" sx={{ paddingBottom: '20px' }}>
          <Tab label={languageData?.shareWithStudents?.label} value={shareTabsValues.students.key} sx={tabHoverStyle} />
          <Tab label={languageData?.shareWithColleagues?.label} value={shareTabsValues.colleagues.key} sx={tabHoverStyle} />
        </Tabs>
        {tabValue === shareTabsValues.students.key &&
          <ShareTab
            shareUrl={shareTabsValues.students.url(examId)}
            onClose={onClose}
            languageData={languageData?.shareWithStudents}
          />
        }
        {tabValue === shareTabsValues.colleagues.key &&
          <ShareTab
            shareUrl={shareTabsValues.colleagues.url(examId, user?._id)}
            onClose={onClose}
            languageData={languageData?.shareWithColleagues}
          />
        }
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
