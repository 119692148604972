import React, { useMemo } from 'react';
import { Box, Button, CircularProgress, IconButton, Grid, Paper, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { isMobile } from '../../utils';
import { DownloadCSVButton } from '../../components/B2B/DownloadCSV';
import { useLanguage } from '../../contexts/languageContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import { colors } from '../../utils/customTheme';

const maxRowsPerPage = 10;
const StudentTable = ({ students: responses, selectedStudent, onStudentClick, onRequestReport, reportState, onDeleteResponse }) => {
    const { isRTL, languageData } = useLanguage();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const studentIdToDeleteRef = React.useRef(null);

    const studentsGradesList = useMemo(() => responses.map(student => ({
        [languageData?.review?.response?.full_name ?? 'Full Name']: student.student_name,
        [languageData?.review?.response?.id ?? 'Id']: student.student_info.personal_id ?? '',
        [languageData?.review?.response?.grade ?? 'grade']: student.grade,
        [languageData?.review?.response?.date ?? 'date']: student.date,
        [languageData?.review?.response?.email ?? 'email']: student.email ?? '',
    })), [responses, isRTL]);

    const handleClickDelete = (target, studentId) => {
        setAnchorEl(target);
        studentIdToDeleteRef.current = studentId;
    };

    // Define the columns for the DataGrid
    const columns = [
        { field: 'student_name', headerName: languageData?.review?.students_table?.name ?? 'Name', width: 150 },
        { field: 'personal_id', headerName: languageData?.review?.students_table?.id ?? 'ID', width: 100 },
        { field: 'grade', headerName: languageData?.review?.students_table?.grade ?? 'Grade', width: 100 },
        {
            field: 'date', headerName: languageData?.review?.students_table?.submission_time ?? 'Submission Time', type: 'dateTime',
            width: 160,
            valueGetter: (val) => { return val ? new Date(val) : null },
            valueFormatter: (val) => {
                if (!val) return '---';
                const date = new Date(val);
                return date.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
            },
        },
        {
            field: 'status',
            headerName: languageData?.review?.students_table?.status ?? 'Status',
            width: 120,
            renderCell: (params) => (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }} >
                    <div>
                        {params.row.reviewSubmitted && <CheckCircleIcon style={{ fontSize: 20, marginRight: 4, color: 'lightgreen' }} />}
                        {params.row.saveForLater && <AccessTimeIcon style={{ color: 'orange', fontSize: 20, marginRight: 4 }} />}
                    </div>
                    {params.row.supervised.auto_submitted && params.row.supervised.supervisor_notes && (
                        <Tooltip title={languageData?.review?.students_table?.left_exam_msg ?? "The student left the exam"} arrow>
                            <ReportIcon color="error" style={{ fontSize: 24, }} />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: languageData?.review?.students_table?.actions ?? 'Actions',
            minWidth: 300,
            flex: 1,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button variant="outlined" sx={{ maxHeight: '40px' }} onClick={() => onStudentClick(responses?.find(response => response._id === params.row.id))}>
                        {languageData?.review?.review}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ maxHeight: '40px' }}
                        onClick={() => onRequestReport(params.row.id)}
                        disabled={reportState.loading && reportState.response_id === params.row.id}
                    >
                        {reportState.loading && reportState.response_id === params.row.id ? <CircularProgress size={22} color="secondary" /> : languageData?.review?.report}
                    </Button>
                    <IconButton onClick={(e) => handleClickDelete(e.currentTarget, params.row.id)}>
                        <DeleteOutlineIcon color="error" />
                    </IconButton>
                </div>
            ),
        }
    ];

    // Map the responses data to fit the DataGrid row format
    const rows = responses.map((response) => ({
        id: response._id,
        student_name: response.student_name ?? '---',
        personal_id: response.student_info?.personal_id ?? '---',
        grade: response.grade,
        date: response.date,
        reviewSubmitted: response.reviewSubmitted,
        saveForLater: response.saveForLater,
        supervised: response.supervised,
    }));

    // Function to apply alternating row colors
    const getRowClassName = (params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
    };

    return (
        <Box mb={2} sx={{ direction: isRTL ? 'rtl' : 'ltr', width: '100%' }}>
            <DownloadCSVButton list={studentsGradesList} filename={languageData?.review?.grades_label ?? 'Grades'} />
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={12} lg={12}>
                    <Paper>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            onRowClick={(params) => onStudentClick(responses?.find(response => response._id === params.row.id))}
                            hideFooterPagination={rows.length < maxRowsPerPage}
                            hideFooter={rows.length < maxRowsPerPage}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: maxRowsPerPage, page: 0 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            autoHeight
                            disableSelectionOnClick
                            getRowClassName={getRowClassName}
                            sx={{
                                direction: isRTL ? 'rtl' : 'ltr',
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: colors.lightblue,
                                    color: '#2a2a2a',
                                },
                                '& .MuiDataGrid-cell': {
                                    width: 'auto',
                                    direction: isRTL ? 'rtl' : 'ltr',
                                    textAlign: isRTL ? 'right' : 'left',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    direction: 'ltr'
                                },
                                '& .evenRow': {
                                    backgroundColor: colors.bg,
                                },
                                '& .oddRow': {
                                    backgroundColor: '#ffffff', // Odd row background color
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    direction: 'ltr'
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <DeleteConfirmationPopver
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setAnchorEl(null)}
                onCancel={() => setAnchorEl(null)}
                onDelete={() => { setAnchorEl(null); onDeleteResponse(studentIdToDeleteRef.current); }}
            />
        </Box>
    );
};

export default StudentTable;
