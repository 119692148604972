import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import ROLES from '../utils/roles';
import UploadFiles from './Upload/UploadFiles';
import { Typography } from '@mui/material';
import readPPT from '../requests/ppt';
import readTXT from '../requests/txt';
import { readPDF } from '../requests/pdf';
import { readDocx } from '../requests/docx';
import useSnackBar from '../hooks/useSnackBar';
import { useLoader, loaderTypes } from '../hooks/useLoader';
import { SnackbarTypes } from '../utils/snackbarTypes';

function FileUploadHandler({ onInput, maxFiles, userRoles = ['FREE'], maxCharsLimit = 10000 }) {
    /** @type {[{file: File, name: string, sizeText: string, text?: string, isValidType?: boolean}[], Function]} */
    const [files, setFiles] = useState([]);
    const [totalChars, setTotalChars] = useState(0);
    const { language } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const { openLoader, closeLoader } = useLoader();

    const FILE_TEXT_LIMIT = Math.max(ROLES.getMaxLimit(userRoles), maxCharsLimit);
    const isPremiumUser = ROLES.isPremiumRole(userRoles);

    const validFilesFilter = useCallback(file => file.isValidType, []);
    const exceededChars = useMemo(() => totalChars > FILE_TEXT_LIMIT, [totalChars, FILE_TEXT_LIMIT]);

    useEffect(() => {
        let text = convertFilesToText(files.filter(validFilesFilter));
        setTotalChars(text?.length ?? 0);
        if (files.length > 0 && files?.every(validFilesFilter)) {
            if (!text || !text.length) {
                return;
            }
            if (text?.length > FILE_TEXT_LIMIT) {
                text = text.substring(0, FILE_TEXT_LIMIT);
            }
            onInput(text);
        }

    }, [files]);

    useEffect(() => {
        if (exceededChars) {
            openSnackBar(SnackbarTypes.FILE_LIMIT.field);
        }
    }, [totalChars]);

    const handleUpload = async (newFiles) => {
        if (newFiles.length && newFiles.some(file => !file.isValidType)) {
            openSnackBar(SnackbarTypes.ERROR.field);
            return;
        }

        openSnackBar(SnackbarTypes.READING_FILE.field);
        openLoader(loaderTypes.READING_FILE);
        let result = newFiles;
        readFiles(newFiles).then(filesAfterRead => {
            if (filesAfterRead && filesAfterRead?.length) {
                result = filesAfterRead;

                if (filesAfterRead[0]?.text?.length > 1)
                    openSnackBar(SnackbarTypes.FILE_READY.field);
            }
            return filesAfterRead;
        }).finally(() => {
            setFiles(prevFiles => [...prevFiles, ...result]);
            closeLoader();
        });
    };

    const handleRemoveFile = (index) => {
        if (index >= 0 && index < files?.length) {
            setFiles(prevFiles => {
                const newFiles = [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)];
                return newFiles;
            });
        }
    };

    async function readFiles(files) {
        return Promise.all(
            files.map(file => {
                const type = file.file.type;
                if (file.isValidType && (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type === 'application/vnd.ms-powerpoint')) {
                    return handleReadResponse(readPPT, file);
                } else if (type === 'application/pdf') {
                    return handleReadResponse(readPDF, file);
                } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    return handleReadResponse(readDocx, file);
                } else if (type === 'text/plain' || file.file.name.toLowerCase().endsWith('.vtt')) {
                    return handleReadResponse(readTXT, file);
                } else {
                    file.text = '';
                    return new Promise(res => res(file));
                }
            })
        );
    }

    function convertFilesToText(files) {
        if (files.length <= 1) {
            return files[0]?.text ?? '';
        }

        return files.map((file, index) => `Text ${index + 1}:\n${file?.text ?? ''}`).join('\n\n');
    }

    async function handleReadResponse(readFunc, file) {
        return readFunc(file.file).then(response => {
            if (!response || !response?.data?.text?.length) {
                openSnackBar(SnackbarTypes.CHECK_FILE.field);
                file.text = '';
            } else {
                file.text = response.data.text;
            }
            return file;
        }).catch(() => {
            openSnackBar(SnackbarTypes.ERROR.field);
            file.text = '';
            return file;
        });
    }


    return (
        <UploadFiles files={files} onUpload={handleUpload} onRemove={handleRemoveFile} isPremiumUser={isPremiumUser} maxFiles={maxFiles} disabled={exceededChars}>
            <Typography variant='body2'>
                {totalChars}/{FILE_TEXT_LIMIT} {language?.exam?.charactersUploaded || 'characters'}
            </Typography>
        </UploadFiles>
    );
}

export default FileUploadHandler;

