// OrgForm.js
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { TextField, Button, Container, Typography, Grid, FormControlLabel, Switch, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UploadCSV from './UploadCSV';
import UploadImage from './UploadImage';
import { createOrg, editOrg } from '../../requests/organizations';
import useSnackBar from '../../hooks/useSnackBar'
import { escapeRegex } from '../../utils/inputValidations';
import SSO from '../../utils/ssoTypes';
import { useDebounce } from '../../hooks/useDebounce';

const OrgForm = ({ org }) => {
    const { openSnackBar } = useSnackBar()
    const [orgState, setOrgState] = useState({
        ...org,
        whitelist: org?.whitelist ?? [],
        use_whitelist: org?.use_whitelist ?? false, // Add use_whitelist here
        email_settings: {
            ...org?.email_settings,
            email_regex: org?.email_settings?.email_regex ? org?.email_settings?.email_regex.substring(3).replaceAll('\\', '') : '',
        }
    });
    const [formStatus, setFormStatus] = useState('primary');
    const [displayWhitelist, setDisplayWhitelist] = useState(Boolean(!!org?.whitelist || org?.use_whitelist)); // Toggle for whitelist
    const [manager, setManager] = useState(org?.manager);
    const uploadImgRef = useRef(null)
    const uploadCsvRef = useRef(null)
    const signupLink = org?._id ? `https://exam-generator.com/org/signup?id=${org._id}` : null
    const orgDashboardLink = org?._id ? location.origin + `/dashboard/org/${org._id}` : null
    const debouncedSetOrgState = useDebounce(setOrgState, 300);

    const resetForm = () => {
        setOrgState({ name: '', manager: '', total_exams: '', logo: '', whitelist: '', use_whitelist: false });
        setFormStatus('primary');
        setDisplayWhitelist(false); // Reset the toggle
        if (uploadImgRef.current) uploadImgRef.current.reset();
        if (uploadCsvRef.current) uploadCsvRef.current.reset();
    };

    const AfterCSVUpload = (jsonDataFromCsv) => {
        setOrgState(prevOrgState => ({ ...prevOrgState, whitelist: jsonDataFromCsv }));
        console.log('whitelist uploaded:', jsonDataFromCsv);
    };

    const AfterImageUpload = (base64String) => {
        setOrgState(prevOrgState => ({ ...prevOrgState, logo: base64String }));
    };

    function handleSetManager(e) {
        const value = e.target.value;
        setManager(value);

        const emails = value.split(',').map(email => email.trim()).filter(email => email && email.includes('@')); // Filter out invalid emails
        debouncedSetOrgState(prevOrg => ({ ...prevOrg, manager: emails }));
    };


    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!orgState.name) {
            alert('Please fill in all required fields.');
            return;
        }

        // Avoiding override the current values (if not specified)
        const orgCopy = { ...orgState, use_whitelist: displayWhitelist }; // Include use_whitelist here
        if (!orgCopy.whitelist || !displayWhitelist) {
            delete orgCopy.whitelist; // Remove whitelist if not used
        }
        if (!orgCopy.logo) delete orgCopy.logo;
        if (orgCopy.email_settings.email_regex)
            orgCopy.email_settings.email_regex = `@.*${escapeRegex(orgCopy.email_settings.email_regex)}`;

        console.log(orgCopy)
        new Promise((res) => {
            res(
                org?._id
                    ? editOrg(org._id, orgCopy, openSnackBar)
                    : createOrg(orgCopy, openSnackBar)
            )
        }).finally(() => {
            setFormStatus('success');
            resetForm();
        });
    };

    const toggleWhitelistUsage = (e) => {
        const checked = e.target.checked;
        setDisplayWhitelist(checked);
        setOrgState(prevState => ({ ...prevState, use_whitelist: checked })); // Update use_whitelist in orgState
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ mb: 2 }}>
                Organization Form
            </Typography>
            <Container sx={{ width: '100%' }}>
                {signupLink && <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                    Link for signup: <a href={signupLink} target='_blank'>{signupLink}</a>
                </Typography>}
                {orgDashboardLink &&
                    <>
                        <Divider />
                        <Typography variant="body1" align="center" sx={{ mb: 5 }}>
                            Link for dashboard: <a href={orgDashboardLink} target='_blank'>{orgDashboardLink}</a>
                        </Typography>
                    </>
                }
            </Container>
            <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Org Name"
                            value={orgState.name}
                            onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, name: e.target.value }))}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Org short english name"
                            type="text"
                            value={orgState?.en_name}
                            onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, en_name: e.target.value }))}
                            fullWidth
                            helperText="This will be used for future org's unique emails"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Org Managers' Emails (comma-separated)"
                            type="text" // Use 'text' since it's not strictly a single email
                            multiline
                            minRows={2}
                            maxRows={4}
                            value={manager}
                            onChange={handleSetManager}
                            placeholder="email1@example.com, email2@example.com"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="sso-type-label">SSO Type</InputLabel>
                            <Select
                                labelId="sso-type-label"
                                id="sso-type-select"
                                value={orgState?.sso_type ?? ''}
                                label="SSO Type"
                                onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, sso_type: e.target.value }))}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Object.entries(SSO.types).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <UploadImage ref={uploadImgRef} onImageUpload={AfterImageUpload} imageBase64={orgState.logo} />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Toggle for using whitelist */}
                        <FormControlLabel
                            control={
                                <Switch checked={displayWhitelist} onChange={toggleWhitelistUsage} />
                            }
                            label="Use Whitelist"
                        />
                    </Grid>
                    {!displayWhitelist && (
                        <Grid item xs={12}>
                            <TextField
                                label="Email Regex"
                                value={orgState?.email_settings?.email_regex}
                                onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, email_settings: { ...prevOrg.email_settings, email_regex: e.target.value } }))} // Update email_settings
                                fullWidth
                                helperText="Enter a text that EVERY email should contain after the @ like 'studywise.io'"
                            />
                        </Grid>
                    )}
                    {/* Only show CSV upload and whitelist display if use_whitelist is enabled */}
                    {displayWhitelist && (
                        <>
                            <Grid item xs={12}>
                                <UploadCSV onFileUpload={AfterCSVUpload} whitelist={org?.whitelist} ref={uploadCsvRef} orgName={org?.name} />
                            </Grid>
                            {orgState.whitelist.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant="h6">Whitelist:</Typography>
                                    <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                        {orgState.whitelist.map((x, idx) => (
                                            <Typography key={idx} variant='body1'>{x.email} -- {x.name}</Typography>
                                        ))}
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color={formStatus} fullWidth>
                            {formStatus === 'success' ? 'Success' : (org?._id ? 'Update Org' : 'Create Org')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="secondary" fullWidth onClick={resetForm}>
                            Reset Form
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

OrgForm.propTypes = {
    org: PropTypes.shape({
        name: PropTypes.string,
        manager: PropTypes.string,
        logo: PropTypes.any,
        use_whitelist: PropTypes.bool, // Add use_whitelist to PropTypes
    }),
};

export default OrgForm;
