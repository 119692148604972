import api from "./api";
import { processResponsePromise } from './utils';

export function getSubjects() {
    return processResponsePromise(api.get('/subjects'));
}

export function requestAddSubject(newSubject) {
    return processResponsePromise(api.post('/subjects', newSubject));
}

export function requestEditSubject(id, editingData) {
    return processResponsePromise(api.put(`/subjects/${id}`, editingData));
}

export function requestDeleteSubject(id) {
    return processResponsePromise(api.delete(`/subjects/${id}`));
}
