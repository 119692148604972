import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Assessment, Visibility } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const LMSIntegration = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography
                                    variant="h3"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    component="h1"
                                    aria-label="Comprehensive LMS Integration for Exam Creation, Evaluation, and Student Performance Tracking"
                                >
                                    Comprehensive LMS Integration for Exam Creation, Evaluation, and Student Performance Tracking
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Integrating advanced exam creation, grading, and evaluation tools with Learning Management Systems (LMS) is key for improving assessment efficiency in educational institutions, corporate training programs, and online learning platforms. Our platform provides seamless LMS integration, enabling educators to create, evaluate, and track exams effortlessly within their preferred LMS environments. By streamlining exam creation, real-time grading, and performance analytics, educators can focus on delivering high-quality learning experiences, while students benefit from clearer feedback and progress tracking.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Our exam creation and evaluation system integrates effortlessly with popular LMS platforms such as Moodle, Blackboard, Canvas, and Google Classroom. This flexible integration allows instructors to design custom exams, automate grading for various question types, and generate detailed performance reports for students. By leveraging our exam integration tools, instructors can improve the speed, accuracy, and flexibility of their assessment processes, while still maintaining complete control over manual grading and feedback for more complex questions like essays.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                With our LMS integration, educators can manage all aspects of assessments—ranging from quizzes to comprehensive exams—directly from within their LMS. Whether you need to create formative assessments or summative evaluations, our platform supports various question formats, automated feedback generation, and comprehensive analytics to improve student outcomes. From small classrooms to large-scale online learning environments, our LMS integration tools are scalable, adaptable, and designed to fit the evolving needs of modern educators and trainers.
                            </Typography>
                        </Grid>

                        {/* Key Features Column */}
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                Key Features of LMS Exam Integration
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                    <Typography variant="h6">Customizable and Flexible Exam Creation</Typography>
                                </Box>
                                Our platform offers highly customizable exam creation features, enabling educators to craft exams that align with specific learning objectives. Educators can create diverse question types such as multiple-choice, true/false, short answer, essays, matching, and fill-in-the-blank questions. This ensures that assessments are comprehensive and cater to various knowledge levels and learning outcomes.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                    <Typography variant="h6">Automated Grading for Efficiency</Typography>
                                </Box>
                                Reduce the time spent grading with our platform’s automated grading system. Exams with multiple-choice, true/false, and matching questions are graded automatically, providing instant results for students. For subjective responses like essays, teachers can provide personalized feedback and grades directly within the LMS, streamlining the evaluation process while ensuring detailed and precise feedback.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Visibility color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                    <Typography variant="h6">Detailed Analytics and Reporting</Typography>
                                </Box>
                                Access real-time analytics and reporting features that allow instructors to gain valuable insights into student performance. These reports can track individual progress, highlight areas of strength and weakness, and provide detailed metrics for class-wide performance. Instructors can use this data to refine their teaching methods and provide targeted feedback for students in need of additional support.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                Benefits of LMS Exam Integration
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                Integrating our exam creation and evaluation tools with your LMS offers several advantages:
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                <ul>
                                    <li><strong>Streamlined Exam Management</strong>: Create, administer, and grade exams from a single platform without needing to switch between different systems.</li>
                                    <li><strong>Instant Feedback</strong>: Automated grading for objective questions ensures that students receive immediate feedback, helping them understand mistakes and improve.</li>
                                    <li><strong>Flexible Grading Options</strong>: For essay-based or open-ended questions, instructors can manually review and provide detailed feedback, ensuring that student performance is accurately assessed.</li>
                                    <li><strong>Scalability</strong>: Manage exams for small groups or large classes effortlessly, with tools that are designed to scale according to your institution’s needs.</li>
                                    <li><strong>Multi-language Support</strong>: Conduct assessments in multiple languages, making it ideal for global institutions or organizations with diverse student populations.</li>
                                    <li><strong>Actionable Insights</strong>: Track and monitor student progress through detailed reports that enable instructors to focus on areas requiring improvement.</li>
                                </ul>
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}>
                                This integration is adaptable to various educational levels and professional training environments, making it an invaluable tool for schools, universities, corporations, and certification bodies. It supports the assessment needs of K-12, higher education, and corporate learning programs, all while maintaining ease of use and customization to match specific learning outcomes.
                            </Typography>
                        </Grid>

                        {/* Call to Action Column */}
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                Get Started with LMS Exam Integration Today
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}>
                                Start enhancing your exam and assessment processes with seamless integration into your LMS. Our platform is compatible with popular systems like Moodle, Blackboard, Canvas, and Google Classroom, allowing for easy setup and use. Leverage our powerful exam creation, evaluation, and analytics tools to improve learning outcomes and provide better, data-driven instruction. Contact us to learn more about how our LMS exam integration solution can help streamline your institution's assessment needs.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default LMSIntegration;
