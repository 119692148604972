// Components
import { Button, Stack, Typography } from '@mui/material/'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// Hooks
import { useLanguage } from '../contexts/languageContext'
import { useUser } from '../hooks/useUser';

const GenerateExamButton = ({ disabled, onClick, errorData }) => {
  const { user } = useUser()
  const { languageData } = useLanguage();
  const hasNoExams = (!user?.exams_left || user?.exams_left <= 0);
  const buttonLabel = languageData && languageData.generate_btn_label ? languageData.generate_btn_label : 'Generate Exam'

  return (
    <Stack direction={'column'} display={'flex'} alignItems="center" style={{ margin: 20 }}>
      <Button
        sx={{ p: 1, minWidth: '200px', maxWidth: '300px', fontSize: '1rem', fontWeight: 'bold' }}
        variant='contained'
        color={hasNoExams ? 'secondary' : 'primary'}
        startIcon={hasNoExams ? <AutoAwesomeIcon /> : null}
        onClick={onClick}
        disabled={disabled || errorData.disableBtn}
      >
        {buttonLabel}
      </Button>
      {errorData?.msg && <Typography variant='body2' sx={{ color: 'red', textAlign: 'center' }}>{errorData.msg}</Typography>}
    </Stack>
  );
};

export default GenerateExamButton;

