import React, { createContext, useContext, useEffect, useState } from 'react';
import { getExamSkills, requestAddSkill, requestEditSkill, requestDeleteSkill } from '../requests/skills';
import { useUser } from '../hooks/useUser'

const SkillsContext = createContext();
const TechnicalMathProblemsId = "662100c88dfb3850a5667ce7"

const useSkills = () => {
    return useContext(SkillsContext)
}

const getMathSkill = () => {
    return TechnicalMathProblemsId
}

const SkillsProvider = ({ children }) => {
    const [skills, setSkills] = useState(null);
    const { user } = useUser();
    // Get Skills
    useEffect(() => {
        if (user && !skills)
            fetchSkills()
    }, [user, skills])

    const fetchSkills = async () => {
        return getExamSkills().then(skillsRes => {
            if (skillsRes)
                setSkills(skillsRes)
            return skillsRes
        })
    }
    const addSkill = async (newSkill) => {
        return requestAddSkill(newSkill).then(addedSkill => {
            if (addedSkill) {
                setSkills([...skills, addedSkill])
            }
        });
    }
    const editSkill = async (id, editingData) => {
        return requestEditSkill(id, editingData).then(() => {
            setSkills(skills.map(skill => {
                if (skill._id === id) {
                    return { ...skill, ...editingData }
                }
                return skill
            }))
        });
    }
    const deleteSkill = async (id) => {
        return requestDeleteSkill(id).then(() => {
            setSkills(skills.filter(skill => skill._id !== id))
        })
    }


    return (
        <SkillsContext.Provider value={{ skills, fetchSkills, addSkill, editSkill, deleteSkill }}>
            {children}
        </SkillsContext.Provider>
    );
};

export { SkillsProvider, SkillsContext, useSkills, getMathSkill };
