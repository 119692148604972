import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo'; // Import an alternate icon
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';

const SubmitAndShareButton = ({ allReviewsSubmitted = true, handleSaveSettings, examJson }) => {
    const { languageData } = useLanguage();
    const { show_results } = examJson?.settings || {};

    const handleClick = () => {
        handleSaveSettings({
            ...examJson.settings,
            show_results: !show_results
        });
    };

    return (
        <Tooltip
            title={!allReviewsSubmitted
                ? (languageData?.review?.share_tooltip ?? 'Submit reviews to all students to enable sharing')
                : ''
            }
            placement="top"
            arrow
        >
            <Box
            >
                <Button
                    color={show_results ? 'error' : 'primary'}
                    onClick={handleClick}
                    disabled={!allReviewsSubmitted}
                    variant="contained"
                    startIcon={show_results ? <UndoIcon /> : <SendIcon />}
                >
                    {show_results
                        ? (languageData?.review?.undo_share_label ?? 'Undo Sharing')
                        : (languageData?.review?.share_label ?? 'Submit & Share')}
                </Button>
            </Box>
        </Tooltip>
    );
};

export default SubmitAndShareButton;
