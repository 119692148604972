import React, { useState, useMemo } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid, Chip, Box, IconButton, Menu, MenuItem, Divider, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
// Components
import ShareButton from './Share/ShareButton';
import DuplicateExamButton from './DuplicateExamButton';
// Hooks
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../hooks/useSnackBar';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import { useUser } from '../hooks/useUser';
// Utils
import { capitalizeFirstLetter, isMobile } from '../utils';
import { colors } from '../utils/customTheme';
import { languages } from '../utils/examOptions';
import { getExamStatusChip } from '../utils';

const MAX_TITLE_CHARS = 20;

const ExamCard = ({ title = "Exam Title", date, id = "", deleteExam, responsesCount = null, totalQuestions = null, difficulty = null, source = null, language = null, startsAt = null, endsAt = null }) => {
    const navigate = useNavigate();
    const { openSnackBar } = useSnackBar();
    const { languageData, isRTL } = useLanguage();
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openTooltip, setOpenTooltip] = useState(false);
    const isHebExam = useMemo(() => language?.toLowerCase() === languages.Hebrew.toLowerCase(), [language]);

    const moveToPreview = () => {
        if (!id) {
            openSnackBar(SnackbarTypes.ERROR.field);
            return;
        }
        navigate(`/preview?id=${id}`);
    };

    const moveToReview = () => {
        if (!id) {
            navigate('/exam');
            return;
        }
        navigate(`/review?id=${id}`);
    };

    const handleDeleteExam = () => {
        deleteExam(id);
        handleCloseMenu();
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const shortenedTitle = useMemo(() => {
        return title.length > MAX_TITLE_CHARS ? `${title.slice(0, MAX_TITLE_CHARS)}...` : title;
    }, [title]);

    const handleOpenTooltip = () => {
        if (shortenedTitle !== title)
            setOpenTooltip(true);
    };

    return (
        <Card sx={{ boxShadow: 1, borderRadius: 2, display: 'flex', flexDirection: 'column', height: '100%' }} dir={isRTL ? 'rtl' : 'ltr'}>
            {/* Title and Menu Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: colors.bg, color: colors.main }}>
                <IconButton aria-label="menu" onClick={handleOpenMenu}>
                    <MoreVertIcon />
                </IconButton>
                <ExamCardMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} handleDeleteExam={handleDeleteExam} languageData={languageData} id={id} user={user} />
                <Tooltip title={title} arrow open={openTooltip} PopperProps={{ sx: { textAlign: 'center' } }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', flexGrow: 1, direction: isHebExam ? 'rtl' : 'ltr' }} onMouseEnter={handleOpenTooltip} onMouseLeave={() => setOpenTooltip(false)}>
                        {shortenedTitle}
                    </Typography>
                </Tooltip>
            </Box>

            <Divider />

            <CardContent
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                    flexFlow: 'column',
                    height: isMobile ? 'auto' : '-webkit-fill-available',
                }}
            >
                <Typography variant="caption" color="textSecondary">
                    {date}
                </Typography>

                {responsesCount !== null && (
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <PeopleIcon fontSize="small" />
                        {responsesCount}
                    </Typography>
                )}

                {getExamStatusChip({ startsAt, endsAt })}

            </CardContent>


            <Divider sx={{ my: 1 }} />

            <CardContent sx={{ flex: 1, p: 0 }}>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'nowrap', px: 2, mt: 1, justifyContent: 'center' }}>
                    {totalQuestions && <Chip label={`${totalQuestions} ${languageData?.history?.questions_label ?? `Questions`}`} color='primary' variant='outlined' />}
                    {difficulty && <Chip label={`${capitalizeFirstLetter(difficulty)}`} color='primary' variant='outlined' />}
                    {source && <Chip label={`${capitalizeFirstLetter(source)}`} color='primary' variant='outlined' />}
                    {language && <Chip label={`${capitalizeFirstLetter(language)}`} color='primary' variant='outlined' />}
                </Box>
            </CardContent>

            <Divider sx={{ my: 1, mt: 2 }} />

            <CardActions sx={{ justifyContent: 'center', p: 1, gap: 2 }}>
                <Button onClick={moveToReview} variant="contained" color="primary" sx={{ borderRadius: '20px', px: 4 }}>
                    {languageData?.review_btn_label || 'Review'}
                </Button>
                <Button onClick={moveToPreview} variant="outlined" sx={{ borderRadius: '20px', px: 4 }}>
                    {languageData?.preview_btn_label || 'Preview'}
                </Button>
            </CardActions>
        </Card>
    );
}

const ExamCardMenu = ({ anchorEl, handleCloseMenu, handleDeleteExam, languageData, id, user }) => {
    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem>
                <ShareButton languageData={languageData?.preview} examId={id} variant='text' icon={<ShareIcon />} color='primary' />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCloseMenu}>
                <DuplicateExamButton examId={id} userId={user?._id} />
            </MenuItem>
            <Divider />
            <MenuItem sx={{ color: 'red' }} onClick={handleDeleteExam}>
                <Button startIcon={<DeleteIcon />} size="small" sx={{ color: 'red' }}>
                    {languageData?.delete_btn_label || "Delete"}
                </Button>
            </MenuItem>
        </Menu>
    );
};

export default ExamCard;
