import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Insights } from '@mui/icons-material';
import AcademiaProfessorsImg from '../../../assets/features/professors.jpg'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const AcademiaProfessorsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography
                                    variant="h3"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    component="h1"
                                    aria-label="Empower Academia Professors with AI-Driven Assessment Tools"
                                >
                                    Empower Academia Professors with Cutting-Edge AI-Driven Assessment Tools
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Artificial Intelligence (AI) has become an essential driver in modern education, especially for academia professors who seek to enhance their teaching methods and student engagement. Our AI-powered platform is tailored to the unique needs of educators, allowing them to create, manage, and analyze tests and exams with unparalleled efficiency. This advanced AI technology enables professors to develop customized assessments that align perfectly with their academic objectives, ensuring that each test or exam is not only comprehensive but also relevant to the specific curriculum being taught. By prioritizing AI in the educational market, we aim to strengthen our presence and provide professors with the tools they need to succeed in today's competitive academic environment.

                                Furthermore, our platform's AI-driven capabilities extend beyond simple test creation. It offers deep insights into student performance, helping educators identify areas where students excel and where they may need additional support. This data-driven approach allows professors to refine their teaching strategies, creating a more personalized learning experience for each student. By leveraging AI, professors can ensure that their assessments are not only fair and accurate but also adaptive to the needs of their students, making education more effective and accessible.

                                Our AI technology is a vital asset in the academic market, where we see tremendous growth potential. As we continue to innovate and improve our platform, we remain committed to providing academia professors with the most advanced tools available. Whether you're developing a new exam, refining an existing test, or exploring innovative online teaching methods, our AI-powered platform offers the flexibility and power you need to excel. This dedication to AI in education underscores our commitment to staying ahead in this rapidly evolving field, where the stakes for student success are higher than ever.
                            </Typography>
                        </Grid>

                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    variant="h3"
                                    component="h2"
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Why Academia Professors Should Choose Our AI-Powered Platform
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Advanced Test Creation and Assessment Strategies
                                        </Typography>
                                    </Box>
                                    Our platform is designed with advanced AI capabilities to empower professors in creating detailed and customized exams and assessments. Whether you're focusing on formative assessments or high-stakes exams, our tools provide the flexibility to design tests that meet a wide range of academic needs. With support for various educational standards and objectives, our platform ensures that every assessment is tailored to the specific requirements of the course, helping professors achieve the best possible learning outcomes.

                                    In addition to test creation, our platform offers innovative assessment strategies that leverage AI to provide real-time feedback and adaptive learning opportunities. By utilizing AI in your assessment process, you can ensure that each student receives personalized feedback that addresses their unique strengths and weaknesses. This approach not only enhances the learning experience but also helps educators maintain high standards of academic excellence.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Efficient Time Management with AI-Enhanced Tools
                                        </Typography>
                                    </Box>
                                    Time management is crucial for academia professors who juggle multiple responsibilities, from teaching and research to student interaction and administrative tasks. Our AI-powered platform streamlines these tasks by automating test creation, grading, and assessment management. With our intuitive interface, professors can quickly and easily create exams that align with their teaching objectives, saving valuable time that can be dedicated to more critical aspects of their work.

                                    Additionally, our platform's AI-enhanced tools allow professors to manage their time more effectively by providing automated grading and assessment tracking. This not only reduces the administrative burden but also ensures that assessments are consistent, fair, and aligned with academic standards. By optimizing time management through AI, professors can focus more on delivering high-quality education and less on the logistical challenges of assessment administration.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            In-Depth Assessment Insights and Student Performance Analytics
                                        </Typography>
                                    </Box>
                                    Our AI-powered platform provides professors with comprehensive insights into student performance, helping them understand how well students are grasping the material. With detailed reports and analytics, professors can identify trends in student performance, pinpoint areas where students may be struggling, and adjust their teaching strategies accordingly. This data-driven approach to education enables professors to make informed decisions that enhance the learning experience and improve academic outcomes.

                                    In addition to providing insights into student performance, our platform also offers tools for analyzing the effectiveness of your assessments. By evaluating the quality and impact of your exams and tests, you can ensure that they are achieving the desired educational objectives. Whether you're assessing student knowledge, skills, or competencies, our AI-driven analytics provide the data you need to refine your assessment strategies and deliver more effective and impactful education.
                                </Typography>
                            </Box>

                            <Typography
                                variant="h3"
                                component="h2"
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How Academia Professors Can Maximize the Use of Our AI-Powered Platform
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Academia professors can maximize the benefits of our AI-powered platform by leveraging its full range of features to enhance their teaching and assessment practices. From automating the creation of tests and exams to utilizing advanced analytics for student performance, our platform is designed to support educators in every aspect of their work. By integrating AI into your teaching methods, you can create a more dynamic and responsive learning environment that meets the needs of today's students.

                                One of the key advantages of our platform is its ability to automate grading and assessment management. This not only saves time but also ensures consistency and fairness in the evaluation process. By automating these tasks, professors can focus on more important aspects of their work, such as providing personalized feedback to students and refining their teaching strategies based on data-driven insights.

                                Additionally, our platform offers a range of tools for online and remote learning, making it easier for professors to reach students wherever they are. Whether you're teaching in a traditional classroom setting or an online environment, our AI-powered tools provide the flexibility and scalability you need to deliver high-quality education. By maximizing the use of our platform, professors can ensure that their students receive the best possible education, no matter the circumstances.

                                Our platform is also equipped with advanced AI-driven generators that help professors create customized assessments that are tailored to their specific academic needs. These generators allow professors to quickly and easily create tests and exams that are aligned with their teaching objectives, ensuring that every assessment is relevant and effective. By utilizing these generators, professors can create a more engaging and impactful learning experience for their students.

                                In conclusion, our AI-powered platform offers academia professors a powerful suite of tools to enhance their teaching, streamline their assessment processes, and gain valuable insights into student performance. By leveraging the full capabilities of our platform, professors can maximize their impact on student learning and achieve their academic goals. Whether you're focused on test creation, grading, or online education, our AI-driven platform provides the tools you need to succeed in today's competitive academic environment.
                            </Typography>
                            <Button variant="contained" color="primary" size="large">
                                Start Your Journey Today
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AcademiaProfessorsUseCase;
