import React from 'react';
import { Stack, Divider } from '@mui/material';
import './assets/css/font-awesome.css';
import './assets/css/templatemo-softy-pinko.css';
// Components
import LandingHeader from './LandingHeader';
import WelcomeArea from './WelcomeArea';
import FeaturesSmall from './FeaturesSmall';
import IconRow from './IconRow';
import CounterParallax from './CounterParallax';
import PricingPlans from './PricingPlans';
import Footer from '../../components/Footer';
import FAQ from './FAQ';
import EducationCards from './EducationCards';
import { colors } from '../../utils/customTheme';
import { isMobile } from '../../utils';
import LandingVideo from './LandingVideo';
import WorkflowComponent from './WorkflowComponent';
import QuizCreationComponent from './QuizCreationComponent';
import { Helmet } from 'react-helmet-async';


const LandingPage = () => {
    return (
        <div style={{ backgroundColor: colors.onboarding }} >
            <Helmet>
                <title>StudyWise AI | Create and Auto Grade your assignments, homework, exams and tests</title>
                <meta name="description" content="Create and auto-grade your assignments, homework, exams, and tests with StudyWise's AI-powered platform. Streamline your workflow and improve accuracy with automatic grading and detailed insights." />
            </Helmet>
            <LandingHeader />
            <Stack spacing={isMobile ? 0 : 1}>
                <WelcomeArea />
                <IconRow />
                <LandingVideo />
                <FeaturesSmall />
                <QuizCreationComponent />
                {!isMobile && <WorkflowComponent />}
                <EducationCards />
                <CounterParallax />
                <FAQ></FAQ>
                <Footer />
            </Stack>
        </div>
    );
};

export default LandingPage;
