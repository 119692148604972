import { Paper, Typography } from '@mui/material';
import React from 'react';
import { renderTextWithBreakLines } from '../utils';

function Unseen({ text, link = null }) {
    return (text || link) ? (
        <Paper style={{ padding: '20px 30px', marginBottom: '20px', borderRadius: '2px' }} >
            <Typography variant="h6">
                {renderTextWithBreakLines(text)}
                {link &&
                    <a href={link} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}> {link} </a>
                }
            </Typography>
        </Paper>
    ) : null
}

export default Unseen;
