import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Star } from '@mui/icons-material';
import StudentsUseCaseImg from '../../../assets/features/students.jpg'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const StudentsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Student Learning with Personalized AI-Driven Assessments">
                                    Optimize Student Learning with Personalized AI-Driven Assessments: Transforming Education Through Tailored Evaluation
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover how our cutting-edge AI-powered platform is set to revolutionize the educational landscape by delivering highly tailored assessments that cater specifically to each student's unique learning style and academic needs. Our advanced system harnesses the power of artificial intelligence to provide personalized feedback that helps students identify their strengths and areas for improvement. This personalized approach ensures that every student receives the support they need to excel academically. AI is a vital element in the market, and we prioritize its integration to maintain our competitive edge.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our platform features comprehensive performance tracking capabilities, allowing educators to monitor student progress over time. By providing detailed insights into individual performance, our tools enable educators to tailor their teaching strategies and interventions to support each student's academic growth effectively. Leveraging the latest advancements in AI technology, our platform aims to enhance academic success through customized, actionable insights that drive meaningful educational outcomes.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                From personalized assessments that adapt to various learning styles to detailed progress reports that highlight key areas for improvement, our platform is designed to meet the diverse needs of students and educators alike. By integrating free AI-driven tools into your educational practices, you can optimize the learning experience, reduce student stress, and achieve better overall academic performance. Our solutions also include paper formats for assessments.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Opt for Our AI-Enhanced Assessment Platform for Student Success?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Highly Personalized Assessments for Individual Learning Needs</Typography>
                                    </Box>
                                    Our platform employs state-of-the-art AI technology to craft assessments that are highly personalized and tailored to meet each student's unique learning preferences and requirements. This customization ensures that assessments are more engaging and effective, as they align closely with the individual needs of each student. By providing feedback that is specific to the student's performance, our platform helps students better understand complex concepts and make significant progress toward achieving their academic goals. Moreover, the test features available enhance engagement and effectiveness, making it a vital aspect of our offerings.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Optimized Time Management for Better Focus and Performance</Typography>
                                    </Box>
                                    Our platform is designed with effective time management in mind, streamlining the exam scheduling and management process. By simplifying these administrative tasks, students can focus more on their studies and less on logistical concerns, which leads to reduced stress and improved academic performance. The intuitive tools and automated features provided by our platform help educators manage assessments efficiently, allowing them to dedicate more time to teaching and less to administrative duties.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Star color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Tracking of Academic Progress and Performance</Typography>
                                    </Box>
                                    Gain valuable insights into student performance with our platform's robust analytics and reporting features. Detailed reports provide a comprehensive view of student progress over time, highlight trends, and identify areas that require improvement. These insights are essential for supporting academic growth and making data-driven decisions about instructional strategies. By analyzing performance data, educators can tailor their teaching methods to better address student needs and enhance overall learning outcomes.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Leverage Our AI Platform for Maximizing Student Success and Academic Achievement?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Utilize our advanced AI-driven platform to transform your approach to creating and managing assessments. Our tools allow you to design customized exams that align with individual learning needs, deliver actionable feedback, and track academic progress comprehensively. By incorporating our platform into your educational practices, you can enhance student learning outcomes, foster academic success, and create a more effective and engaging learning environment for all students.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={StudentsUseCaseImg} alt="AI-Powered Student Assessments" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default StudentsUseCase;
