import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { moeILAuth } from '../requests/moeAuth';
import { homepage, onboarding } from '../components/menu/MenuLinks';
import { HttpStatusCode } from 'axios';
import { useUser } from '../hooks/useUser';
import useHistory from '../hooks/useHistory';
import { tokensConfig } from './SignUp/tokensConfig';
function NotAuthorized() {
    return (
        <div>
            <p>Not Authorized</p>
        </div>
    );
}

export default function IdmMoeILRedirect() {
    const userContext = useUser();
    const historyContext = useHistory();
    const naviagte = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const [authorized, setAuthorized] = React.useState(true);

    useEffect(() => {
        if (!code || !state) {
            setAuthorized(false);
        } else {
            setAuthorized(true);
            const payload = preAuthPayload();
            moeILAuth(code, state, payload).then(res => {
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                    const { idm_data, ...studentData } = res.data; // Extract the idm_data and do not save it in the local storage
                    studentData.timestamp = new Date().getTime(); // Add timestamp to the student data
                    localStorage.setItem('studentData', JSON.stringify(studentData));
                    localStorage.removeItem('redirectUrl');
                    window.open(redirectUrl, '_self');
                    return;
                }
                const user = res?.data;
                userContext.login(user)
                historyContext.resetHistory()
                if (user?.idm_data?.isstudent == "Yes") {
                    naviagte('/student/history')
                    return
                }
                else if (res.status === HttpStatusCode.Ok) {
                    // user found
                    naviagte(homepage);
                } else if (res.status === HttpStatusCode.Created) {
                    // user created
                    naviagte(onboarding);
                } else
                    setAuthorized(false);
            }).catch(err => {
                console.error(err);
                setAuthorized(false);
            });
        }
    }, [code, state])

    if (!authorized) {
        return <NotAuthorized />
    }

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
}

/**
 * 
 * @returns {orgId?: string, userData:{roles: string[], exams: number}}
 */
function preAuthPayload() {
    const payload = {};

    const orgId = sessionStorage.getItem('orgId');
    if (orgId) {
        payload.orgId = orgId;
        sessionStorage.removeItem('orgId');
    }

    const tokenId = sessionStorage.getItem('tokenId');
    if (tokenId) {
        const { roles, exams } = tokensConfig.find(tokenObj => tokenObj.token === tokenId);
        payload.userData = { roles, exams };
        sessionStorage.removeItem('tokenId');
    }
    return payload;
}