import { useContext, Context } from 'react';
import { StudentHistoryContext } from '../contexts/StudentHistoryContext'; // Adjust the path accordingly

/**
 * @returns {Context}
 */
const useStudentHistory = () => {
    const studentHistoryContext = useContext(StudentHistoryContext);

    if (!studentHistoryContext) {
        throw new Error('useStudentHistory must be used within a HistoryProvider');
    }

    return studentHistoryContext;
};

export default useStudentHistory;