import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { BarChart, TrendingUp, Assessment } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const InsightsAnalytics = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <BarChart color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="AI-Enhanced Insights and Analytics for Test Assessment">
                                    Unlock the Full Potential of AI-Enhanced Insights and Analytics for Comprehensive Test Assessments and Educational Success
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover the transformative power of artificial intelligence (AI) in revolutionizing your test assessments with our advanced insights and analytics tools. Our AI-driven solutions are designed to deliver comprehensive analysis and actionable data that empower educators and administrators to make well-informed decisions, enhance the effectiveness of tests, and significantly boost overall academic performance. By integrating AI into your assessment processes, you unlock a new level of precision and efficiency in understanding and improving student learning outcomes.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our platform leverages cutting-edge AI technology to provide detailed insights into test results, enabling educators to streamline their assessment strategies. Through sophisticated data analysis, we offer valuable visualizations, trend analysis, and performance metrics that facilitate a deeper understanding of student capabilities and instructional impact. With our AI-enhanced analytics, you can identify key trends, optimize testing methods, and drive continuous improvements in educational practices.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                The integration of AI into test insights not only simplifies data interpretation but also enhances decision-making processes. Our advanced tools ensure that educators and administrators can effectively monitor and assess educational progress, track performance metrics over time, and make data-driven adjustments to teaching methods and assessment strategies. This holistic approach to test analysis supports a more informed and responsive educational environment, ensuring that you are not just keeping pace with the evolving educational landscape but leading the charge.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Integrate AI into Your Insights and Analytics for Test Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <BarChart color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Visualize Complex Test Data with Advanced AI Tools</Typography>
                                    </Box>
                                    Our AI-powered tools excel at transforming complex and voluminous test data into intuitive, easy-to-understand visualizations. By utilizing interactive charts, graphs, and dashboards, educators and administrators can quickly interpret assessment results, uncover trends, and pinpoint areas that require attention. This capability simplifies data analysis and enhances the ability to make informed decisions based on comprehensive insights. 
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <TrendingUp color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Track and Analyze Performance Trends Over Time with AI</Typography>
                                    </Box>
                                    Leveraging AI-driven analytics allows for detailed tracking and analysis of performance trends over extended periods. This functionality helps to identify long-term patterns in student achievement, evaluate the effectiveness of teaching methodologies, and make strategic adjustments to improve educational outcomes. By continuously monitoring these trends, educators can refine their approaches to ensure sustained academic progress, ensuring that every student receives the attention they need to excel.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Performance Assessment with AI Precision</Typography>
                                    </Box>
                                    Our platform offers detailed performance assessments by utilizing AI to evaluate a wide range of metrics. This allows for precise analysis of test data, helping educators develop refined testing strategies and improve the effectiveness of assessments. By focusing on detailed performance evaluation, our tools support a more nuanced understanding of student progress and instructional effectiveness, ultimately driving better educational outcomes.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Maximize AI for Effective Test Insights and Analytics in Your Educational Institution?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Harness the power of AI to transform the way you manage and interpret test insights and analytics. Our state-of-the-art tools provide a comprehensive suite of features designed to enhance your ability to analyze test data, track performance trends, and gain actionable insights. By leveraging advanced AI technology, you can optimize your assessment strategies, improve educational outcomes, and create a more data-driven approach to teaching and learning. Explore how our AI-enhanced insights can elevate your educational practices and drive success across your institution. Don't miss out on the opportunity to tap into the vast potential of free resources available to enhance your testing strategies, ensuring that every student benefits from a personalized learning experience.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default InsightsAnalytics;
