import React from 'react'; 
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Feedback, Assessment as AssessmentIcon, Timeline } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';
import FormativeAssessmentImg from '../../../assets/features/professor.jpg'; // Placeholder image

const FormativeAssessmentUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <AssessmentIcon color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Advanced Formative Assessment Tools">
                                    Advanced Formative Assessment Tools: Revolutionizing Student Learning, Test Preparation, and Academic Achievement with Cutting-Edge AI Technology
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our advanced formative assessment tools are meticulously designed to revolutionize the way students learn and prepare for tests. By leveraging state-of-the-art AI technology, which is essential for market growth, our platform provides educators and students with immediate, actionable feedback that drives continuous improvement and enhances academic performance. We understand that AI is not just a trend; it’s a crucial element that will shape the future of education, ensuring that every student receives the support they need to succeed.
                                <br /><br />
                                With AI at the forefront, we focus on creating an adaptive learning environment where assessments are not merely evaluations but powerful learning experiences. Our technology integrates seamlessly into existing educational frameworks, making it easier for educators to utilize these tools effectively and for students to engage with content meaningfully. This commitment to integrating AI into the learning process enables a more personalized approach, enhancing student outcomes in a way that traditional methods cannot match.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                The power of AI in formative assessments lies in its ability to deliver real-time insights and analytics that help educators adapt their teaching strategies to meet individual student needs. Our platform supports a wide range of assessment types, including quizzes, practice tests, and diagnostic assessments, making it easier than ever to evaluate student performance and foster a culture of continuous learning and improvement. By focusing on creating a dynamic learning environment, we ensure that students remain engaged and motivated in their educational journey.
                                <br /><br />
                                By continually analyzing performance data, educators can identify trends that inform instructional practices. This data-driven approach enables teachers to design assessments that target specific learning objectives, ultimately leading to improved student performance. Our emphasis on a holistic view of student progress ensures that all aspects of learning are taken into account, leading to comprehensive growth and mastery of concepts.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With real-time feedback and detailed analytics at your fingertips, our platform empowers educators to create personalized learning experiences tailored to each student's strengths and areas for growth. Whether you're preparing for standardized exams or creating customized assessments, our tools provide the flexibility and depth needed to enhance both learning outcomes and test preparation. Our commitment to excellence means we continuously innovate, making our formative assessment tools invaluable for educators and students alike.
                                <br /><br />
                                Furthermore, we offer comprehensive resources and training to help educators maximize the potential of our assessment tools. By investing in professional development, educators can fully leverage the capabilities of AI-enhanced assessments, leading to improved teaching strategies and better student engagement. Our goal is to create a collaborative learning environment that extends beyond assessments, fostering a culture of continuous improvement in education.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Key Features and Benefits of Our Comprehensive Formative Assessment Platform
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Feedback for Assessments and Tests</Typography>
                                    </Box>
                                    Our platform integrates advanced AI algorithms to deliver instant, personalized feedback on a variety of assessments and tests. This cutting-edge technology allows students to gain a deep understanding of their performance, pinpoint specific areas for improvement, and refine their skills effectively. By providing real-time, actionable feedback, we enable students to continuously enhance their readiness for future academic challenges and improve their overall performance. The power of AI not only supports individual learning paths but also helps teachers to assess student understanding efficiently.
                                    <br /><br />
                                    Additionally, our feedback system is designed to facilitate discussions between students and educators, ensuring that misconceptions are addressed promptly and learning gaps are filled. This collaborative approach fosters a growth mindset, encouraging students to take ownership of their learning journey and strive for excellence.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Timeline color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Tracking of Test Performance and Learning Progress</Typography>
                                    </Box>
                                    Track and analyze student performance comprehensively with our robust analytics tools. Our platform provides detailed insights into learning trends, performance metrics, and test results, enabling educators to make informed decisions that enhance test preparation and academic achievement. By monitoring performance over time, you can identify patterns, adjust teaching strategies, and provide targeted support to help students succeed. This feature is particularly useful for identifying strengths and weaknesses across different subjects, allowing for a more tailored educational experience.
                                    <br /><br />
                                    The tracking system also helps educators create reports that can be shared with stakeholders, ensuring transparency and accountability in the assessment process. These reports highlight not only individual progress but also classroom-wide trends, enabling collaborative planning and intervention strategies.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Create an Effective Learning and Assessment Environment</Typography>
                                    </Box>
                                    Our platform simplifies the process of designing, managing, and evaluating assessments, providing you with the tools needed to create an effective learning environment. By offering constructive feedback and maintaining high academic standards, we help foster a supportive atmosphere that encourages student growth and success. Our tools also support the creation of customized assessments tailored to specific learning objectives, further enhancing the educational experience. We believe that formative assessments should not only evaluate learning but also stimulate it, driving deeper understanding and retention.
                                    <br /><br />
                                    Moreover, we promote an inclusive learning environment by providing diverse assessment formats that cater to different learning styles. This ensures that every student has the opportunity to demonstrate their knowledge and skills in a way that resonates with them, ultimately leading to better learning outcomes.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                Implementing Formative Assessments: A Comprehensive Guide for Educators to Maximize Learning Outcomes
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Implementing formative assessments with our platform is straightforward and highly effective. Our intuitive tools enable you to create, manage, and evaluate a wide range of assessments effortlessly. By leveraging advanced AI technology for personalized feedback, you can enhance learning outcomes, prepare students more effectively for tests, and drive academic success. Our platform provides a comprehensive solution for formative assessment, helping educators to meet the diverse needs of their students and achieve educational excellence. We are committed to continuous improvement and are always seeking new ways to enhance our offerings to better serve the educational community.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Image Column */}
                        {/* <Grid item xs={12}>
                            <img 
                                src={FormativeAssessmentImg} 
                                alt="Educator using formative assessment tools" 
                                style={{ width: '100%', borderRadius: 8, border: '1px solid black' }}
                                aria-label="Formative Assessment Image"
                            />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FormativeAssessmentUseCase;
