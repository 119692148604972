import React, { useState } from 'react';
import { Typography, Grid, Alert } from '@mui/material';
import FileUploadHandler from '../../components/FileUploadHandlerForExam';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useLanguage } from '../../contexts/languageContext';
import { uploadPastExams } from '../../requests/personalization';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../hooks/useLoader';
import Header from '../../components/Header';

const UploadPastExams = () => {
    const { openSnackBar } = useSnackBar();
    const { openLoader, closeLoader } = useLoader();
    const { languageData } = useLanguage();
    const navigate = useNavigate();

    const handleInput = (text) => {
        if (!text) {
            openSnackBar(SnackbarTypes.NO_TEXT.field);
            return;
        }
        openLoader();
        return uploadPastExams([text]).then(() => {
            navigate('/personal?r=1')
        }).catch(error => {
            console.error('Error uploading past exam:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }).finally(() => closeLoader());
    };

    return (
        <>
            <Header />
            <Grid container alignItems="center" spacing={2} marginTop={5} px={4}>
                <Grid item xs={12}>
                    <Typography variant="h5" align='center' gutterBottom sx={{ fontWeight: 'bold' }}>
                        {languageData?.onboarding?.upload_past_test_step?.title || "Upload Past Tests"}
                    </Typography>
                    <Typography variant="subtitle1" align='center' gutterBottom>
                        {languageData?.onboarding?.upload_past_test_step?.subtitle || "Uploading past tests helps us understand your teaching style and the type of questions you prefer, enabling us to provide more personalized and effective exam suggestions."}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="success">
                        {languageData?.onboarding?.upload_past_test_step?.data_privacy_alert || "We will not train or use your data in any way. Your privacy is important to us."}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadHandler onInput={handleInput} maxFiles={2} maxCharsLimit={30000} />
                </Grid>

            </Grid>
        </>
    );
};

export default UploadPastExams;
