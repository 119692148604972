import React from 'react';
import ExamCard from './ExamCard';
    import { Grid } from '@mui/material';

const CardList = ({ exams, deleteExam }) => (
    <Grid container spacing={3}>
        {
            exams.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <ExamCard
                        title={card.title}
                        date={card.date ?? ''}
                        id={card._id ?? ""}
                        totalQuestions={card?.questions.length}
                        difficulty={card?.parameters?.difficulty}
                        source={card?.parameters?.source}
                        language={card?.parameters?.language}
                        deleteExam={deleteExam}
                        responsesCount={card?.responses_count}
                        startsAt={card?.settings?.start_time ?? ""}
                        endsAt={card?.settings?.end_time ?? ""}
                    />
                </Grid>
            ))
        }
    </Grid>
);

export default CardList;
