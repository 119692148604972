import React, { useEffect, useState } from 'react';
import { Typography, TextField } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useDebounce } from '../../hooks/useDebounce'
import CustomLatex from '../../components/CustomLatex';

const OpenQuestion = ({ questionId, title, onAnswer, savedAnswer }) => {
  const [answer, setAnswer] = useState(savedAnswer);
  const { languageData, isRTL } = useLanguage()
  const debouncedOnAnswer = useDebounce(onAnswer)

  useEffect(() => {
    if (savedAnswer)
      setAnswer(savedAnswer)
  }, [savedAnswer])

  const handleAnswerChange = (e) => {
    const newAnswer = e.target.value
    setAnswer(newAnswer);
    debouncedOnAnswer(questionId, newAnswer)
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        <CustomLatex>{title}</CustomLatex>
      </Typography>
      <TextField
        label={languageData?.quiz?.answer_label ?? ''}
        value={answer}
        onChange={handleAnswerChange}
        fullWidth
        multiline
        minRows={3}
        maxRows={15}
        variant="outlined"
        margin="normal"
        error={!answer}
      />
    </div>
  );
};

export default OpenQuestion;
