import axios from 'axios';
import { getJWTHeaders } from '../utils/JWT_functions';

axios.defaults.withCredentials = true

export const openPages = ['login', 'signup', 'blog']

// DEV MODE
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:5000/api/v1',
    // baseURL: 'http://localhost:5000/api/v1',
});

api.interceptors.request.use(
    (config) => {
        const tokenHeader = getJWTHeaders();
        config.headers = {
            ...config.headers,
            ...tokenHeader,
        };
        // logTheRequest(config)
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    res => {
        return res
    },
    error => {
        if (error.response &&
            !openPages.some(path => window.location.pathname.includes(path)) &&
            error.response.data &&
            ['expired', 'invalid', 'revoked'].some(reason => error.response.data?.msg?.toLowerCase()?.includes(reason))
        ) {
            // If page is not login or signup and Token expired or invalid or revoked, then:
            // Perform redirect
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
)

function logTheRequest(config) {
    // Log the request details
    console.log('Request details:', {
        method: config.method,
        url: config.url,
        headers: config.headers,
        data: config.data,
    });
}

export default api;
