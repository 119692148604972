import React from 'react';
import StudentExamCard from './StudentExamCard';
import { Grid } from '@mui/material';

const StudentExamsCardList = ({ exams }) => (
    <Grid container spacing={3}>
        {
            exams.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <StudentExamCard
                        title={card.exam_details?.title}
                        date={card.date ?? ''}
                        totalQuestions={card.answers ? card.answers.length : 0}
                        language={card?.exam_details?.parameters?.language}
                        examId={card.exam_id ?? ""}
                        responseId={card._id}
                        totalGrade={card.exam_details?.grade}
                        grade={card.grade}
                    />
                </Grid>
            ))
        }
    </Grid>
);

export default StudentExamsCardList;
