import { HttpStatusCode } from 'axios';
import { convertISOToDDMMYYYY } from '../utils/dateHelpers';
import { defaultParams } from '../utils/examOptions';
import api from './api';
import { processResponsePromise } from './utils';

async function getPaginatedHistory(userId, queryParams) {
  const { page = 1, size = 50, remaining = false } = queryParams || {};
  return api.get(`/users/${userId}/exams/paginated?page=${page}&size=${size}&remaining=${remaining}`).then(response => {
    if (response.status === HttpStatusCode.Ok) {
      return response.data?.history;
    } else {
      throw Error(`Could not get history | status code: ${response.status}`)
    }
  }).catch(error => {
    console.error('Error fetching history:', error);
    throw error;
  })
}

async function getRemainingHistory(userId) {
  return getPaginatedHistory(userId, { page: 2, remaining: true })
}

async function getAllHistory(userId) {
  return api.get(`/users/${userId}/exams`).then(response => {
    if (response.status === HttpStatusCode.Ok) {
      return response.data;
    } else {
      throw Error(`Could not get history | status code: ${response.status}`)
    }
  }).catch(error => {
    console.error('Error fetching history:', error);
    throw error;
  })
}

/**
 * @param {object} examParams 
 * @param {string} examParams.text 
 * @param {string} examParams.field 
 * @param {Array} examParams.questions 
 * @param {string} examParams.difficulty 
 * @param {string} examParams.language 
 * @param {string} examParams.focus 
 * @param {Array} examParams.skills 
 * @param {string} examParams.exampleQuestion 
 * @param {boolean} examParams.isFile 
 * @param {object} examParams.payload 
 * @param {boolean} examParams.isClosedContent 
 * @param {boolean} examParams.useIndicator 
 */
const requestGenerateExam = async (examParams) => {
  try {
    const response = await api.post('/gen/exam', { ...examParams });
    return response;
  } catch (err) {
    if (err.status === HttpStatusCode.TooManyRequests) {
      return Promise.reject({ error: 'Too many requests', status: HttpStatusCode.TooManyRequests });
    }
    else if (err.response) {
      // The request was made and the server responded with a status code
      // other than 2xx.
      console.error('Server responded with error:', err.response.data);
      return Promise.reject(err.response.data);
    } else if (err.request) {
      // The request was made but no response was received.
      console.error('No response from the server');
      return Promise.reject({ error: 'No response from the server', status: 500 });
    } else {
      // Something happened in setting up the request that triggered an Error.
      console.error('An unexpected error occurred:', err.message);
      return Promise.reject({ error: 'An unexpected error occurred', status: 500 });
    }
  }
};

const deleteExamFromHistory = (examId) => {
  try {
    return api.delete(`/exams/${examId}`)
  } catch (err) {
    console.error(err)
    return Promise.reject('Request to delete exam failed!')
  }
}

/**
 * TODO: consider merge this with get-user-for-quiz
 * Requests exam JSON data from the server for the currentUser
 * @param {string} examId - The ID of the exam to request.
 * @returns {Promise<{title: string, filename: string, exam: Array, properties: Object, parameters: Object, _id: string, date: string}>} The exam JSON data.
 */
const requestExamJson = async (examId) => {
  try {
    const response = await api.get(`/exams/${examId}`);
    if (response.status == HttpStatusCode.Ok) {
      // Change date format:
      if (response.data.date)
        response.data.date = convertISOToDDMMYYYY(response.data.date)
      return response.data;
    } else {
      throw Error(`Could not get exam | status code: ${response.status}`)
    }
  } catch (error) {
    console.error('Error fetching exam:', error);
    throw error;
  }
};

function saveExam(examId, examJson) {
  // Updates only title & questions
  return api.put(`/exams/${examId}`, { exam: examJson })
}

const requestExamStatus = async (taskId) => {
  try {
    return await api.get(`/gen/${taskId}/status`)
  } catch (error) {
    console.error('Error fetching exam status:', error);
    throw error;
  }
}

function duplicateExam(eid, uid) {
  return processResponsePromise(api.post(`/exams/${eid}/duplicate/${uid}`))
}

/**
 * Function to request grade for open-ended questions
 * @param {Array<{ _id: string, answer: string, explanation: string }>} answers Array of objects containing question ID, answer, and explanation
 * @returns {Promise<Array<{ _id: string, grade: number }>>} A promise that resolves with an array of objects containing question ID and grade
 */
const requestEvaluation = async (answers, language, difficulty, token) => {
  try {
    const response = await api.post('/gen/exam/evaluate', { answers, language, difficulty }, { headers: { "X-Token": token } });

    return response.data;
  } catch (error) {
    console.error("Error grading open-ended questions:", error);
    throw error; // Rethrow error for handling in the caller function
  }
};

async function requestUploadExam(inputText, difficulty, language) {
  try {
    const response = await api.post('/gen/own', { inputText, difficulty, language });
    return response.status === 200 ? response.data.examId : null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export {
  requestUploadExam,
  requestGenerateExam,
  deleteExamFromHistory,
  requestExamJson,
  saveExam,
  requestEvaluation,
  duplicateExam,
  requestExamStatus,
  getAllHistory,
  getPaginatedHistory,
  getRemainingHistory
}
