import React, { useEffect, useState } from 'react';
// MUI
import { Grid, Paper, Typography, Box } from '@mui/material';
// Utils
import { getFeaturesConfig } from '../utils/configs';
import { isMobile, getIcon, getFontSize, getIconColor } from '../utils';
// Hooks
import { useLanguage } from '../contexts/languageContext';
import { useLobby } from '../contexts/LobbyContext';

const FeaturesList = ({
    user,
    language,
    isRTL,
    onInput,
    onFeatureClick,
    inputText,
    selectedFeature
}) => {
    const [selectedFeatureObj, setSelectedFeatureObj] = useState(null);
    const { languageData } = useLanguage()
    const { lobbyStatus } = useLobby();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const inputQuery = searchParams.get('input');

        if (inputQuery) {
            // send the input to the parent component which re-render this component with the right selectedFeture prop
            onFeatureClick(inputQuery)
        }
    }, []);

    useEffect(() => {
        if (!selectedFeature)
            setSelectedFeatureObj(null)
        else {
            const foundFeature = getFeaturesConfig(languageData).find(feature => feature.field === selectedFeature);
            if (foundFeature) {
                setSelectedFeatureObj(foundFeature);
            }
        }
    }, [selectedFeature, languageData])

    const handleFeatureClick = (feature) => {
        onFeatureClick(feature.field)
    };

    const handleTextInputChange = (value, field) => {
        onInput(value, field)
    };
    const handleFocusTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1.07)';
        e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
    }
    const handleBlurTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = 'none';
    }
    return (
        <div>
            {selectedFeatureObj ? (
                <div>
                    <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Typography variant="h6" style={{ marginLeft: '10px', direction: isRTL ? 'rtl' : 'ltr' }}>
                                {` ${(languageData?.create_from ?? '') + selectedFeatureObj.label}`}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: 'gray', marginLeft: '10px', direction: isRTL ? 'rtl' : 'ltr', fontSize: isMobile ? '' : getFontSize() }}>
                                {selectedFeatureObj.shortExplain}
                            </Typography>
                        </div>
                        {selectedFeatureObj.component(user, inputText, handleTextInputChange)}
                    </Paper>
                </div>
            ) : (
                <>
                    <Typography variant={isMobile ? "h6" : "h4"} sx={{ fontWeight: 500 }} gutterBottom p={1.5}>
                        {languageData.select_input_title}
                    </Typography>

                    <Grid sx={{ minWidth: '50vw' }} container spacing={2} mb={4}>
                        {getFeaturesConfig(languageData).map((feature, index) => {
                            const displayPulse = lobbyStatus?.first_exam === false && index === 0;
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                    <Paper
                                        onClick={() => handleFeatureClick(feature)}
                                        sx={{
                                            m: isMobile ? 1 : 0.5,
                                            p: 3,
                                            cursor: 'pointer',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderRadius: 2,
                                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                            },
                                            position: 'relative',
                                            animation: displayPulse ? 'pulse 2s infinite ease-in-out' : 'none', // Apply pulsing animation for the first feature
                                        }}
                                        tabIndex={0}
                                        onMouseEnter={handleFocusTransition}
                                        onFocus={handleFocusTransition}
                                        onMouseLeave={handleBlurTransition}
                                        onBlur={handleBlurTransition}
                                        onKeyDown={(e) => e.key === 'Enter' ? handleFeatureClick(feature) : null}
                                    >
                                        {feature.chip && (
                                            <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                                {feature.chip}
                                            </Box>
                                        )}
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '50%',
                                                    bgcolor: getIconColor(feature.field),
                                                    width: 60,
                                                    height: 60,
                                                    mb: 2,
                                                }}
                                            >
                                                {getIcon(feature.field)}
                                            </Box>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                align="center"
                                                sx={{ fontWeight: 700, fontSize: isMobile ? '18px' : getFontSize().title }}
                                            >
                                                {feature.label}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            align="center"
                                            sx={{
                                                mt: 1,
                                                direction: language !== 'en' ? 'rtl' : 'ltr',
                                                fontSize: isMobile ? '' : getFontSize().subtitle,
                                            }}
                                        >
                                            {feature.shortExplain}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default FeaturesList;
