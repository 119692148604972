import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CodeBlock = ({ code }) => {
    const [copied, setCopied] = React.useState(false);

    // Function to copy the code to the clipboard
    const handleCopy = () => {
        setCopied(true);
        navigator.clipboard.writeText(code).then(() => {
            setTimeout(() => setCopied(false), 1500); // Reset the tooltip after 1.5 seconds
        }).catch(err => { });
    };
    IconButton
    return (
        <Box
            sx={{
                position: 'relative',
                padding: '16px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                backgroundColor: '#f5f5f5',
                fontFamily: 'monospace',
                overflowX: 'auto',
                whiteSpace: 'pre',
                display: 'block',
            }}
        >
            {/* Copy button with Tooltip */}
            <Tooltip open={copied} title="Copied" arrow >
                <IconButton
                    size="small"
                    onClick={handleCopy}
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        '&:hover': {
                            backgroundColor: '#ffffffdd',
                            border: '1px solid #ddd',
                        },
                    }}
                >
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            {/* Display the code */}
            <pre style={{ margin: 0 }}>
                <code>
                    {code}
                </code>
            </pre>
        </Box>
    );
};

export default CodeBlock;