import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useManagerDashboard } from '../../contexts/ManagerDashboardContext';

const Teachers = ({ orgId }) => {
    const { fetchOrgUsers, managerDashboardData } = useManagerDashboard();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (orgId && !managerDashboardData?.users) {
            setLoading(true);
            fetchOrgUsers(orgId).catch((e) => {
                console.error('Error fetching org users:', e);
                setError('Failed to fetch teachers');
            }).finally(() => setLoading(false));
        }
    }, [orgId]);

    // Memoizing hasInstitute to prevent unnecessary re-computations
    const hasInstitute = useMemo(() => {
        return managerDashboardData?.users?.some(user => user.org_details?.institute_name);
    }, [managerDashboardData?.users]);

    // Memoize columns to ensure stability
    const columns = useMemo(() => [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'total_exams', headerName: 'Total Exams Conducted', flex: 1 },
        { field: 'total_responses', headerName: 'Total Responses', flex: 1 },
        { field: 'login_timestamps', headerName: 'Total Logins', flex: 1 },
        ...(hasInstitute ? [{ field: 'institute', headerName: 'Institute Name', flex: 1 }] : []),
    ], [hasInstitute]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    if (managerDashboardData?.users?.length === 0) {
        return <Typography variant="body1">No users found for this organization.</Typography>;
    }

    // Prepare the rows
    const rows = managerDashboardData?.users.map((teacher) => ({
        id: teacher._id,
        name: teacher.fullname,
        email: teacher.email,
        total_exams: teacher.total_exams,
        total_responses: teacher?.total_responses,
        login_timestamps: teacher.login_timestamps,
        institute: teacher.org_details?.institute_name,
    }));

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid columns={columns} rows={rows} />
        </div>
    );
};

export default Teachers;
