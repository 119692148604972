import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assessment, Analytics, DataUsage } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const AIReportsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assessment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    aria-label="Transform Your Data into Actionable Insights with AI Reports"
                                >
                                    Transform Your Data into Actionable Insights with AI Reports
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                In today’s data-driven educational landscape, harnessing the power of AI for report generation is crucial for educators and administrators. Our platform provides advanced AI-driven reports that streamline data analysis, offering actionable insights that empower educators to enhance teaching strategies and improve student outcomes. By utilizing AI for report generation, institutions can save time, reduce manual workload, and focus on what truly matters: fostering student success and academic excellence.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Our AI reports are designed to help educators and administrators identify trends, assess performance metrics, and make informed decisions. With the capability to analyze vast amounts of data quickly, our platform offers detailed insights into student engagement, assessment outcomes, and instructional effectiveness. This data-driven approach allows for personalized learning experiences, enabling educators to tailor their teaching methods to meet the diverse needs of their students effectively.
                            </Typography>
                        </Grid>

                        {/* Key Features Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    Key Features of AI-Driven Reports
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Data Analysis</Typography>
                                    </Box>
                                    Our AI reporting tools provide in-depth analysis of various educational metrics, including student performance, engagement rates, and learning outcomes. By consolidating data from multiple sources, educators can gain a holistic view of their teaching effectiveness and identify areas for improvement. This comprehensive analysis supports strategic planning and informed decision-making, allowing institutions to implement effective interventions and improve overall student performance.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Analytics color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Real-Time Insights and Reporting</Typography>
                                    </Box>
                                    Our platform offers real-time insights into student engagement and assessment results, enabling educators to monitor progress and adapt their teaching strategies promptly. With the ability to generate reports on demand, educators can quickly access the information they need to make data-driven decisions. This flexibility allows for timely interventions, ensuring that no student is left behind and all learners receive the support they need to succeed.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <DataUsage color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Customized Reporting Options</Typography>
                                    </Box>
                                    Tailor reports to meet the specific needs of your institution with our customizable reporting options. Educators can select from various data points and metrics to focus on what matters most to their teaching and learning objectives. This level of customization enhances the relevance and utility of the reports generated, ensuring that educators can derive actionable insights tailored to their unique contexts and goals.
                                </Typography>
                            </Box>
                        </Grid>

                        {/* How AI Reports Enhance Outcomes Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    How AI Reports Enhance Educational Outcomes
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}>
                                    By integrating AI-powered reports into your educational practice, you unlock the potential for data-informed decision-making that enhances teaching and learning outcomes. Our reports enable educators to identify trends in student performance over time, facilitating proactive interventions to support at-risk students. The insights generated can guide curriculum development, resource allocation, and instructional design, leading to improved academic results and greater student satisfaction.
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Call to Action Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    Start Leveraging AI-Driven Reports Today
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}>
                                    Embrace the power of AI in your educational reporting process by exploring our innovative AI reports. Our user-friendly interface and intuitive design make it easy for educators to generate, customize, and analyze reports that drive actionable insights. Join a growing community of educators who are transforming their data into meaningful outcomes and making a real difference in student learning experiences. 
                                </Typography>
                                <Box>
                                    <Button variant="contained" color="primary" href="/signup">
                                        Sign Up for Free
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AIReportsUseCase;
