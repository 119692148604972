import React from 'react';
import { Drawer, Box } from '@mui/material';
import VerticalLinearStepper from './VerticalLineStpper';

const drawerWidth = 350;

const StagesMenu = ({ stage }) => {
    return (
        <Drawer anchor="left" open={true} variant="persistent" sx={{width: drawerWidth}}>
            <Box sx={{ padding: '70px 50px 50px 20px' }}>
                <VerticalLinearStepper activeStep={stage} />
            </Box>
        </Drawer>
    );
};

export default StagesMenu;
