import React, { useEffect, useState, useMemo } from 'react';
import {
  Stack, Avatar, Button, CssBaseline, TextField, Link,
  Grid, Box, Typography, Container, Divider, CircularProgress
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ContinueWithGoogleButton from '../components/GoogleAuth';
import ContinueWithMicrosoftButton from '../components/MicrosoftAuth';
import PrivacyAndTermsOfService from '../components/PrivacyAndTerms0fService';
import HebrewPrivacyAndTermsOfService from '../components/HebrewPrivacyAndTermsOfService';
import { IdmMoeILButton } from '../components/IdmMoeILButton';
import Header from '../components/Header';
import Logo from '../assets/logo-horizonal.png';

import useSnackBar from '../hooks/useSnackBar';
import { useUser } from '../hooks/useUser';
import useHistory from '../hooks/useHistory';
import { useLanguage } from '../contexts/languageContext';

import api from '../requests/api';
import { getTempToken } from '../requests/token';
import { handleRedirectUrlAfterSignIn, handleSelectedtierIfExist } from '../utils';
import { validateEmail, validatePassword } from '../utils/inputValidations';
import { tokensConfig } from './SignUp/tokensConfig';
import ROLES from '../utils/roles';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { homepage } from '../components/menu/MenuLinks';
import { colors } from '../utils/customTheme';
import { HttpStatusCode } from 'axios';

export default function SignUp() {
  // Hooks:
  const navigate = useNavigate();
  const userContext = useUser()
  const historyContext = useHistory()
  const location = useLocation();
  const { openSnackBar } = useSnackBar();
  const { languageData, fetchLanguageWithToken, isRTL } = useLanguage();

  useEffect(() => {
    userContext.isLoggedIn().then(res => {
      if (res) {
        handleSelectedtierIfExist()
        handleRedirectUrlAfterSignIn(navigate, homepage)
      } else {
        getTempToken(null).then(token => {
          if (token)
            fetchLanguageWithToken(token)
        })
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isFormValid, setisFormValid] = useState({ firstName: false, lastName: false, email: false, password: false });
  const [showForm, setShowForm] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const tokenData = useMemo(() => {
    if (queryParams.get('teacher')) // check if the token is an IL teacher token
      return tokensConfig.find(tokenObj => tokenObj.name === "IL_TEACHER" && tokenObj.token === queryParams.get('teacher'));
    else if (queryParams.get('token'))
      return tokensConfig.find(tokenObj => tokenObj.key === 'token' && tokenObj.token === queryParams.get('token'));
    return null;
  }, [queryParams]);

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    let newIsFormValid = { ...isFormValid };

    switch (name) {
      case 'firstName':
        newIsFormValid[name] = value.length > 0;
        break;
      case 'lastName':
        newIsFormValid[name] = value.length > 0;
        break;
      case 'email':
        newIsFormValid[name] = validateEmail(value);
        break;
      case 'password':
        newIsFormValid[name] = value.length >= 6;
        break;
      default:
        break;
    }

    setisFormValid(newIsFormValid);
  };

  const checkValidForm = (obj) => {
    const { firstName, lastName, email, password } = obj
    return firstName && lastName && email && password
  }

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmailError(!validateEmail(input));
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    setPasswordError(!validatePassword(input));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkValidForm(isFormValid)) {
      openSnackBar(SnackbarTypes.INVALID_CREDENTIALS.field);
      return;
    }
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const payload = {
      user: {
        email: data.get('email'),
        password: data.get('password'),
        firstname: data.get('firstName'),
        lastname: data.get('lastName'),
      }
    }
    if (tokenData) {
      payload.user.roles = tokenData.roles
      payload.user.exams_left = tokenData.exams
    }

    await api.post(`/auth/signup`, payload).then(async response => {
      if (response.status === HttpStatusCode.Ok) {
        handleSelectedtierIfExist();
        historyContext.resetHistory()
        if (tokenData?.roles.includes(ROLES.IL_TEACHER.label)) {
          userContext.login(response.data)
          navigate('/start')
        } else {
          const encoded_email = btoa(data.get('email'));
          navigate(`/validation/stop?e=${encoded_email}`);
        }
      }
      else {
        if (response.status === HttpStatusCode.TooManyRequests) {
          openSnackBar(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
        }
        else {
          openSnackBar('ERROR')
        }
      }
    }).catch(err => {
      console.error(err)
      if (err.response?.status === HttpStatusCode.Conflict) {
        navigate('/login');
        openSnackBar('CONFLICT')
      }
      else {
        if (err.response?.status === HttpStatusCode.TooManyRequests) {
          openSnackBar(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
        }
        else {
          openSnackBar('ERROR')
        }
      }
    }).finally(() => {
      setIsLoading(false);
    })
  };

  function handleIdmMoePreClick() {
    if (tokenData?.token)
      sessionStorage.setItem('tokenId', tokenData.token);
  }

  const handleEmailSignInToggle = () => {
    setShowForm(prevShowForm => !prevShowForm);
  };

  return (
    <>
      <Helmet> <title>StudyWise | Signup</title> <meta name="description" content={"Join ExamGenerator! Sign up for your free trial and unlock the power of AI-powered exam creation. Generate personalized, high-quality exams in seconds with diverse question types, customizable difficulty, and time-saving features. Focus on teaching, not test creation - Start your free trial now!"} /> </Helmet>
      <Header />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            direction: isRTL ? 'rtl' : 'ltr'
          }}
        >
          <img src={Logo} alt="Logo" style={{ marginBottom: '20px', width: '50%', height: 'auto' }} />

          {showForm && (
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label={languageData?.sign_up?.first_name_label ?? "First Name"}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label={languageData?.sign_up?.last_name_label ?? "Last Name"}
                    name="lastName"
                    autoComplete="family-name"
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type='email'
                    label={languageData?.sign_up?.email_label ?? "Email Address"}
                    name="email"
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError ? languageData?.sign_up?.email_helper_Text ?? 'Please enter a valid email' : ''}
                    autoComplete="email"
                    onInput={handleTextFieldChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    onInput={handleTextFieldChange}
                    name="password"
                    label={languageData?.sign_up?.password_label ?? "Password"}
                    onChange={handlePasswordChange}
                    type="password"
                    error={passwordError}
                    helperText={
                      passwordError ? languageData?.sign_up?.password_helper_Text ?? 'Password must be at least 6 characters long' : ''
                    }
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isLoading ? <CircularProgress color='inherit' size={24} /> : (languageData?.sign_up?.sign_up_btn ?? "Sign Up")}
                  </Button>

                </Grid>
                <Grid item xs={12}>
                  <Divider>or</Divider>
                </Grid>
              </Grid>
            </Box>)}
          <Grid item xs={12} >
            <Stack spacing={2} sx={{ alignItems: 'center', mt: 3 }}>
              <ContinueWithGoogleButton tokenData={tokenData} />
              <IdmMoeILButton preClick={handleIdmMoePreClick} />
              {!showForm && (
                <Button variant="outlined" sx={{ backgroundColor: 'white', color: colors.main, width: '-webkit-fill-available', }} startIcon={!isRTL ? <EmailIcon /> : null} onClick={handleEmailSignInToggle}>
                  {languageData?.sign_up?.email ?? 'Sign In With Email'}
                </Button>
              )}
              <ContinueWithMicrosoftButton tokenData={tokenData} />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Link href="login" variant="body2">
                  {languageData?.sign_up?.already_account ?? 'Already have an account? Log in'}
                </Link>
              </Box>
            </Stack>
          </Grid>

          <Grid container justifyContent="center" mt={3}>
            <Grid item>
              {isRTL ? <HebrewPrivacyAndTermsOfService buttonText={"הרשמה"} operationText={"לחיצה על"} /> : <PrivacyAndTermsOfService buttonText={"Signing Up"} />}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}