// UserContext.js
import React, { createContext, useState } from 'react';
import { getLoggedInUser } from '../requests/auth';
import { getIsAdmin } from '../requests/dashboard';
import ROLES from '../utils/roles';
import '../utils/typedefs/dashboard.js'

const UserContext = createContext();

const UserProvider = ({ children }) => {
  /**@type {[User, Function]} */
  const [user, setUser] = useState(null);
  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
  };

  const decrementExamsLeft = () => {
    if (user)
      setUser(prevUser => ({ ...prevUser, exams_left: prevUser.exams_left - 1 }))
  }

  const isPremium = () => {
    if (user)
      return ROLES.isPremiumRole(user?.roles)
    else return false
  }

  const isLoggedIn = async () => {
    if (user && user._id) return Promise.resolve(user)
    return getLoggedInUser().then(user => {
      login(user)
      return user
    }).catch(e => {
      return null
    })
  }

  /**
   * 
   * @param {{forceFetch: boolean}} options 
   * @returns {boolean}
   */
  const isAdmin = async (options = {}) => {
    let currentUser = user;

    if (!currentUser || !currentUser._id) {
      currentUser = await isLoggedIn();
    }

    if (options?.forceFetch !== true && currentUser && currentUser.is_admin !== undefined) {
      return Promise.resolve(currentUser.is_admin);
    }
    try {
      const result = await getIsAdmin(currentUser._id);
      setUser(prevUser => {
        if (prevUser) {
          return { ...prevUser, is_admin: !!result };
        }
        return { is_admin: !!result };
      });
      return result;
    } catch (error) {
      return false;
    }

    return false;
  }

  return (
    <UserContext.Provider value={{ user, login, logout, decrementExamsLeft, isLoggedIn, isAdmin, isPremium }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };


function createOrgComboMoeIl(user) {
  /**
   * Create an organization combo for the Ministry
   */
  if (user.idm_data && user.idm_data.orgrolecomplex) {
    const mosadCodePattern = /mosad:(\d+)/;

    // Extract the mosad codes, remove duplicates with a Set, and join with commas
    const orgsCodes = Array.from(new Set(
      user.idm_data.orgrolecomplex.map(orgRole => {
        const match = orgRole.match(mosadCodePattern);
        return match ? match[1] : null;
      }).filter(code => code !== null)  // Remove null values
    ));

    user.idm_data.orgs_combo = orgsCodes.join(", ");
  }
}
