import React, { useState } from 'react';
import { Button } from '@mui/material';
import HubApproveDialog from './HubApproveDialog';
import { isMobile } from '../../utils';
import { iconButtonStyle } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';
import { addExamToHub } from '../../requests/hub'
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const SaveToHubButton = ({ title, examId, forceMobileStyle = true }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { isRTL } = useLanguage();
    const { openSnackBar } = useSnackBar()
    const iconBtnStyle = forceMobileStyle ? iconButtonStyle.mobile : iconButtonStyle.desktop(isRTL)

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleApprove = () => {
        addExamToHub(examId)
        openSnackBar(SnackbarTypes.APPROVE_EXAM_TO_HUB.field)
        handleCloseDialog();
    };

    return (
        <>
            <Button
                title={title}
                variant='outlined'
                startIcon={<BookmarkIcon />}
                sx={iconBtnStyle}
                onClick={handleOpenDialog}
            >
            </Button>
            <HubApproveDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onApprove={handleApprove}
            />
        </>
    );
};

export default SaveToHubButton;
