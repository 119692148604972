import React, { useState, useMemo } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Chip, MenuItem, Select } from '@mui/material';
import generateEnglishPDF from '../assets/tutorials/generate.pdf';
import autoGradeEnglishPDF from '../assets/tutorials/grade.pdf';
import generateHebrewPDF from '../assets/tutorials/יצירה.pdf';
import autoGradeHebrewPDF from '../assets/tutorials/בדיקה.pdf';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';
import { languageCodes } from '../contexts/languageContext';

const tutorials = [
    {
        title: "How To Generate New Exam",
        subtitle: "Step-by-step guide on generating new exams",
        link: generateEnglishPDF,
        language: languageCodes.english
    },
    {
        title: "How To Auto-Grade Exam",
        subtitle: "Automate the grading process efficiently",
        link: autoGradeEnglishPDF,
        language: languageCodes.english
    },
    {
        title: "איך לייצר מבחן חדש",
        subtitle: "מדריך שלב אחר שלב ליצירת מבחן חדש",
        link: generateHebrewPDF,
        language: languageCodes.hebrew
    },
    {
        title: "איך לבדוק אוטומטית מבחן",
        subtitle: "אוטומציה של תהליך בדיקת המבחן",
        link: autoGradeHebrewPDF,
        language: languageCodes.hebrew
    },
    {
        title: "צפייה מקדימה ומענה על בחינה",
        subtitle: "מדריך לצפייה והשתתפות בבחינה",
        link: 'https://www.youtube.com/watch?v=Rc7Ms5WWwRk',
        language: languageCodes.hebrew
    },
    {
        title: "אפשרויות להעלאת תוכן",
        subtitle: "איך להעלות תוכן למערכת",
        link: 'https://www.youtube.com/watch?v=qv0XF6IsSoM',
        language: languageCodes.hebrew
    },
    {
        title: "עריכה והוספת שאלות לבחינה",
        subtitle: "מדריך לעריכה והוספת שאלות",
        link: 'https://www.youtube.com/watch?v=O0UcdGMpvvU',
        language: languageCodes.hebrew
    },
    {
        title: "יצירת בחינה מבוססת מיומנויות",
        subtitle: "איך ליצור בחינות שמתמקדות במיומנויות",
        link: 'https://www.youtube.com/watch?v=gH97kS_-q4U',
        language: languageCodes.hebrew
    },
    {
        title: "יצירת בחינה תוך מספר שניות עם בינה מלאכותית",
        subtitle: "מדריך לשימוש בבינה מלאכותית ליצירת בחינות",
        link: 'https://www.youtube.com/watch?v=0wD05xr5ALg',
        language: languageCodes.hebrew
    },
    {
        title: "הרשמה למערכת ומילוי פרטים אישיים",
        subtitle: "מדריך להרשמה ולמילוי פרטים",
        link: 'https://www.youtube.com/watch?v=JeCY_uKok7Y',
        language: languageCodes.hebrew
    },
    {
        title: "ציון אוטומטי ומשוב מפורט למבחנים",
        subtitle: "איך לקבל ציון אוטומטי ומשוב למבחנים",
        link: 'https://www.youtube.com/watch?v=nTAJauXeoaw',
        language: languageCodes.hebrew
    },
    {
        title: "כיצד לנתח ציונים ומשובים",
        subtitle: "מדריך לניתוח ציונים ומשובים",
        link: 'https://www.youtube.com/watch?v=1GLHJN13Xw4',
        language: languageCodes.hebrew
    }
];

const Tutorials = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(languageCodes.english);

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const filteredTutorials = useMemo(() => {
        return tutorials.filter(tutorial => tutorial.language === selectedLanguage);
    }, [selectedLanguage]);

    return (
        <div>
            <Helmet>
                <title>StudyWise | Tutorials</title>
                <meta name="description" content="Explore our tutorials to master the AI-powered exam generator. Learn how to create personalized exams, customize questions, and optimize your workflow with our step-by-step guides." />
            </Helmet>
            <Header />
            <Container style={{ padding: '30px' }}>
                <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    style={{ marginBottom: '20px' }}
                >
                    {Object.keys(languageCodes).map(key => (
                        <MenuItem key={key} value={languageCodes[key]}>
                            {key.charAt(0).toUpperCase() + key.slice(1)} {/* Capitalize first letter */}
                        </MenuItem>
                    ))}
                </Select>
                {filteredTutorials.length === 0 ? (
                    <Typography variant="body1" color="textSecondary">
                        Tutorials for this language are coming soon...
                    </Typography>
                ) : (
                    <Grid container spacing={3}>
                        {filteredTutorials.map((tutorial, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardContent style={{ direction: tutorial.language === languageCodes.hebrew ? 'rtl' : 'ltr' }}>
                                        <Typography variant="h5" component="h2">
                                            {tutorial.title}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {tutorial.subtitle}
                                        </Typography>
                                        <div style={{ marginTop: '10px' }}>
                                            <Chip label={tutorial.language === languageCodes.hebrew ? 'עברית' : 'English'} />
                                        </div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            href={tutorial.link}
                                            target="_blank"
                                            style={{ marginTop: '16px' }}
                                        >
                                            {tutorial.language === languageCodes.hebrew ? 'לצפייה במדריך' : 'View Tutorial'}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
        </div>
    );
};

export default Tutorials;
