import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import logoImage from "../../assets/StudyWiseHorizontalLogo.png";
import { links, contactUs } from "../../utils";
import { websiteLinks } from "../../utils/configs";

const logoStyle = {
    width: "150px",
    height: "auto",
    cursor: "pointer",
    margin: "10px",
};

function Copyright() {
    return (
        <Typography variant="body2" color="#94A6B8" mt={1}>
            {"© "}
            <Link href="#footer">StudyWise&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function HebrewFooter() {

    const handleSubmit = () => {
        window.open(websiteLinks.SCHOOL(''));
    };

    return (
        <div id="footer">
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: '#131921',
                    color: '#94A6B8',
                    alignItems: "center",
                    minWidth: '-webkit-fill-available',
                    gap: { xs: 4, sm: 6 },
                    textAlign: { xs: "center", sm: "right" },
                    py: 8,
                    direction: 'rtl', // Set RTL direction for Hebrew
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row-reverse" },
                        width: "100%",
                        px: 5,
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                            minWidth: { xs: "100%", sm: "60%" },
                        }}
                    >
                        <Box sx={{ width: { xs: "100%", sm: "60%" }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ mr: { xs: "0px", sm: "-15px" } }}>
                                <img src={logoImage} style={logoStyle} alt="לוגו של StudyWise" />
                            </Box>
                            <Typography variant="body1" color="text.secondary" mb={2} sx={{ color: '#94A6B8' }}>
                                הצעה מיוחדת למורים ולתלמידים!
                            </Typography>
                            <Stack direction="row" spacing={1} useFlexGap justifyContent={{ xs: "center", sm: "end" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: '150px', backgroundColor: '#007bff' }}
                                    onClick={handleSubmit}
                                >
                                    שלח
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", sm: "flex" },
                            flexDirection: "column",
                            gap: 1,
                        }}
                    >
                        <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}>
                            מוצר
                        </Typography>
                        <Link color="text.secondary" href="#features" sx={{ color: '#94A6B8' }} > תכונות </Link>
                        <Link color="text.secondary" href="#highlights" sx={{ color: '#94A6B8' }} > הישגים </Link>
                        <Link color="text.secondary" href="/api-docs" target="_blank" sx={{ color: '#94A6B8' }} > API </Link>
                        <Link color="text.secondary" href="#faq" sx={{ color: '#94A6B8' }} > שאלות נפוצות </Link>
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "flex" }, flexDirection: "column", gap: 1, }} >
                        <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}>חברה</Typography>
                        <Link color="text.secondary" sx={{ color: '#94A6B8' }} href={links.LINKEDIN} target="_blank"> אודותינו </Link>
                        <Link color="text.secondary" sx={{ color: '#94A6B8' }} href="#footer"> קריירה (בקרוב) </Link>
                        <Link color="text.secondary" onClick={contactUs} sx={{ color: '#94A6B8' }} href="#footer"> צור קשר </Link>
                        <Link color="text.secondary" sx={{ color: '#94A6B8' }} href={links.BUG_FORM} target="_blank"> דיווח על תקלה </Link>
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "flex" }, flexDirection: "column", gap: 1, }} >
                        <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}> טפסים </Typography>
                        <Link color="text.secondary" href={links.TOS} target="_blank" sx={{ color: '#94A6B8' }}> תנאי שימוש </Link>
                        <Link color="text.secondary" href={links.PRIVACY} target="_blank" sx={{ color: '#94A6B8' }}> מדיניות פרטיות </Link>
                        <Link color="text.secondary" href={links.DATA} target="_blank" sx={{ color: '#94A6B8' }}> שמירת נתונים </Link>
                        <Link color="text.secondary" href={links.ACCESSIBILITY} target="_blank" sx={{ color: '#94A6B8' }}> הצהרת נגישות </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pt: { xs: 4, sm: 4 },
                        width: "100%",
                        borderTop: "1px solid",
                        borderColor: "#94A6B8",
                        flexDirection: { xs: "column", sm: "row-reverse" },
                        px: 5,
                    }}
                >
                    <div>
                        <Link color="text.secondary" href={links.PRIVACY} target="_blank" sx={{ color: '#94A6B8' }}> מדיניות פרטיות </Link>
                        <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, color: '#94A6B8' }}> &nbsp;•&nbsp; </Typography>
                        <Link color="text.secondary" href={links.TOS} target="_blank" sx={{ color: '#94A6B8' }}>תנאי שירות</Link>
                        <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, color: '#94A6B8' }}>&nbsp;•&nbsp;</Typography>
                        <Link color="text.secondary" onClick={contactUs} target="_blank" sx={{ color: '#94A6B8' }} href="#footer">info@studywise.io</Link>
                        <Copyright />
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        useFlexGap
                        sx={{
                            color: "text.secondary",
                        }}
                        pt={{ xs: 3, sm: 0 }}
                    >
                        <IconButton
                            color="inherit"
                            href={links.INSTAGRAM}
                            target="_blank"
                            aria-label="Instagram"
                            sx={{ alignSelf: "center", color: '#94A6B8' }}
                        >
                            <InstagramIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href={links.LINKEDIN}
                            target="_blank"
                            aria-label="LinkedIn"
                            sx={{ alignSelf: "center", color: '#94A6B8' }}
                        >
                            <LinkedInIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://www.youtube.com/@ExamGenerator"
                            target="_blank"
                            aria-label="YouTube"
                            sx={{ alignSelf: "center", color: '#94A6B8' }}
                        >
                            <YouTubeIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Container>
        </div>
    );
}
