import React, { createContext, useEffect, useState } from 'react';
import { getAllResponses, getResponseInfo } from '../requests/students'
import { useUser } from '../hooks/useUser';
import { convertISOToDDMMYYYY } from '../utils/dateHelpers'

const StudentHistoryContext = createContext();

const StudentHistoryProvider = ({ children }) => {
    const [studentHistory, setStudentHistory] = useState(null);
    const { user } = useUser()

    useEffect(() => {
        if (!user) {
            setStudentHistory(null);
        }
    }, [user]);

    const fetchHistory = async () => {
        const userId = user?._id
        if (!userId) return Promise.reject('User not found')

        return getAllResponses(userId).then(studenthistory => {
            const preparedHistory = prepareStudentHistoryByDate(studenthistory)
            setStudentHistory(preparedHistory ?? [])
            return preparedHistory
        })
    }

    const getResponseFromHistory = async (responseId) => {
        return getResponseInfo(user?.personal_id, responseId)
    }

    return (
        <StudentHistoryContext.Provider value={{ studentHistory, getAllResponses, getResponseFromHistory, fetchHistory }}>
            {children}
        </StudentHistoryContext.Provider>
    );
};

export { StudentHistoryProvider, StudentHistoryContext };


function prepareStudentHistoryByDate(userHistory) {
    // Filter items with undefined date
    const validItems = userHistory.filter(item => item.date).map(exam => ({ ...exam, date: convertISOToDDMMYYYY(exam.date) ?? '' }));

    // Sort by date (converting "dd/mm/yyyy")
    validItems.sort((a, b) => {
        if (!a.date || !b.date) // move to the last of the array
            return -1
        const dateA = a.date.split('/').reverse().join('/'); // Convert to "yyyy/mm/dd"
        const dateB = b.date.split('/').reverse().join('/'); // Convert to "yyyy/mm/dd"
        return new Date(dateB) - new Date(dateA);
    });

    // Include items with undefined dates at the end
    const sortedHistory = validItems.concat(userHistory.filter(item => !item.date));

    return sortedHistory;
}
