import { Box, Button, Card, Chip, CardActions, CardContent, Container, Divider, Grid, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { websiteLinks } from '../../utils/configs';
import { colors } from "../../utils/customTheme";

const tiers = [
    {
        title: "Professional",
        originalPrice: "13",
        discountedPrice: "8",
        link: websiteLinks.PRO,
        description: [
            "30 Exams Per Month",
            "GPT-4 Model",
            "50,000 words per exam",
        ],
        buttonText: "Get Started",
        buttonVariant: "outlined",
    },
    {
        title: "Premium",
        subheader: "Best",
        originalPrice: "18",
        discountedPrice: "12",
        link: websiteLinks.PREMIUM,
        description: [
            "Unlimited Exams",
            "GPT-4o, Gemini, Claude",
            "100,000 words per exam",
            "AI Auto-Grade",
            "Dashboard",
            "Personalization options",
        ],
        buttonText: "Get Started with premium",
        buttonVariant: "contained",
    },
    {
        title: "Schools & Organizations",
        price: "Custom",
        link: websiteLinks.SCHOOL,
        description: [
            "Analytics dashboard",
            "Integration with LMS",
            "Dedicated support team",
        ],
        buttonText: "Contact us",
        buttonVariant: "outlined",
    },
];

export default function PricingPlans({ email }) {
    return (
        <Container sx={{ minHeight: '100vh', bgcolor: colors.onboarding, minWidth: '100%' }}>
            <Container
                id="pricing"
                sx={{
                    pt: { xs: 4, sm: 3 },
                    pb: { xs: 2, sm: 3 },
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: 2, sm: 4 },
                    maxWidth: '100%',
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", sm: "80%" },
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h4" color="text.primary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' }, fontWeight: 600 }}>
                        Plans & Pricing
                    </Typography>
                    <Typography variant="body1" color="white" sx={{ borderRadius: 1, p: 1.3, display: 'inline-block', backgroundColor: 'secondary.main', fontSize: { xs: '0.875rem', sm: '1rem' }, mt: 2, fontWeight: 500 }}>
                        Limited Offer 🔥
                    </Typography>

                </Box>
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                    {tiers.map((tier) => (
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === "Schools & Organizations" ? 12 : 6}
                            md={4}
                            lg={3}
                        >
                            <Card
                                sx={{
                                    p: 1.5,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    border: tier.title === "Premium" ? "1px solid" : undefined,
                                    borderColor: tier.title === "Premium" ? "primary.main" : undefined,
                                    background: tier.title === "Premium" ? "linear-gradient(#033363, #021F3B)" : undefined,
                                }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            mb: 1,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            color: tier.title === "Premium" ? "grey.100" : "",
                                        }}
                                    >
                                        <Typography component="h3" variant="h5" sx={{ color: tier.title === 'Premium' ? 'white' : '' }}>
                                            {tier.title}
                                        </Typography>
                                        {tier.title === "Premium" && (
                                            <Chip
                                                icon={<AutoAwesomeIcon />}
                                                label={tier.subheader}
                                                size="small"
                                                sx={{
                                                    padding: 0.5,
                                                    backgroundColor: "primary.contrastText",
                                                    "& .MuiChip-label": {
                                                        color: "primary.dark",
                                                    },
                                                    "& .MuiChip-icon": {
                                                        color: "primary.dark",
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            color: tier.title === "Premium" ? "grey.50" : undefined,
                                        }}
                                    >
                                        {!isNaN(parseInt(tier.originalPrice)) && (
                                            <>
                                                <Typography component="h5" variant="h5" sx={{ textDecoration: 'line-through', color: tier.title === 'Premium' ? 'white' : '' }}>
                                                    ${tier.originalPrice}
                                                </Typography>
                                                <Typography component="h3" variant="h4" sx={{ ml: 1, color: tier.title === 'Premium' ? 'white' : '' }}>
                                                    ${tier.discountedPrice}
                                                </Typography>
                                                <Typography component="h6">
                                                    &nbsp; / month
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                    <Divider
                                        sx={{
                                            my: 1,
                                            opacity: 0.2,
                                            borderColor: "grey.500",
                                        }}
                                    />
                                    {tier.description.map((line) => (
                                        <Box
                                            key={line}
                                            sx={{
                                                py: 0.5,
                                                display: "flex",
                                                gap: 1,
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckCircleRoundedIcon
                                                sx={{
                                                    width: 18,
                                                    color: tier.title === "Premium" ? '#317abf' : "primary.main",
                                                }}
                                            />
                                            <Typography
                                                component="text"
                                                variant="body2"
                                                sx={{
                                                    color: tier.title === "Premium" ? "grey.200" : undefined,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {line}
                                            </Typography>
                                        </Box>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant={tier.buttonVariant}
                                        sx={{ backgroundColor: tier.title === 'Premium' ? '#317abf' : '' }}
                                        component="a"
                                        onClick={() => {
                                            window.open(tier.link(email));
                                        }}
                                        target="_blank"
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box
                    sx={{
                        mt: 4,
                        textAlign: "center",
                        py: 2,
                    }}
                >
                    <Typography variant="h6" color="text.primary">
                        Questions?{" "}
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                window.open(websiteLinks.SCHOOL(email), "_blank");
                            }}
                        >
                            Contact us
                        </Button>
                    </Typography>
                </Box>
            </Container>
        </Container>
    );
}
