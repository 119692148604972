import React, { useState } from 'react';
import { Popover, List, ListItem, ListItemText, IconButton, TextField, Button, Divider, Typography, Box, Tooltip } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { modifyOptions } from '../../utils/examOptions';
import { languageCodes, useLanguage } from '../../contexts/languageContext';

const ModifyQuestionPopover = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customOption, setCustomOption] = useState('');
  const { isRTL, languageData, language } = useLanguage();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCustomOption('');
  };

  const handleOptionSelect = (option) => {
    onSelect(option);
    handleClose();
  };

  const handleCustomOptionSubmit = () => {
    if (customOption.trim() !== '') {
      onSelect(customOption);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title={languageData?.preview?.tooltips.modify || "Modify question"} arrow>
        <IconButton onClick={handleClick} aria-label="Modify question">
          <AutoFixHighIcon color="secondary" />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isRTL ? 'right' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isRTL ? 'right' : 'center',
        }}
        PaperProps={{
          sx: { width: 300, textAlign: 'center', padding: '8px' },
        }}
      >
        <List>
          {modifyOptions.map((option, index) => (
            <Box key={index}>
              <ListItem
                button
                onClick={() => handleOptionSelect(option.value)}
                sx={{
                  textAlignLast: 'center',
                  padding: '8px 16px',
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontSize: '0.9rem', fontWeight: 500 }}>
                      {languageCodes.hebrew === language ? option.hebrewLabel : option.label}
                    </Typography>
                  }
                />
              </ListItem>
              {index < modifyOptions.length - 1 && <Divider sx={{ marginY: 1 }} />}
            </Box>
          ))}
          {/* Custom Option Input */}
          <Divider sx={{ marginY: 1 }} />
          <ListItem>
            <TextField
              fullWidth
              variant="outlined"
              label={
                <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                  {languageData?.preview?.modify.add_option}
                </Typography>
              }
              value={customOption}
              onChange={(e) => setCustomOption(e.target.value)}
              multiline
              sx={{ fontSize: '0.85rem' }}
            />
            <Button
              onClick={handleCustomOptionSubmit}
              disabled={customOption.trim() === ''}
              sx={{ ml: 1, fontSize: '0.85rem', minWidth: 'auto', paddingX: 1 }}
              variant="contained"
              color="primary"
            >
              {languageData?.preview?.modify.add}
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default ModifyQuestionPopover;
