import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Assignment, Timer, Analytics } from '@mui/icons-material';
import HomeworkImg from '../../../assets/features/homework.jpg'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const HomeworkUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="AI-Driven Homework and Assignment Maker">
                                    AI-Driven Homework and Assignment Maker: Elevating Educational Standards Through Enhanced Learning and Assessment Tools
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Experience the future of education with our state-of-the-art AI-driven homework and assignment maker. Our platform is meticulously designed to transform the traditional approaches to managing homework and assignments, making it easier for educators to engage students and monitor their academic progress effectively. By incorporating advanced AI technology, we streamline the entire process of assignment creation, distribution, and evaluation, significantly enhancing the overall educational experience and improving learning outcomes. This approach ensures that AI remains a critical asset in modern education, driving success in the ever-evolving academic landscape.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our cutting-edge AI tools, educators can effortlessly generate customized homework assignments that align with specific learning objectives and curriculum goals. The platform’s powerful automation features simplify the management of submission deadlines, track student progress in real-time, and provide actionable insights into performance. Our detailed analytics help educators make data-driven decisions to refine assignments, adapt teaching strategies, and ultimately enhance student achievement.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our AI-driven platform not only automates repetitive tasks but also provides comprehensive feedback and performance analysis. This allows educators to focus on delivering personalized instruction and fostering a supportive learning environment. Whether you are looking to automate assignment distribution, monitor student submissions, or gain valuable insights into academic performance, our platform offers a holistic solution for modern educational needs. 
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our Advanced AI-Powered Platform for Homework and Assignments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Streamlined Homework Assignment Creation and Management</Typography>
                                    </Box>
                                    Our AI-driven assignment maker revolutionizes the way homework is created and managed. By offering customizable assignment templates and automated distribution features, educators can align assignments with curriculum objectives and streamline the process of delivering and collecting student work. This efficiency allows for more focus on teaching and less on administrative tasks. In a market where test preparation is vital, our platform empowers educators to enhance their assessment strategies.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Timer color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Automated Tracking and Management of Submissions and Deadlines</Typography>
                                    </Box>
                                    Manage assignment submissions and deadlines with ease using our automated tracking tools. The platform sends reminders, tracks submission statuses, and ensures that all deadlines are met without manual intervention. This automation reduces administrative burden and ensures that no assignment is overlooked. By providing real-time updates on student progress, educators can intervene when necessary and provide timely feedback to support student learning and growth.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Analytics color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Performance Analytics and Insights</Typography>
                                    </Box>
                                    Our platform offers detailed performance analytics that provide valuable insights into student progress and achievement. Through comprehensive reports and visualizations, educators can identify trends, pinpoint areas for improvement, and make informed decisions to enhance assignment quality and teaching effectiveness. This data-driven approach supports a more personalized learning experience and drives better educational outcomes. Our platform also includes free assignment templates for educators.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                Maximizing the Impact of Homework and Assessments with AI Technology
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leverage our AI-powered platform to transform how homework and assessments are managed, designed, and evaluated. From creating and assigning homework to tracking progress and analyzing performance, our tools provide a comprehensive solution that supports educators in delivering high-quality, data-driven education. Enhance your teaching strategies, engage students more effectively, and achieve outstanding academic results with our innovative platform. 
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Image Column */}
                        {/* <Grid item xs={12}>
                            <img 
                                src={HomeworkImg} 
                                alt="AI Homework and Assignment Maker" 
                                style={{ width: '100%', borderRadius: 8, border: '1px solid black' }}
                                aria-label="Homework Image"
                            />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default HomeworkUseCase;
