import React from 'react';
import { useLanguage } from '../../contexts/languageContext';
import { Card, CardActions, Button, Typography, Grid, Box, Avatar, Divider } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StudentsList = ({ students }) => {
    const { isRTL, languageData } = useLanguage();
    const navigate = useNavigate();

    return (
        <Grid container spacing={3} justifyContent={isRTL ? "end" : "start"}>
            {students?.map((student, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                        sx={{
                            boxShadow: 3,
                            borderRadius: 3,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: isRTL ? 'right' : 'left',
                            height: '100%',
                            backgroundColor: '#F9FAFB'
                        }}
                        dir={isRTL ? 'rtl' : 'ltr'}
                    >
                        <Avatar sx={{ width: 48, height: 48, mb: 2, }}>
                            <AccountCircleIcon sx={{ fontSize: 44 }} />
                        </Avatar>

                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: colors.dark }}>
                            {student?.studentInfo?.name}
                        </Typography>

                        {/* Student Details */}
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                {languageData?.my_students?.exams_taken || "Exams Taken"}: {student?.responses?.length || 0}
                            </Typography>
                            {/* Additional details can be added here in the future */}
                        </Box>

                        {/* Action Button */}
                        <CardActions sx={{ width: '100%', px: 2 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => student?.studentInfo?._id && navigate(`${student.studentInfo._id}`)}
                                sx={{
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    fontWeight: 'medium',
                                    py: 1,
                                    bgcolor: colors.main,
                                    '&:hover': { bgcolor: colors.mainHover }
                                }}
                            >
                                {languageData?.my_students?.view_student || "View Student's Performance"}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default StudentsList;
