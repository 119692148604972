// UploadCSV.js
import * as XLSX from 'xlsx';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Typography, Input, Button, Grid } from '@mui/material';
import { DownloadCSVButton } from './DownloadCSV';
import { toSnakeCase } from '../../utils';

const UploadCSV = forwardRef(({ onFileUpload, whitelist: whitelistParam, orgName }, ref) => {
    const [csvError, setCsvError] = useState('');
    const [whitelist, setWhitelist] = useState(whitelistParam ?? []);
    const fileInputRef = useRef(null)

    const reset = () => {
        setCsvError('')
        if (fileInputRef.current) fileInputRef.current.value = ''
    }

    useImperativeHandle(ref, () => ({
        reset: reset
    }))

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Check file format
        if (file.name.endsWith('.csv')) {
            handleCsvFile(file);
        } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
            handleXLSXFile(file);
        } else {
            setError('Invalid file format. Please upload a CSV or XLSX file.');
        }
    };

    const handleCsvFile = (csvFile) => {
        // Read the CSV file
        const reader = new FileReader();
        reader.onload = (e) => {
            const csvText = e.target.result;

            // Convert CSV to JSON
            const jsonData = convertCsvToArray(csvText);

            // Remove empty objects
            const cleanedArrayData = jsonData.filter(entry => Object.keys(entry).length > 0);
            setWhitelist(cleanedArrayData)
            // Pass the JSON data to the onFileUpload function
            onFileUpload(cleanedArrayData);
        };

        reader.readAsText(csvFile);
    }

    const handleXLSXFile = (xlsxFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            // Trim each value in the sheetData array
            const cleanedArrayData = sheetData.map(entry => {
                const cleanedEntry = {};
                Object.keys(entry).forEach(key => {
                    cleanedEntry[key] = entry[key].trim();
                });
                return cleanedEntry;
            }).filter(entry => Object.keys(entry).length > 0);

            setWhitelist(cleanedArrayData)
            onFileUpload(cleanedArrayData);
        };
        reader.readAsArrayBuffer(xlsxFile);
    };

    const cancelCSVUpload = (e) => {
        if (fileInputRef.current)
            fileInputRef.current.value = null
        setWhitelist([])
        onFileUpload([])
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography variant="h6">Upload WhiteList (.csv/.xlsx File)</Typography>
                <Typography variant="body2">Format: {'{'} name: string, email: string {'}'}</Typography>
                <input type="file" name='file' accept=".csv, .xlsx, .xls" onChange={handleFileChange} ref={fileInputRef} />
            </Grid>
            <Grid item xs={12} md={12}>
                <DownloadCSVButton list={whitelist} filename={(orgName ? toSnakeCase(orgName) + "_" : '') + 'whitelist'} />
                &nbsp;&nbsp;<Button variant="outlined" onClick={cancelCSVUpload} >Cancel CSV</Button>
            </Grid>
            {csvError && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {csvError}
                </Typography>
            )}
        </Grid>
    );
});

function convertCsvToArray(csvText) {
    const lines = csvText.split('\n');
    const headers = lines[0].split(',');
    const jsonData = [];

    for (let i = 1; i < lines.length; i++) {
        /** @type {string[]} */
        const values = lines[i].split(',');
        const entry = {};
        for (let j = 0; j < headers.length; j++) {
            // Remove special characters from keys and values
            if (headers[j] && values[j]) {
                const cleanKey = headers[j].replace(/\r/g, '').trim();
                const cleanValue = values[j].replace(/\r/g, '').trim();
                entry[cleanKey] = cleanValue;
            }
        }

        // Check if "email" field is not empty
        if (entry["email"] !== undefined && entry["email"].trim() !== "") {
            jsonData.push(entry);
        }
    }

    return jsonData;
};

function convertWhitelistStructure(whitelist) {
    /** 
     * This function is for your usage to convert the json data from whatever structre you get it
     * To a valid whitelist structure: {name: string, phone: string, email: string}[]
     */
    return whitelist.map(item => ({ name: item["First Name"] + ' ' + item["Last Name"], email: item.email }))
}

export default UploadCSV;

