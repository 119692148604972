import React, { useState } from 'react';
import { Tooltip, Chip, TextField, Typography } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { useLanguage } from '../../contexts/languageContext';
import { isMobile, renderTextWithBreakLines } from '../../utils';
import CustomLatex from '../../components/CustomLatex';

const EditableOpenQuestion = ({ languageData, questionIndex, title, grade, explanation, indicator, isEditing = false, onChange, handleGradeChange, gradeError }) => {
  const [gradeState, setGradeState] = useState(grade);
  const debouncedGradeChange = useDebounce(handleGradeChange, 500);

  const handleTitleChange = (newTitle) => {
    onChange(questionIndex, { title: newTitle, explanation, grade })
  }

  const handleExplanationChange = (newExplanation) => {
    onChange(questionIndex, { title, explanation: newExplanation, grade })
  }

  const handleIndicatorChange = (newIndicator) => {
    onChange(questionIndex, { title, indicator: newIndicator, grade })
  }

  const handleCurrentGradeChange = (event) => {
    setGradeState(event.target.value);
    debouncedGradeChange(event)
  };

  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={languageData.grade_label}
            onChange={handleCurrentGradeChange}
            value={gradeState}
            type='number'
            fullWidth
            variant="outlined"
            margin="normal"
            error={gradeError}
          />
          <TextField
            label={languageData.question_title}
            value={title ?? ""}
            onChange={e => handleTitleChange(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            error={!title}
          />
          <TextField
            label={languageData.explanation_label}
            value={indicator ?? explanation}
            onChange={e => indicator !== undefined ? handleIndicatorChange(e.target.value) : handleExplanationChange(e.target.value)}
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            variant="outlined"
            margin="normal"
            error={!indicator && !explanation}
          />
        </>
      ) : (
        <>
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
            <CustomLatex>{title}</CustomLatex>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              {indicator ? languageData.indicator_label : languageData.correct_answer}:&nbsp;&nbsp;
            </span>
            <br />
            {indicator ? renderTextWithBreakLines(indicator) : renderTextWithBreakLines(explanation)}
          </Typography>
        </>
      )}
    </div>
  );
};


export default EditableOpenQuestion;
