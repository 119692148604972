import React, { useCallback, useEffect } from 'react';
import { Button, Container, Typography, Paper, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Person, Assignment, QuestionAnswer } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { renderTextWithBreakLines } from '../../utils';

const defaultLeftExamMsg = "Your exam has been automatically submitted because you navigated away from the exam page. To maintain exam integrity, please avoid leaving the exam window in the future"
const ThankYou = ({ examId, responseId, examSettings, languageData, isRTL, examAnswers, studentDetails, leftExam }) => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const navigate = useNavigate();

  const handleViewResults = () => {
    sessionStorage.setItem('responseId', responseId);
    sessionStorage.setItem('examId', examId);
    navigate('/results');
  };

  const CustomListItem = useCallback((questionId, answer, i, j = '') => {
    const subIndex = j !== '' ? '.' + (j + 1) : ''
    return (
      <ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }} key={questionId}>
        <ListItemIcon>
          <QuestionAnswer color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              <Typography component="span" variant="body1" fontWeight="bold" sx={{ pr: 1 }}>
                {`${languageData?.question_label} ${i + 1}${subIndex}: `}&nbsp;&nbsp;
              </Typography>
              {renderTextWithBreakLines(answer ?? '')}
            </span>
          }
        />
      </ListItem>
    )
  }, [isRTL]);

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <Container maxWidth="md" sx={{ textAlign: 'center', paddingTop: '50px' }}>
        <Typography variant="h4" gutterBottom>
          {languageData?.completion_msgs?.title}
        </Typography>
        {!examSettings?.show_results && (
          <Typography variant="subtitle1" gutterBottom pb={2}>
            {languageData?.completion_msgs?.subtitle}
          </Typography>
        )}

        {examSettings?.is_supervised && leftExam && (
          <Typography variant="h6" color="error" gutterBottom pb={2}>
            {languageData?.completion_msgs?.left_exam_msg || defaultLeftExamMsg}
          </Typography>
        )}

        {examSettings?.show_results && (
          <Button variant="contained" color="primary" sx={{ m: 2 }} onClick={handleViewResults}>
            {languageData?.completion_msgs?.view_results}
          </Button>
        )}

        <Paper elevation={3} sx={{ textAlign: isRTL ? 'right' : 'left', marginBottom: '20px', padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            {languageData?.completion_msgs?.personal_info}
          </Typography>
          <List>
            <ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }}>
              <ListItemIcon>
                <Person color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    <Typography component="span" variant="body1" fontWeight="bold">
                      {`${languageData?.full_name}:`}
                    </Typography>
                    {` ${studentDetails?.fullname}`}
                  </span>
                }
              />
            </ListItem>
            {studentDetails?.id && <ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }}>
              <ListItemIcon>
                <Assignment color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    <Typography component="span" variant="body1" fontWeight="bold">
                      {`${languageData?.id}:`}
                    </Typography>
                    {` ${studentDetails?.id}`}
                  </span>
                }
              />
            </ListItem>}
          </List>
        </Paper>

        <Paper elevation={3} sx={{ textAlign: isRTL ? 'right' : 'left', marginBottom: '20px', padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            {languageData?.completion_msgs?.exam_answers}
          </Typography>
          <List>
            {examAnswers.map(({ questionId, answer, nested_answers }, i) => (
              nested_answers ?
                nested_answers.map((nestedAnswer, j) => CustomListItem(nestedAnswer.questionId, nestedAnswer.answer, i, j)) :
                CustomListItem(questionId, answer, i)
            ))}
          </List>
        </Paper>
      </Container>
    </div>
  );
};

export default ThankYou;
