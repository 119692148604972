import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, useMediaQuery } from '@mui/material';
import Logo from '../../assets/logo-horizonal.png';
import MenuIcon from '@mui/icons-material/Menu';

const menuItems = [
    { label: "Access token Information", id: "access-token" },
    { label: "Issue Access Token", id: "issue-access-token" },
    { label: "Issue One Time Token", id: "issue-one-time-token" },
    { label: "Create an exam", id: "create-exam" },
    { label: "Get Exam", id: "get-exam" },
    { label: "Evaluate an Exam", id: "evaluate-exam" },
    { label: "Get All Exam's Responses", id: "get-exam-responses" },
    { label: "Submit Exam Response", id: "submit-response" },
    { label: "Submit Response Review", id: "submit-review" },
    { label: "Update question", id: "update-question" },
    { label: "Read PPTX File", id: "read-pptx" },
    { label: "Read PDF File", id: "read-pdf" },
    { label: "Read DOCX File", id: "read-docx" },
    { label: "Read TXT File", id: "read-txt" },
];

const Sidebar = ({ drawerWidth = 200 }) => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const [open, setOpen] = useState(!isMobile); // Keep open by default on desktop

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            window.location.hash = id; // Update the URL hash
        }
        if (isMobile) setOpen(false); // Only close drawer on mobile after selecting an item
    };

    const toggleDrawer = () => {
        if (isMobile) {
            setOpen(!open);
        }
    };

    return (
        <>
            {isMobile && (
                <IconButton
                    onClick={toggleDrawer}
                    sx={{
                        position: 'fixed',
                        top: 16,
                        left: 16,
                        zIndex: 1300,
                    }}
                >
                    {!open && <MenuIcon />}
                </IconButton>
            )}
            <Drawer
                variant={isMobile ? "temporary" : "persistent"}
                open={open}
                onClose={toggleDrawer}
                sx={{
                    width: isMobile ? 'auto' : drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <div style={{ padding: 30, paddingBottom: 10 }}>
                    <img src={Logo} alt="Logo" style={{ width: '100%', height: 'auto' }} />
                </div>
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            button
                            key={item.id}
                            onClick={() => handleScroll(item.id)}
                        >
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

export default Sidebar;
