import { useEffect, useState, Fragment, useCallback } from "react";
import { useSkills } from "../../contexts/SkillsContext";
import { IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, CircularProgress, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DeleteConfirmationPopver } from "../../components/DeleteConfirmationPopover";
import { paddingPageStyle } from "../../utils/customTheme";
import Header from "../../components/Header";
import { homepage } from "../../components/menu/MenuLinks";
import { useUser } from "../../hooks/useUser";

const skillsCategories = ['cognitive', 'emotional', 'social']
function SkillsDashboard() {
    const { user, isAdmin } = useUser();
    const { skills, addSkill, editSkill, deleteSkill, fetchSkills } = useSkills();
    const [newSkill, setNewSkill] = useState({ label: { en: '', he: '' }, value: '', category: '' });
    const [editingSkillId, setEditingSkillId] = useState(null);
    const [editingData, setEditingData] = useState({ label: { en: '', he: '' }, value: '', category: '' });
    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [skillIdToDelete, setSkillIdToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        isAdmin().then(res => {
            if (!res) {
                window.location.href = homepage;
            }
        });
    }, [])

    useEffect(() => {
        if (!skills || !skills.length) {
            fetchSkills()
        }
    }, [skills, fetchSkills]);

    if (!user || !user.is_admin)
        return null;

    const handleAddSkill = useCallback(async () => {
        setIsLoading(true);
        if (newSkill.label.en && newSkill.label.he && newSkill.value && newSkill.category) {
            addSkill(newSkill).then(() => {
                setNewSkill({ label: { en: '', he: '' }, value: '', category: '' });
            }).catch(e => console.error(e)).finally(() => setIsLoading(false));
        } else {
            setIsLoading(false)
        }
    }, [newSkill, addSkill])

    const handleEditSkill = useCallback(async (id) => {
        setIsLoading(true);
        if (editingData.label.en && editingData.label.he && editingData.value && editingData.category) {
            editSkill(id, editingData).then(() => {
                setEditingSkillId(null);
                setEditingData({ label: { en: '', he: '' }, value: '' });
            }).catch(e => console.log(e))
        }
    }, [editingData, editSkill])

    const handleDeleteSkill = useCallback(async () => {
        setIsLoading(true);
        deleteSkill(skillIdToDelete).then(() => {
            setSkillIdToDelete(null);
            setDeletePopoverAnchorEl(null);
        }).catch(e => console.log(e))
    }, [skillIdToDelete, deleteSkill])

    return (
        <>
            <Header />
            <div style={{ ...paddingPageStyle, marginBottom: '10rem', padding: '16px 32px' }}>
                <Typography variant="h4" textAlign='center'>Skills Dashboard</Typography>
                <Grid container sx={{ maxWidth: '50rem', py: 4, gap: '10px', justifyContent: 'space-between', margin: 'auto' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Add New Skill</Typography>
                    </Grid>
                    <Grid item xs={3.9}>
                        <TextField
                            label="English Label"
                            variant="outlined"
                            fullWidth
                            value={newSkill.label.en}
                            onChange={(e) => setNewSkill({ ...newSkill, label: { ...newSkill.label, en: e.target.value } })}
                        />
                    </Grid>
                    <Grid item xs={3.9}>
                        <TextField
                            label="Hebrew Label"
                            variant="outlined"
                            fullWidth
                            value={newSkill.label.he}
                            onChange={(e) => setNewSkill({ ...newSkill, label: { ...newSkill.label, he: e.target.value } })}
                        />
                    </Grid>
                    <Grid item xs={3.9}>
                        <FormControl fullWidth>
                            <InputLabel id="category-select">Category</InputLabel>
                            <Select
                                labelId="category-select"
                                label="Category"
                                variant="outlined"
                                fullWidth
                                value={newSkill.category}
                                onChange={(e) => setNewSkill({ ...newSkill, category: e.target.value })}
                                sx={{ backgroundColor: 'transparent' }}
                            >
                                {skillsCategories.map((category, index) => (
                                    <MenuItem key={index} value={category}>{category}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Prompt"
                            variant="outlined"
                            fullWidth
                            multiline
                            value={newSkill.value}
                            onChange={(e) => setNewSkill({ ...newSkill, value: e.target.value })}
                        />
                    </Grid>
                    <Grid xs={12} display='flex' justifyContent='center'>
                        <Button variant="contained" color="primary" sx={{ width: '200px' }} onClick={handleAddSkill}>
                            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Add Skill'}
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Label (English)</TableCell>
                                <TableCell>Label (Hebrew)</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Prompt</TableCell>
                                <TableCell sx={{ minWidth: '150px' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {skills?.map((skill) => (
                                <Fragment key={skill._id}>
                                    <TableRow>
                                        <TableCell>{skill.label?.en}</TableCell>
                                        <TableCell>{skill.label?.he}</TableCell>
                                        <TableCell>{skill.category}</TableCell>
                                        <TableCell>{skill.value}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => {
                                                setEditingSkillId(skill._id);
                                                setEditingData({ label: { en: skill.label.en, he: skill.label.he }, value: skill.value, category: skill.category });
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={(e) => {
                                                setDeletePopoverAnchorEl(e.currentTarget);
                                                setSkillIdToDelete(skill._id);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {editingSkillId === skill._id && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <TextField
                                                        label="Edit Label (English)"
                                                        value={editingData.label.en}
                                                        onChange={(e) => setEditingData({ ...editingData, label: { ...editingData.label, en: e.target.value } })}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        label="Edit Label (Hebrew)"
                                                        value={editingData.label.he}
                                                        onChange={(e) => setEditingData({ ...editingData, label: { ...editingData.label, he: e.target.value } })}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        label="Edit Category"
                                                        value={editingData.category}
                                                        onChange={(e) => setEditingData({ ...editingData, category: e.target.value })}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        label="Edit Prompt"
                                                        value={editingData.value}
                                                        onChange={(e) => setEditingData({ ...editingData, value: e.target.value })}
                                                        variant="outlined"
                                                        multiline
                                                        sx={{ width: '-webkit-fill-available' }}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell >
                                                <IconButton onClick={() => handleEditSkill(skill._id)}>
                                                    <SaveIcon />
                                                </IconButton>
                                                <IconButton onClick={() => setEditingSkillId(null)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <DeleteConfirmationPopver
                anchorEl={deletePopoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => setDeletePopoverAnchorEl(null)}
                onCancel={() => setDeletePopoverAnchorEl(null)}
                onDelete={handleDeleteSkill}
                slotProps={{ paper: { sx: { p: 1 } } }}
            />
        </>
    );
}

export default SkillsDashboard;