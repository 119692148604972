import api from "./api";
import { processResponsePromise } from "./utils";

export async function getUserProfile(userId, token) {
    return processResponsePromise(api.get(`/users/${userId}/profile`, { headers: { "X-Token": token } }))
}

export async function getAllUserStudents(userId) {
    return processResponsePromise(api.get(`/users/${userId}/students/responses`))
}

export async function getUserOrgSSOType(userId, token) {
    if (!userId)
        return { status: 404, data: { message: 'User not found' } }
    return processResponsePromise(api.get(`/users/${userId}/org/sso-type`, { headers: { "X-Token": token } }))
}