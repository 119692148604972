import { createContext, useContext, useEffect, useState } from 'react';
import { getLobbyStatus } from '../requests/lobby';
import { useUser } from '../hooks/useUser';

const lobbyContext = createContext();

export const useLobby = () => {
    const context = useContext(lobbyContext);
    if (!context) {
        throw new Error('useLobby must be used within a LobbyProvider');
    }
    return context;
};


export const LobbyProvider = ({ children }) => {
    /** @type {[{email_verification: boolean, first_exam: boolean, auto_grade: boolean, review: boolean}, Function]} */
    const [lobbyStatus, setLobbyStatus] = useState(null)
    const { user } = useUser()

    useEffect(() => {
        if (!user) {
            setLobbyStatus(null);
        } else {
            if (!lobbyStatus) {
                fetchLobbyStatus()
            }
        }
    }, [user, lobbyStatus]);

    const fetchLobbyStatus = async () => {
        if (lobbyStatus) return Promise.resolve(lobbyStatus)
        return getLobbyStatus().then(res => {
            if (res) setLobbyStatus(res);
            return res;
        })
    }

    const createdExam = () => {
        if (lobbyStatus?.first_exam)
            return;
        setLobbyStatus(prevStatus => ({ ...prevStatus, first_exam: true }));
    }
    const autoGrade = () => {
        if (lobbyStatus?.auto_grade)
            return;
        setLobbyStatus(prevStatus => ({ ...prevStatus, auto_grade: true }));
    }
    const reviewedExam = () => {
        if (lobbyStatus?.review)
            return;
        setLobbyStatus(prevStatus => ({ ...prevStatus, review: true }));
    }

    return (
        <lobbyContext.Provider value={{ lobbyStatus, fetchLobbyStatus, createdExam, autoGrade, reviewedExam }}>
            {children}
        </lobbyContext.Provider>
    );
}
