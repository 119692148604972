export default class ROLES {
    // Do not display these labels to the client - they are only for comparisons and conditions
    static FREE = { label: 'FREE', limit: 10000 };
    static BASIC = { label: 'BASIC', limit: 20000 };
    static PRO = { label: 'PRO', limit: 50000 };
    static UNLIMITED = { label: 'UNLIMITED', limit: 100000 };
    static MEMBERSHIP = { label: 'MEMBERSHIP', limit: 100000 };
    static MANAGER = { label: 'MANAGER', limit: 10000 };
    static PREMIUM = { label: 'PREMIUM', limit: 100000 };
    static TEACHER = { label: 'TEACHER', limit: 10000 };
    static IL_TEACHER = { label: 'IL_TEACHER', limit: 100000 };
    static US_TEACHER = { label: 'US_TEACHER', limit: 100000 };
    static YARDEN = { label: 'YARDEN', limit: 10000 };
    static LINOY = { label: 'LINOY', limit: 10000 };
    static STUDENT = { label: 'STUDENT', limit: 10000 };
    static ENTERPRISE = { label: 'ENTERPRISE', limit: 10000 };
    static PARENT = { label: 'PARENT', limit: 10000 };
    static PERSONAL = { label: 'PERSONAL', limit: 10000 };
    static premiumRoles = [ROLES.BASIC.label, ROLES.PRO.label, ROLES.PREMIUM.label, ROLES.UNLIMITED.label]
    static subscriptionRoles = [ROLES.FREE.label, ROLES.BASIC.label, ROLES.PRO.label, ROLES.PREMIUM.label]
    static specialRoles = [ROLES.IL_TEACHER.label, ROLES.STUDENT.label, ROLES.YARDEN.label, ROLES.LINOY.label]
    static teacherRoles = [ROLES.IL_TEACHER.label, ROLES.US_TEACHER.label, ROLES.TEACHER.label]
  
  static getMaxLimit(rolesArray) {
    if (!rolesArray || !Array.isArray(rolesArray) || rolesArray.length === 0) {
      console.error('Invalid roles. Please provide a non-empty array of roles.');
      return ROLES.FREE.limit
    }

    const maxLimit = rolesArray.reduce((max, role) => {
      const roleLimit = ROLES[role]?.limit || 0;
      return Math.max(max, roleLimit);
    }, 0);

    return maxLimit;
  }

  /**
   * @param {Array} roles 
   * @returns {boolean}
   */
  static isPremiumRole(roles) {
    if (roles?.includes(ROLES.FREE.label))
      return false;

    return roles?.some(role => this.premiumRoles.includes(role));
  }

  static canUploadPastTests = (roles, selectedRole) => {
    if (!roles || !Array.isArray(roles) || roles.length === 0) {
      return false;
    }
    else if (!selectedRole) {
      return false;
    }
    return roles.includes(ROLES.US_TEACHER.label) || roles.includes(ROLES.TEACHER.label) || roles.includes(ROLES.IL_TEACHER.label) || selectedRole === ROLES.ENTERPRISE.label || roles.includes(ROLES.PREMIUM.label) || roles.includes(ROLES.PRO.label) || selectedRole === ROLES.TEACHER.label;
  }
  /**
   * Checks if a user has a special role.
   * @param {Array} roles - The roles of the user.
   * @returns {boolean} - True if the user has a special role, false otherwise.
   */
  static hasSpecialRole(roles) {
    return roles?.some(role => ROLES.specialRoles.includes(role));
  }
}

import { LocalLibrary, School, Person, Business, ChildCare, PersonPin } from '@mui/icons-material';
import { colors } from './customTheme';

const iconSize = '3rem';

export const getOnboardingRoles = (languageData) => {
  return [
    {
      _id: '1',
      role: ROLES.TEACHER.label,
      title: languageData?.onboarding?.role_step?.roles?.teacher_title || 'Teacher',
      subtitle: languageData?.onboarding?.role_step?.roles?.teacher_subtitle || 'Create exams and assignments, auto-grade efficiently, and gain insights into student performance.',
      icon: <School sx={{ color: colors.light, fontSize: iconSize }} />
    },
    // {
    //   _id: '2',
    //   role: ROLES.TEACHER.label,
    //   title: languageData?.onboarding?.role_step?.roles?.lecturer_title || 'Lecturer',
    //   subtitle: languageData?.onboarding?.role_step?.roles?.lecturer_subtitle || 'Integrate to Moodle, auto-grade and create assignments efficiently, gain insights into academic performance.',
    //   icon: <LocalLibrary />
    // },
    {
      _id: '3',
      role: ROLES.STUDENT.label,
      title: languageData?.onboarding?.role_step?.roles?.student_title || 'Student',
      subtitle: languageData?.onboarding?.role_step?.roles?.student_subtitle || 'Enhance your skills by creating exams and receiving automated feedback on submissions.',
      icon: <PersonPin sx={{ color: colors.light, fontSize: iconSize }} />
    },
    {
      _id: '4',
      role: ROLES.ENTERPRISE.label,
      title: languageData?.onboarding?.role_step?.roles?.enterprise_title || 'Enterprise',
      subtitle: languageData?.onboarding?.role_step?.roles?.enterprise_subtitle || 'Develop and manage training programs for employees with automated assessments and insights.',
      icon: <Business sx={{ color: colors.light, fontSize: iconSize }} />
    }
    // too many options:
    // { 
    //   _id: '5', 
    //   role: ROLES.PARENT.label, 
    //   title: languageData?.onboarding?.role_step?.roles?.parent_title || 'Parent', 
    //   subtitle: languageData?.onboarding?.role_step?.roles?.parent_subtitle || 'Generate AI reports for your children and monitor their progress and performance.', 
    //   icon: <ChildCare /> 
    // },
    // { 
    //   _id: '6', 
    //   role: ROLES.PERSONAL.label, 
    //   title: languageData?.onboarding?.role_step?.roles?.personal_title || 'Personal', 
    //   subtitle: languageData?.onboarding?.role_step?.roles?.personal_subtitle || 'Create exams on any topic or find inspiration for your assessments.', 
    //   icon: <Person /> 
    // },
  ];
};