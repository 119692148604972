import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, IntegrationInstructions, Settings } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const MoodleIntegration = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <IntegrationInstructions color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography
                                    variant="h3"
                                    sx={{ flexGrow: 1, fontSize: '35px' }}
                                    component="h1"
                                    aria-label="Seamless Integration with Moodle: Enhance Your Teaching Experience"
                                >
                                    Seamless Integration with Moodle: Enhance Your Teaching Experience
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Our platform offers robust integration with Moodle, empowering educators to create, manage, and assess tests effortlessly. With our free AI-powered tools, you can streamline the test creation process, automate grading, and enhance student engagement significantly. This integration is essential for teachers looking to optimize their workflow, improve student outcomes, and provide personalized learning experiences. By utilizing our comprehensive suite of features, educators can take full advantage of Moodle's capabilities while enriching their teaching methodologies.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                The integration of our platform with Moodle facilitates a seamless workflow, allowing you to access a plethora of resources and tools all in one place. This means you can spend less time managing administrative tasks and more time focusing on what really matters: your students. Our AI-driven assessment tools are designed to simplify the complexities of modern education, providing you with the ability to create custom tests that are not only aligned with curriculum standards but also tailored to meet the diverse needs of your students.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    variant="h3"
                                    component="h2"
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Key Benefits of Moodle Integration
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <IntegrationInstructions color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Streamlined Test Management
                                        </Typography>
                                    </Box>
                                    Our integration allows teachers to create and manage tests directly within Moodle, providing a unified experience for assessment and feedback. With intuitive interfaces and easy navigation, you can design and distribute tests effortlessly, ensuring that your assessment strategies align with your educational goals. This streamlined management process enhances your productivity, allowing you to focus on teaching while we handle the intricacies of exam administration.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Automated Grading and Feedback
                                        </Typography>
                                    </Box>
                                    Save time with our AI-driven grading system, which provides instant feedback on student performance. By automating the grading process, our platform not only ensures consistent and objective evaluation but also frees up educators to concentrate on interactive teaching and personalized student support. Our system analyzes student responses to deliver precise feedback that helps students understand their strengths and areas for improvement, fostering a more conducive learning environment.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Settings color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Customizable Assessments
                                        </Typography>
                                    </Box>
                                    Tailor assessments to meet the unique needs of your students with our flexible test creation tools. Our platform allows for the incorporation of various question types, including multiple-choice, short answer, and essay questions, enabling educators to design comprehensive assessments that gauge student understanding effectively. This adaptability is crucial in addressing different learning styles and ensuring that each student can demonstrate their knowledge in a way that suits them best.
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Full Width Column for Call to Action */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                component="h2"
                                sx={{ fontSize: '30px', mt: 2 }}
                            >
                                How Our AI-Powered Tools Enhance Your Moodle Experience
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Integrating our assessment platform with Moodle not only simplifies the management of educational tasks but also enhances the overall teaching and learning experience. Our AI-powered tools analyze vast amounts of data to provide actionable insights that help educators make informed decisions. By leveraging these insights, teachers can refine their instructional methods and strategies, ensuring that they meet the evolving needs of their students.
                            </Typography>
                            <Typography
                                variant="h4"
                                component="h2"
                                sx={{ fontSize: '30px', mt: 2 }}
                            >
                                Get Started with Moodle Integration
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Integrating our assessment platform with Moodle is simple and efficient. Follow our comprehensive step-by-step guide to set up and start creating assessments that enhance learning outcomes. With detailed tutorials and dedicated support, we ensure that you can navigate the integration process seamlessly. Join thousands of educators who have transformed their teaching experience with our tools and see the difference it can make in your educational practices.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default MoodleIntegration;
