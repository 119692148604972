// DashboardContext.js
import React, { createContext, useContext, useState } from 'react';
import { getAllUsers, getAllClicks, getAllLogs, getDailyAnalytics } from "../../requests/dashboard";
import { useUser } from '../../hooks/useUser';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [dashboardData, setDashboardData] = useState(null);
    const { isAdmin } = useUser()

    function updateUsers(users) {
        setDashboardData(prevD => ({ ...prevD, users: users }))
    }

    async function fetchDashboardData() {
        console.time('fetch dashboard data')
        if (dashboardData) return Promise.resolve(dashboardData)
        return isAdmin().then(result => {
            if (result) {
                fetchUsers()
                fetchDailyAnalytics()
                fetchLogs()
                fetchClicks()
            } else {
                throw Error('Unauthorized')
            }
        })
    }

    async function fetchUsers() {
        return getAllUsers().then(usersResult => {
            console.timeEnd('fetch dashboard data')
            if (usersResult) {
                const { users } = usersResult
                users?.forEach(user => {
                    // Check if 'is_verified' exists in 'user' object
                    user.is_verified = Boolean(user.is_verified);
                })
                setDashboardData(prevData => prevData ? ({ ...prevData, users: users }) : { users: users })
            }
        })
    }
    async function fetchDailyAnalytics() {
        return getDailyAnalytics().then(analyticsResult => {
            if (analyticsResult) {
                const da = { dailyAnalytics: analyticsResult }
                setDashboardData(prevData => prevData ? ({ ...prevData, ...da }) : da)
            }
        })
    }
    async function fetchClicks() {
        return getAllClicks().then(clicksResult => {
            if (clicksResult)
                setDashboardData(prevData => prevData ? ({ ...prevData, clicks: clicksResult }) : { clicks: clicksResult })
        })
    }
    async function fetchLogs() {
        return getAllLogs().then(logsResult => {
            if (logsResult) {
                setDashboardData(prevData => prevData ? ({ ...prevData, logs: logsResult }) : { logs: logsResult })
            }
        })
    }


    return (
        <DashboardContext.Provider value={{ dashboardData, fetchDashboardData, fetchClicks, fetchDailyAnalytics, fetchLogs, fetchUsers, updateUsers }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => useContext(DashboardContext);
