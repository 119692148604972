import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Group, Comment, Feedback } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const ClassInteractionsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Class Interactions">
                                    Enhance Class Interactions with AI-Powered Tools - Coming Soon
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                In today's fast-paced educational landscape, leveraging artificial intelligence tools is essential for keeping students engaged and motivated. Our platform aims to revolutionize classroom interactions, enhancing the overall learning experience through better communication, collaboration, and engagement. Imagine an educational environment where AI assists in managing the flow of information, providing real-time feedback, and creating a more interactive classroom. With these powerful tools, educators can significantly elevate the quality of education delivered to students.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our innovative platform, educators can seamlessly create dynamic assessments and interactive activities tailored to various educational settings. This not only simplifies classroom management but also allows teachers to focus on what matters most—enhancing student engagement and improving learning outcomes. Our platform is designed to integrate smoothly with existing workflows, making it easier to manage tests, assignments, and student interactions without overwhelming educators with additional tasks. By incorporating these advanced features, we empower teachers to foster an environment of continuous learning and curiosity.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our free features allow educators to explore the platform without commitment, ensuring they can fully experience the benefits of AI-enhanced education. From tracking student progress to facilitating real-time discussions, our tools provide a versatile solution to modern classroom challenges. Whether you are interested in boosting participation, streamlining grading processes, or creating engaging content for your lessons, our platform has everything you need to foster a successful learning environment that caters to diverse learning styles.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    Why Choose Our Platform for Interactive Class Management?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Group color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Collaborative Learning</Typography>
                                    </Box>
                                    Our platform promotes collaborative learning by facilitating interactions among students through innovative AI tools. Students can work together on projects, share ideas, and engage in meaningful discussions, significantly boosting engagement and enhancing critical thinking skills. By encouraging collaboration, our platform helps students develop essential interpersonal skills that are vital in today's interconnected world.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Comment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Interactive Discussions and Real-Time Feedback</Typography>
                                    </Box>
                                    Our platform enhances discussions and feedback through AI-powered tools that facilitate live polls, group discussions, and immediate responses to student inquiries. This immediate interaction keeps students engaged and informed about their performance, enabling continuous improvement in their learning journeys. Teachers can use these insights to adjust their teaching strategies and better meet the needs of their students.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Instant AI-Driven Feedback for Continuous Improvement</Typography>
                                    </Box>
                                    Our feedback system provides instant, actionable insights into student performance. By identifying areas needing support, the platform helps students understand their strengths and weaknesses, promoting academic success. This proactive approach not only improves student confidence but also enhances overall academic performance by guiding students toward the resources they need to succeed.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                How to Leverage Our AI Tools for Effective Class Interactions
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Integrating our AI-powered tools into your classroom is simple and intuitive. These tools enable you to manage tests, assignments, and discussions efficiently, creating a dynamic learning environment that encourages student participation. With our platform, educators can enhance student engagement while streamlining administrative tasks, supporting student success across various educational contexts. By utilizing AI in your classroom, you can transform traditional teaching methods into innovative learning experiences that resonate with today’s students.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ClassInteractionsUseCase;
