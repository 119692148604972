import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Quiz, Assessment, Speed, BarChart } from '@mui/icons-material';
import QuizzesAssessmentsImg from '../../../assets/features/quiz.png'; // Placeholder image
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const QuizzesAssessmentsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Quiz color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Advanced Quiz and Assessment Maker with AI-Powered Tools for Effective Evaluation and Insights">
                                    Advanced Quiz and Assessment Maker: AI-Powered Tools for Effective Evaluation and Insights
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Transform your educational evaluations with our advanced AI-powered quiz and assessment tools, meticulously designed to enhance every aspect of your assessment process. Our platform offers a comprehensive suite of features aimed at streamlining the creation, management, and analysis of quizzes and assessments, making it an invaluable asset for educators, administrators, and educational institutions seeking to achieve superior accuracy and efficiency in their evaluations. Whether you aim to craft tailored quizzes for diverse learning objectives or seek to derive deep, actionable insights from assessment data, our platform provides an all-encompassing solution to meet and exceed your educational goals. In today's market, the integration of AI is essential, ensuring you stay ahead of the competition.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our sophisticated quiz maker empowers educators to construct an extensive and diverse question bank, customize quizzes to address specific learning outcomes, and deliver engaging assessments that captivate and challenge students. By integrating automation and advanced analytics, you can effortlessly track student progress, evaluate performance trends, and make informed, data-driven decisions to refine teaching strategies and optimize learning outcomes. With our AI-driven tools, you are equipped to enhance educational effectiveness and foster a more impactful learning experience. Additionally, you can create free quizzes and assessments to broaden your reach and maximize exposure in the education sector.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Opt for Our AI-Powered Platform for Quizzes and Assessments? Discover the Comprehensive Advantages
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Extensive Question Bank for Customized Assessments</Typography>
                                    </Box>
                                    Leverage our platform's extensive library of pre-built questions and the capability to create custom quizzes tailored specifically to your curriculum and educational objectives. Our advanced quiz maker ensures that you have a comprehensive range of questions to effectively evaluate student understanding across various subjects and topics. This extensive question bank enables educators to design assessments that accurately measure student learning and provide valuable insights into their academic progress. Our platform also includes test features that are crucial for educators looking to enhance their assessment capabilities.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Speed color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Streamlined and Rapid Test Setup for Efficiency</Typography>
                                    </Box>
                                    Our user-friendly interface facilitates the quick and easy setup of quizzes and assessments, significantly reducing the time required to create and deploy evaluations. The platform's automation features streamline the entire process, making it straightforward and efficient. This ease of use allows educators to focus more on content quality and instructional strategies rather than administrative tasks, thereby enhancing overall productivity. Experience the benefits of free testing tools that optimize your quiz-making process.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <BarChart color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Detailed Analytics and Reporting for In-Depth Insights</Typography>
                                    </Box>
                                    Obtain valuable insights into student performance through our platform's comprehensive analytics and reporting features. Track assessment results, analyze performance trends, and identify areas for improvement with detailed reports that inform instructional strategies and enhance educational outcomes. By utilizing these analytics, educators can make data-driven decisions to refine assessment approaches, improve teaching methods, and foster a more effective learning environment. This emphasis on analytics is vital for institutions that aim to maximize the potential of their educational offerings.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Maximize the Potential of Our AI-Powered Platform for Optimal Quiz and Assessment Management
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Unlock the full potential of our AI-powered platform to transform the creation and management of quizzes and assessments. Our tools facilitate the seamless design of quizzes, automate grading processes, and provide in-depth analytics to enhance the overall educational experience. Educators and institutions can leverage our platform to deliver effective assessments that drive student achievement and contribute to a higher standard of educational excellence. Embrace the future of assessment management with our innovative solutions and elevate your educational practices to new heights. With our platform, the ability to create free assessments allows for greater engagement and accessibility in education.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={QuizzesAssessmentsImg} alt="Advanced Quiz Maker and Assessment Tools" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default QuizzesAssessmentsUseCase;
