import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Define styled components
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none', // Remove elevation or box shadow
    border: `1px solid ${theme.palette.divider}`, // Add border
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '& .MuiAccordionSummary-content': {
        fontSize: theme.typography.body1.fontSize, // Match font size
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize, // Match font size
}));

const faqs = [
    {
        question: 'כיצד StudyWise יוצר מבחנים?',
        answer:
            'StudyWise משתמש בטכנולוגיית בינה מלאכותית ליצירת מבחנים איכותיים תוך שניות. ניתן להכניס כל טקסט או קובץ, כגון PDF או מצגות PowerPoint, והבינה המלאכותית תיצור באופן אוטומטי שאלות מבחן, מה שמייעל את תהליך יצירת המבחנים.',
    },
    {
        question: 'האם ניתן להוריד את המבחנים כקובצי PDF?',
        answer:
            'כן, StudyWise מאפשר להוריד בקלות את המבחנים שנוצרו באמצעות הבינה המלאכותית כקובצי PDF. כך ניתן להשתמש במבחנים גם במצב לא מקוון או לשתף אותם עם תלמידים ועמיתים.',
    },
    {
        question: 'האם StudyWise תומך בבדיקה אוטומטית?',
        answer:
            'כן, StudyWise תומך בבדיקה אוטומטית לשאלות רב-ברירה ולשאלות פתוחות. המערכת מעריכה את התשובות באופן מיידי, חוסכת זמן למורים ומספקת משוב מהיר לתלמידים.',
    },
    {
        question: 'כיצד ניתן לשלב את המבחנים עם Google Forms?',
        answer:
            'StudyWise משתלב בצורה חלקה עם Google Forms, ומאפשר להעביר את המבחנים שנוצרו ישירות לטפסים של Google Forms. זה מתאים במיוחד למורים המעדיפים להשתמש ב-Google Forms לניהול מבחנים ומעקב אחר תשובות.',
    },
    {
        question: 'מי יכול להשתמש ב-StudyWise?',
        answer:
            'StudyWise מיועד למורים, מדריכים ומוסדות במגוון תחומים. הוא מתאים לבתי ספר, אוניברסיטאות, מדריכים עסקיים וכל מי שזקוק לדרך מהירה ויעילה ליצירת ובדיקת מבחנים.',
    },
    {
        question: 'מדוע כדאי להשתמש ב-StudyWise?',
        answer:
            'StudyWise מפשט את תהליך יצירת המבחנים באמצעות בינה מלאכותית ליצירת מבחנים במהירות. עם תכונות כמו בדיקה אוטומטית, הורדות PDF, ניתוחים ושילוב עם Google Forms, StudyWise חוסך זמן תוך שמירה על תוכן מבחנים איכותי.',
    },
    {
        question: 'האם יש הגבלות על סוגי הקבצים שניתן להעלות?',
        answer:
            'לא, StudyWise תומך במגוון רחב של סוגי קבצים, כולל מסמכי טקסט, PDF ומצגות PowerPoint. גמישות זו מאפשרת להשתמש בחומרים קיימים ליצירת מבחנים בקלות.',
    },
    {
        question: 'האם StudyWise מספק ניתוחים על ביצועי המבחנים?',
        answer:
            'כן, StudyWise מציע ניתוחים מפורטים על ביצועי המבחנים. המערכת מייצרת תובנות לגבי תשובות התלמידים, ומאפשרת למורים לזהות תחומי שיפור ולעקוב אחר התקדמות הכיתה בזמן אמת.',
    },
    {
        question: 'האם StudyWise יכול לטפל בכיתות גדולות או במבחנים מרובים?',
        answer:
            'בהחלט! StudyWise מיועד להתמודד עם כיתות גדולות ומבחנים מרובים בו זמנית. בין אם אתה מנהל מאות תלמידים או כמה מבחנים במקביל, הפלטפורמה מבטיחה ביצועים חלקים.',
    },
    {
        question: 'כיצד StudyWise מבטיח פרטיות וביטחון מידע?',
        answer:
            'StudyWise שם דגש על אבטחת המידע שלך. הפלטפורמה עומדת במדיניות מחמירה של פרטיות מידע ופועלת בהתאם לתקנות הגנת המידע בחינוך, כך שמידע רגיש נשמר באופן מאובטח.',
    },
    {
        question: 'האם StudyWise תומך ביצירת מבחנים משותפת?',
        answer:
            'כן, StudyWise מאפשר למספר מורים או חברי צוות לשתף פעולה ביצירת מבחנים. תכונה זו מתאימה במיוחד לבתי ספר וארגונים שמסתמכים על עבודה משותפת ליצירת מבחנים מקיפים.',
    },
    {
        question: 'האם תלמידים יכולים ליצור מבחנים לתרגול עצמי ב-StudyWise?',
        answer:
            'כן, StudyWise מאפשר לתלמידים ליצור מבחנים לתרגול עצמי. תכונה זו מסייעת לתלמידים להתכונן למבחנים באופן עצמאי ומציעה להם חווית למידה מותאמת אישית.',
    },
    {
        question: 'האם יש תמיכה במובייל ל-StudyWise?',
        answer:
            'כן, StudyWise תומך במכשירים ניידים, ומאפשר למורים ותלמידים ליצור, לגשת ולבצע מבחנים בקלות דרך מכשירי המובייל.',
    },
    {
        question: 'כיצד התמחור עובד, והאם ניתן לבטל בכל זמן?',
        answer:
            'StudyWise מציע תכניות תמחור גמישות בהתאם לצרכי בית הספר או הארגון שלך. ניתן לבטל את המנוי בכל זמן.',
    },
    {
        question: 'כיצד בתי ספר או ארגונים יכולים להצטרף ל-StudyWise?',
        answer:
            'בתי ספר וארגונים יכולים ליצור קשר איתנו ולהירשם ל-StudyWise דרך האתר שלנו. לאחר ההרשמה, המנהלים יקבלו גישה לניסיון חינם על מנת לחקור את כל התכונות לפני התחייבות לתכנית. אנו גם מספקים תמיכה בתהליך ההצטרפות.',
    },
    {
        question: 'האם ניתן לשלב את StudyWise עם המערכות הקיימות של בית הספר שלי?',
        answer:
            'כן, StudyWise ניתן לשילוב עם רוב מערכות ניהול בתי הספר, ומספק מעבר חלק בין יצירת מבחנים, בדיקה וניהול אדמיניסטרטיבי. זה מסייע לייעל את זרימת העבודה החינוכית.',
    },
];

const HebrewFAQ = () => {
    return (
        <Container id="faq" style={{ padding: '64px 24px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
            <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                יש לך שאלות?
            </Typography>
            {faqs.map((faq, index) => (
                <CustomAccordion key={index}>
                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-panel-${index}`} id={`faq-header-${index}`}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </CustomAccordionDetails>
                </CustomAccordion>
            ))}
        </Container>
    );
};

export default HebrewFAQ;
