import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, Typography, Button } from '@mui/material';
import subjects from '../../utils/subjectsData';
import { useLanguage } from '../../contexts/languageContext';

const HubApproveDialog = ({ open, onClose, onApprove }) => {
    const { languageData, isRTL } = useLanguage();

    const handleApprove = () => {
        onApprove();
    };

    return (
        <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'}>
            <DialogTitle>{languageData?.hub.dialog.title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {languageData?.hub.dialog.subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {languageData?.hub.dialog.cancel}
                </Button>
                <Button onClick={handleApprove} variant='contained' color="primary">
                    {languageData?.hub.dialog.approve}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HubApproveDialog;
