import React from 'react';
import Chip from '@mui/material/Chip';

const NoAnswerProvidedChip = ({ text }) => {
    return (
        <Chip
            label={text}
            color="error"
            sx={{
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                color: '#8B0000',
                padding: '8px',
                borderRadius: '8px',
                marginLeft: '8px'
            }}
        />
    );
};

export default NoAnswerProvidedChip;