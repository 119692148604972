import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Define styled components
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none', // Remove elevation or box shadow
    border: `1px solid ${theme.palette.divider}`, // Add border
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '& .MuiAccordionSummary-content': {
        fontSize: theme.typography.body1.fontSize, // Match font size
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize, // Match font size
}));

const faqs = [
    {
        question: 'How does StudyWise generate exams?',
        answer:
            'StudyWise uses AI technology to generate high-quality exams in seconds. You can input any text or file, such as PDFs and PowerPoint presentations, and the AI will automatically create exam questions, offering a streamlined exam creation process.',
    },
    {
        question: 'Can I download exams as a PDF?',
        answer:
            'Yes, StudyWise allows you to easily download your AI-generated exams as PDF files. This ensures that exams can be used offline or shared with students and colleagues as needed.',
    },
    {
        question: 'Does StudyWise support automatic grading?',
        answer:
            'Yes, StudyWise supports automatic grading for both multiple-choice and open-ended questions. The system instantly evaluates responses, saving time for educators and providing immediate feedback to students.',
    },
    {
        question: 'How can I integrate exams with Google Forms?',
        answer:
            'StudyWise integrates seamlessly with Google Forms, enabling you to directly transfer AI-generated exams into Google Forms quizzes. This is ideal for educators who prefer using Google Forms to manage their quizzes and responses.',
    },
    {
        question: 'Who can use StudyWise?',
        answer:
            'StudyWise is designed for educators, trainers, and institutions across various sectors. It is ideal for schools, universities, corporate trainers, and anyone in need of a faster, more efficient way to create and assess exams.',
    },
    {
        question: 'Why should I use StudyWise?',
        answer:
            'StudyWise simplifies the exam creation process by using AI to generate exams quickly. With features like automatic grading, PDF downloads, analytics, and Google Forms integration, StudyWise saves time while maintaining high-quality exam content.',
    },
    {
        question: 'Are there any restrictions on the types of files I can input?',
        answer:
            'No, StudyWise supports a wide variety of file types, including text documents, PDFs, and PowerPoint presentations. This flexibility ensures you can use existing materials to create exams effortlessly.',
    },
    {
        question: 'Does StudyWise provide analytics on exam performance?',
        answer:
            'Yes, StudyWise offers detailed analytics on exam performance. The system generates insights into student responses, allowing educators to identify areas for improvement and track overall class progress in real time.',
    },
    {
        question: 'Can StudyWise handle large classes or multiple exams?',
        answer:
            'Absolutely! StudyWise is built to handle large-scale classes and multiple exams simultaneously. Whether you are managing hundreds of students or administering several tests at once, the platform ensures smooth performance.',
    },
    {
        question: 'How does StudyWise ensure data privacy and security?',
        answer:
            'StudyWise prioritizes the security of your data. The platform adheres to strict data privacy policies and complies with educational data protection regulations, ensuring that sensitive information is safeguarded.',
    },
    {
        question: 'Does StudyWise support collaborative exam creation?',
        answer:
            'Yes, StudyWise allows multiple educators or team members to collaborate on exam creation. This feature is ideal for schools and organizations that rely on team input for building comprehensive assessments.',
    },
    {
        question: 'Can students create their own practice exams on StudyWise?',
        answer:
            'Yes, StudyWise allows students to create their own practice exams. This feature helps them review and prepare for exams independently, offering them a personalized learning experience.',
    },
    {
        question: 'Is there mobile support for StudyWise?',
        answer:
            'Yes, StudyWise offers mobile-friendly support, allowing educators and students to create, access, and complete exams on mobile devices with ease.',
    },
    {
        question: 'How does pricing work, and can I cancel at any time?',
        answer:
            'StudyWise offers flexible pricing plans based on the needs of your school or organization. You can cancel your subscription at any time with no penalties.',
    },
    {
        question: 'How can schools or organizations join StudyWise?',
        answer:
            'Schools and organizations can contact us to sign up for StudyWise through our website. After registering, administrators will have access to a free trial to explore all features before committing to a plan. We also provide onboarding support for a smooth transition.',
    },
    {
        question: 'Can StudyWise be integrated into my school’s existing systems?',
        answer:
            'Yes, StudyWise can be integrated with most school management systems, providing a seamless transition between exam creation, grading, and school administration. This helps streamline educational workflows.',
    },
];


const FAQ = () => {
    return (
        <Container id="faq" style={{ padding: '64px 24px', margin: 'auto', fontFamily: 'Arial, sans-serif', 'scroll-margin-top': '65px' }}>
            <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                Any Questions?
            </Typography>
            {faqs.map((faq, index) => (
                <CustomAccordion key={index}>
                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-panel-${index}`} id={`faq-header-${index}`}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </CustomAccordionDetails>
                </CustomAccordion>
            ))}
        </Container>
    );
};

export default FAQ;
