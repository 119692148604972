import api from "./api";
import { processResponsePromise } from './utils';

export function getExamSkills() {
    return processResponsePromise(api.get('/skills'))
}

export function requestAddSkill(newSkill) {
    return processResponsePromise(api.post('/skills', newSkill))
}

export function requestEditSkill(id, editingData) {
    return processResponsePromise(api.put(`/skills/${id}`, editingData))
}

export function requestDeleteSkill(id) {
    return processResponsePromise(api.delete(`/skills/${id}`))
}
