import React, { useState, useMemo } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import CustomLatex from '../../components/CustomLatex';
import { shuffleArray } from '../../utils';

// todo: change color and align text 
const MultipleQuestion = ({ questionId, title, options, onAnswer, savedAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(savedAnswer);
  const { isRTL } = useLanguage();

  const shuffledOptions = useMemo(() => shuffleArray(options), [options]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onAnswer(questionId, option);
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder' }}>
        <CustomLatex>{title}</CustomLatex>
      </Typography>
      <Grid container direction="column" spacing={1}>
        {shuffledOptions.map((option, index) => (
          <Grid item key={index}>
            <Button
              sx={{
                textAlign: isRTL ? 'right' : 'left',
                minWidth: '60vw',
                justifyContent: 'flex-start',
              }}
              variant={selectedOption === option ? "outlined" : "text"}
              onClick={() => handleOptionClick(option)}
            >
              <span style={{ marginBottom: 'auto', margin: `0 ${isRTL ? '4px' : '16px'} 0 ${isRTL ? '16px' : 0}` }}>{index + 1}.</span>
              <CustomLatex>{option}</CustomLatex>
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MultipleQuestion;
