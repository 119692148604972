import React from 'react'; 
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Chat, Grade, AssignmentTurnedIn } from '@mui/icons-material';
import LandingHeader from '../LandingHeader';
import { isMobile } from '../../../utils';

const FeedbackGrades = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="flex-start">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Chat color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" component="h1" sx={{ flexGrow: 1, fontSize: '35px' }} aria-label="AI-Powered Feedback and Grades for Online Assessments">
                                    Transform Your Online Assessments with AI-Driven Feedback and Comprehensive Grading Solutions
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                In the rapidly evolving world of online education, the need for effective feedback mechanisms is more crucial than ever. Our innovative AI-powered feedback and grading system revolutionizes how assessments are conducted, ensuring that both educators and students can achieve greater success. By leveraging advanced AI algorithms and state-of-the-art analytics, we provide detailed grades and constructive feedback tailored to each student's needs. This integration not only streamlines the assessment process but also deepens the understanding of student performance and progress, making it an essential tool in modern education.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Our platform is meticulously designed to meet the evolving demands of online learning environments. We offer personalized feedback that addresses individual learning gaps while generating comprehensive grades that reflect a student's true performance. The advanced analytics capabilities built into our system enable educators to conduct thorough analyses of student data, leading to data-driven decision-making that enhances the overall effectiveness of the educational process. Experience the future of online assessments with our AI-driven solutions, bringing precision, efficiency, and depth to your feedback and grading practices.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    Why Choose AI for Feedback and Grading?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Driven Constructive Feedback</Typography>
                                    </Box>
                                    Our AI-driven feedback system delivers personalized and actionable insights into student performance. By analyzing response patterns and identifying common errors, our AI generates constructive feedback that empowers students to understand their mistakes, learn effectively, and enhance their skills. This personalized approach enriches the learning experience and supports students in achieving their academic goals, addressing unique learning needs and challenges along the way.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Grade color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Grades and Detailed Analytics</Typography>
                                    </Box>
                                    Our platform provides not only detailed grades but also comprehensive analytics that furnish educators with a clear understanding of student performance. The AI-powered grading system ensures both accuracy and consistency, while the analytics tools reveal trends, strengths, and areas requiring improvement. This wealth of data empowers educators to tailor their teaching strategies, provide targeted support, and make informed decisions that enhance student outcomes and the overall effectiveness of the educational experience.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Continuous Monitoring and Tracking of Student Performance</Typography>
                                    </Box>
                                    Our platform empowers educators to continuously monitor and track student performance through AI-driven insights. By analyzing performance data over time, educators can identify trends, assess progress, and adjust teaching methods accordingly. This proactive capability ensures timely and effective interventions, providing a data-driven approach to enhancing student achievement and supporting ongoing educational development.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    Steps to Integrate AI for Effective Feedback and Grading in Online Assessments
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    To harness the full potential of our AI-powered feedback and grading system, it is crucial to integrate it seamlessly into your online assessment process. Start by utilizing our sophisticated tools to generate personalized feedback that aligns precisely with each student's performance. Employ our grading system to deliver accurate and detailed grades. Furthermore, leverage the robust analytics features to gain comprehensive insights into student progress, allowing you to adjust your teaching strategies effectively. By incorporating these AI-driven tools into your assessments, you enhance the overall educational experience, streamline grading processes, and support student success with invaluable data-driven insights and feedback.
                                </Typography>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FeedbackGrades;
