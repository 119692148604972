import PropTypes from 'prop-types';
import { Grid } from "@mui/material"
import AnalyticNumeric from "./AnalyticsNumeric"

const DashboardAnalytics = ({ dailyAnalytics }) => {
    return (
        <Grid container>
            {/* isLoss --> arrow down icon */}
            {/* extra --> in compare to yesterday */}
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <AnalyticNumeric
                    title="Users"
                    count={dailyAnalytics.usersToday}
                    percentage={parseInt((dailyAnalytics.usersToday / dailyAnalytics.usersYesterday) * 100)}
                    extra={dailyAnalytics.usersToday - dailyAnalytics.usersYesterday}
                    isLoss={dailyAnalytics.usersToday < dailyAnalytics.usersYesterday}
                />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <AnalyticNumeric
                    title="Exams Created"
                    count={dailyAnalytics.totalExamsToday}
                    percentage={parseInt((dailyAnalytics.totalExamsToday / dailyAnalytics.totalExamsYesterday) * 100)}
                    extra={dailyAnalytics.totalExamsToday - dailyAnalytics.totalExamsYesterday}
                    isLoss={dailyAnalytics.totalExamsToday < dailyAnalytics.totalExamsYesterday}
                />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <AnalyticNumeric
                    title="Exam Avg Duration"
                    count={dailyAnalytics.avgDurationToday}
                    percentage={parseInt((dailyAnalytics.avgDurationToday / dailyAnalytics.avgDurationYesterday) * 100)}
                    extra={dailyAnalytics.avgDurationToday - dailyAnalytics.avgDurationYesterday}
                    isLoss={dailyAnalytics.avgDurationToday < dailyAnalytics.avgDurationYesterday}
                    lossReversed
                />
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={2.4}>
                <AnalyticNumeric
                    title="Exam Avg Input Token Used"
                    count={dailyAnalytics.avgInputTokensToday}
                    percentage={parseInt((dailyAnalytics.avgInputTokensToday / dailyAnalytics.avgInputTokensYesterday) * 100)}
                    extra={dailyAnalytics.avgInputTokensToday - dailyAnalytics.avgInputTokensYesterday}
                    isLoss={dailyAnalytics.avgInputTokensToday < dailyAnalytics.avgInputTokensYesterday}
                    lossReversed
                />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2.4}>
                <AnalyticNumeric
                    title="Exam Avg Output Token Used"
                    count={dailyAnalytics.avgOutputTokensToday}
                    percentage={parseInt((dailyAnalytics.avgOutputTokensToday / dailyAnalytics.avgOutputTokensYesterday) * 100)}
                    extra={dailyAnalytics.avgOutputTokensToday - dailyAnalytics.avgOutputTokensYesterday}
                    isLoss={dailyAnalytics.avgOutputTokensToday < dailyAnalytics.avgOutputTokensYesterday}
                    lossReversed
                />
            </Grid> */}
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <AnalyticNumeric
                    title="Responses Created"
                    count={dailyAnalytics.totalResponsesToday}
                    percentage={parseInt((dailyAnalytics.totalResponsesToday / dailyAnalytics.totalResponsesYesterday) * 100)}
                    extra={dailyAnalytics.totalResponsesToday - dailyAnalytics.totalResponsesYesterday}
                    isLoss={dailyAnalytics.totalResponsesToday < dailyAnalytics.totalResponsesYesterday}
                />
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={2.4}>
                <AnalyticNumeric
                    title="Response Avg Input Tokens"
                    count={dailyAnalytics.avgResponsesInputTokensToday}
                    percentage={parseInt((dailyAnalytics.avgResponsesInputTokensToday / dailyAnalytics.avgResponsesInputTokensYesterday) * 100)}
                    extra={dailyAnalytics.avgResponsesInputTokensToday - dailyAnalytics.avgResponsesInputTokensYesterday}
                    isLoss={dailyAnalytics.avgResponsesInputTokensToday < dailyAnalytics.avgResponsesInputTokensYesterday}
                />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2.4}>
                <AnalyticNumeric
                    title="Response Avg Output Tokens"
                    count={dailyAnalytics.avgResponsesOutputTokensToday}
                    percentage={parseInt((dailyAnalytics.avgResponsesOutputTokensToday / dailyAnalytics.avgResponsesOutputTokensYesterday) * 100)}
                    extra={dailyAnalytics.avgResponsesOutputTokensToday - dailyAnalytics.avgResponsesOutputTokensYesterday}
                    isLoss={dailyAnalytics.avgResponsesOutputTokensToday < dailyAnalytics.avgResponsesOutputTokensYesterday}
                />
            </Grid> */}
        </Grid>
    )
}


export default DashboardAnalytics;

DashboardAnalytics.proptypes = {
    dailyAnalytics: PropTypes.shape({
        usersToday: PropTypes.number,
        usersYesterday: PropTypes.number,
        totalExamsToday: PropTypes.number,
        totalExamsYesterday: PropTypes.number,
        avgDurationToday: PropTypes.number,
        avgDurationYesterday: PropTypes.number,
        avgInputTokensToday: PropTypes.number,
        avgInputTokensYesterday: PropTypes.number,
        avgOutputTokensToday: PropTypes.number,
        avgOutputTokensYesterday: PropTypes.number,
        totalResponsesToday: PropTypes.number,
        totalResponsesYesterday: PropTypes.number,
        avgResponsesInputTokensToday: PropTypes.number,
        avgResponsesInputTokensYesterday: PropTypes.number,
        avgResponsesOutputTokensToday: PropTypes.number,
        avgResponsesOutputTokensYesterday: PropTypes.number,
    })
}