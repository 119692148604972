import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';

const countdownTime = 7; // Set countdown duration in seconds
const defaultLeavingNotice = `The system detected that you are trying to leave the exam. If you continue, the exam will be auto-submitted. You have ${countdownTime} seconds to return`;
const defaultContent = "This is a supervised exam. Leaving the exam tab will result in the end of the exam session.";

const SupervisionApprove = ({ open, onClose, onAccept, onLeft, leaving = false }) => {
    const { languageData, isRTL } = useLanguage();
    const supervisionTexts = languageData?.quiz?.supervision_approve_dialog;
    const [timeLeft, setTimeLeft] = useState(countdownTime);
    const timerIntervalRef = useRef(null);

    useEffect(() => {
        if (leaving && open)
            setTimeLeft(countdownTime); // Reset the countdown when the dialog opens for leaving
    }, [leaving, open]);

    useEffect(() => {
        if (leaving && open && timeLeft > 0) {
            timerIntervalRef.current = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);

            return () => clearInterval(timerIntervalRef.current);
        } else if (leaving && timeLeft === 0) {
            clearInterval(timerIntervalRef.current);
            setTimeLeft(prev => prev - 1);
            onLeft(); // Trigger the onLeft function when countdown reaches zero
        }
    }, [leaving, open, timeLeft, onLeft]);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick" || reason === "escapeKeyDown") return;
        onClose();
    };

    const handleClick = () => {
        onAccept();
        setTimeLeft(countdownTime); // Reset countdown if user clicks the button manually
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { textAlign: 'center', display: 'flex', alignItems: 'center', direction: isRTL ? 'rtl' : 'ltr' } }}
        >
            <DialogTitle>{supervisionTexts?.title ?? 'Supervised Exam Notice'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {leaving
                        ? (supervisionTexts?.leaving_notice ?? defaultLeavingNotice)
                        : (supervisionTexts?.content ?? defaultContent)
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClick} variant="contained" color="error" sx={{ minWidth: '120px' }}>
                    {leaving && timeLeft > 0
                        ? `${supervisionTexts?.button ?? "I understand"} (${timeLeft})`
                        : `${supervisionTexts?.button ?? "I understand"}`
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupervisionApprove;
