import React, { useState, useContext, useMemo } from 'react';
import { Avatar, Menu, MenuItem, ListItemIcon, Link, Divider, Box, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import { logout } from '../requests/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { EMPTY_STRING, links } from '../utils';
import { allowFocusStyle, colors } from '../utils/customTheme';
import { useLanguage } from '../contexts/languageContext';

export default function AvatarProfile({ }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { languageData, isHeb } = useLanguage()
    const isMoeIL = useMemo(() => userContext?.user?.email?.includes('.moe.il'), [userContext?.user?.email]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick(event);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout().then(() => {
            userContext.logout();
            if (isMoeIL)
                window.open(process.env.REACT_APP_IDM_MOE_IL_LOGOUT_URL + window.location.origin + '', '_self')
            else
                navigate('/');
        });
    };

    return (
        <div style={{ marginTop: 5 }}>
            <Box display="flex" alignItems="center" margin={1}>
                <Avatar
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    sx={{ cursor: 'pointer', ...allowFocusStyle, bgcolor: colors.secondary }}
                    tabIndex="0"
                >
                    {userContext?.user?.fullname?.substring(0, 1).toUpperCase() ?? EMPTY_STRING}
                </Avatar>
                <Box ml={1}>
                    <Typography variant="body2">{userContext?.user?.fullname ?? ''}</Typography>
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                tabIndex={0}
            >
                <MenuItem
                    component={Link}
                    href="/start"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                >
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    {languageData?.profile?.profile || 'Profile'}
                </MenuItem>
                {isMoeIL &&
                    <>
                        <Divider />
                        <MenuItem
                            component={Link}
                            href="https://apps2.education.gov.il/EduLogin/myaccount.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClose}
                        >
                            {/* Add moe.il icon */}
                            פרופיל משרד החינוך
                        </MenuItem>
                    </>
                }
                <Divider />
                {userContext.isPremium() &&
                    <MenuItem
                        component={Link}
                        href="https://exam-generator.lemonsqueezy.com/billing"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClose}
                    >
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        {languageData?.profile?.manage || 'Manage Subscription'}
                    </MenuItem>
                }
                {userContext.isPremium() && <Divider />}
                <MenuItem
                    component={Link}
                    href={links.BUG_FORM}
                    target="_blank"
                >
                    <ListItemIcon>
                        <ReportIcon fontSize="small" />
                    </ListItemIcon>
                    {languageData?.profile?.report_a_bug || 'Report a Bug'}
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    onClick={() => { handleClose(); handleLogout(); }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    {languageData?.profile?.logout || 'Logout'}
                </MenuItem>
            </Menu>
        </div >
    );
}
