import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useUser } from '../hooks/useUser';
import { colors } from '../utils/customTheme';

const styles = {
    dialogContentText: {
        textAlign: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
    },
    dialogActions: {
        justifyContent: 'center',
        gap: '20px'
    },
};

const LoginMoeILDialog = () => {
    const [open, setOpen] = useState(false);
    const { user } = useUser();

    const handleClose = () => {
        localStorage.setItem('hasSeenLoginMoeILDialog', 'true');
        setOpen(false);
    };

    const handleOk = () => {
        localStorage.setItem('hasSeenLoginMoeILDialog', 'true');
        handleClose();
    };

    useEffect(() => {
        if (user?.email?.includes('.moe.il')) {
            const hasSeenDialog = localStorage.getItem('hasSeenLoginMoeILDialog');
            if (!hasSeenDialog)
                setOpen(true);
        }
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ direction: 'rtl' }}>
            <DialogTitle id="form-dialog-title" style={styles.dialogTitle}>ברוכים הבאים!</DialogTitle>
            <DialogContent >
                <DialogContentText style={styles.dialogContentText}>
                    קיבלתם 3 מבחנים מתנה מאיתנו!
                </DialogContentText>
                <DialogContentText style={styles.dialogContentText}>
                    לכל שאלה, אנא פנו אלינו בכתובת: <a href="mailto:support@studywise.io">support@studywise.io</a>
                </DialogContentText>
                <DialogContentText style={styles.dialogContentText}>
                    לרכישת השירות פנו אלינו במייל או לחצו למעבר לחנות
                </DialogContentText>
            </DialogContent>
            <DialogActions style={styles.dialogActions}>
                <Button onClick={handleOk} color="primary" variant="contained"> תודה </Button>
                <Button color="secondary" variant="contained" onClick={() => window.open(window.location.origin + '/pricing', '_self')}> חנות </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoginMoeILDialog;