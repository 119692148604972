import React from 'react';
import { Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { duplicateExam } from '../requests/exam';
import useSnackBar from '../hooks/useSnackBar';
import useHistory from '../hooks/useHistory';
import { useLanguage } from '../contexts/languageContext';
import { SnackbarTypes } from '../utils/snackbarTypes';

const DuplicateExamButton = ({ examId, userId }) => {
    const { openSnackBar } = useSnackBar();
    const { languageData } = useLanguage();
    const historyContext = useHistory()

    const handleDuplicate = async () => {
        try {
            const newId = await duplicateExam(examId, userId);
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
            historyContext.addNewExamToHistory(newId)
        } catch (error) {
            openSnackBar(SnackbarTypes.ERROR.field);
            console.error('Error duplicating exam:', error);
        }
    };

    return (
        <Button size="small" startIcon={<FileCopyIcon />} onClick={handleDuplicate}>
            {languageData?.duplicate_btn_label || "Duplicate"}
        </Button>
    );
};

export default DuplicateExamButton;
