import { createContext, useContext, useMemo, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { languageCodes, useLanguage } from '../contexts/languageContext';

const snackbarContext = createContext()

export const useSnackBar = () => {
    const context = useContext(snackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

const defaultDuration = 5000;
export const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('ERROR');
    const [snackBarDuration, setSnackBarDuration] = useState(defaultDuration);
    const { language } = useLanguage();

    const openSnackBar = (type) => {
        if (!type || !SnackbarTypes[type]) return
        const { msg, hebrew_msg, arabic_msg, severity, duration } = SnackbarTypes[type];

        let currentLanguage = language
        if (currentLanguage === languageCodes.arabic) setMessage(arabic_msg);
        else if (currentLanguage === languageCodes.hebrew) setMessage(hebrew_msg);
        else setMessage(msg);
        setSeverity(severity);
        setSnackBarDuration(duration || defaultDuration);
        setOpen(true);
    };

    const closeSnackBar = () => {
        setOpen(false);
        setSnackBarDuration(defaultDuration); // Reset to default duration
    };

    const SnackBarComponent = useMemo(() => (
        <Snackbar
            open={open}
            autoHideDuration={snackBarDuration}
            onClose={closeSnackBar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ zIndex: 10000 }}
        >
            <Alert
                onClose={closeSnackBar}
                severity={severity}
                variant='filled'
            >
                {message.split('\n').map((line, index) => (
                    <Typography key={index} component="span">
                        {line}
                        <br />
                    </Typography>
                ))}
            </Alert>
        </Snackbar>
    ), [open, message, severity, snackBarDuration]);

    return (
        <snackbarContext.Provider value={{ openSnackBar, closeSnackBar }}>
            {children}
            {SnackBarComponent}
        </snackbarContext.Provider>
    );
};

export default useSnackBar;
