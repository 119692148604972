import React from "react";
import { Alert, TextField, Typography, Grid, Box } from "@mui/material";
import { useLanguage } from "../../contexts/languageContext";
import { getSeverityStyles } from "../../utils/severity";

const EditableAlert = ({
    title,
    value,
    onChange,
    placeholder,
    minRows = 2,
    maxRows = 10,
    severity = "info",
}) => {
    const { isRTL } = useLanguage();

    const { borderColor, backgroundColor } = getSeverityStyles(severity);

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Alert
                    variant="contained"
                    severity={severity}
                    icon={false}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        borderLeft: !isRTL ? `4px solid ${borderColor}` : "none",
                        borderRight: isRTL ? `4px solid ${borderColor}` : "none",
                        backgroundColor: backgroundColor,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bolder", flexGrow: 1 }}>
                            {title}
                        </Typography>
                    </Box>
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        margin="normal"
                        minRows={minRows}
                        maxRows={maxRows}
                        sx={{
                            backgroundColor: backgroundColor,
                        }}
                    />
                </Alert>
            </Grid>
        </Grid>
    );
};

export default EditableAlert;
