import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography, Button, Box } from '@mui/material';
import { useLanguage } from '../contexts/languageContext';
import { useUser } from '../hooks/useUser';
import ROLES from '../utils/roles';

const Feedback = () => {
  const { user } = useUser();
  const { isRTL, languageData } = useLanguage();
  const [open, setOpen] = useState(false);
  const feedbackFormLink = 'https://forms.gle/ykf9YY41K6pZnBvB8';

  const openFeedbackForm = () => {
    window.open(feedbackFormLink, '_blank');
    localStorage.setItem('feedbackGiven', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const feedbackGiven = localStorage.getItem('feedbackGiven');
    if (!feedbackGiven && user?.roles?.includes(ROLES.TEACHER.label)) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Dialog
      aria-label="feedback"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Typography variant="h6" gutterBottom>
            {languageData?.feedback?.title ?? 'Get a personalized offer for your school'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {languageData?.feedback?.subtitle ?? 'Click the button to fill out the form and receive your custom offer.'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={openFeedbackForm}
            style={{ marginTop: '1rem' }}
          >
            {languageData?.feedback?.survey ?? 'Fill out the form'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
